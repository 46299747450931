import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { FirebaseAuthService } from '../../services/firebase/auth.service';
import { DatabaseService } from '../../services/utilities/database.service';
import { ToastService } from '../../services/utilities/toast.service';
import { PlannerActions } from '../planner/planner.actions';
import { AuthActions } from './auth.actions';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private firebaseAuthService: FirebaseAuthService,
    private router: Router,
    private toastService: ToastService,
    private databaseService: DatabaseService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.load),
      switchMap(() =>
        this.firebaseAuthService.getUser().pipe(
          map((user) => {
            const client = this.firebaseAuthService.getClient();
            if (client) this.databaseService.connect(client);

            if (user) console.log(`ID token:\n${user.token}`);

            return AuthActions.loadSuccess({ client, user });
          }),
        ),
      ),
    ),
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      switchMap((form) =>
        this.firebaseAuthService.login(form).pipe(
          map((user) => ({
            client: form.client.toLowerCase(),
            user,
          })),
          map((data) => AuthActions.loginSuccess(data)),
          catchError(() => of(AuthActions.loginFailure())),
        ),
      ),
    ),
  );
  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        tap(({ user, client }) => {
          console.log(`ID token:\n${user.token}`);
          this.firebaseAuthService.setClient(client);
          this.databaseService.connect(client);
          this.router.navigateByUrl('/planner');
        }),
      ),
    { dispatch: false },
  );
  loginFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loginFailure),
        tap(() =>
          this.toastService.showError(
            'Wystąpił błąd',
            'Nieprawidłowy adres e-mail lub hasło',
          ),
        ),
      ),
    { dispatch: false },
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      switchMap(() => this.firebaseAuthService.logout()),
      tap(() => this.router.navigateByUrl('/login')),
      map(PlannerActions.clear),
    ),
  );
}
