import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { FirebaseSolutionPoint } from '../../interfaces/vehicles/firebase-solution-point';

export const SolutionsActions = createActionGroup({
  source: 'Solutions',
  events: {
    load: emptyProps(),
    loadSuccess: emptyProps(),

    clear: emptyProps(),
    update: props<Record<string, FirebaseSolutionPoint[]>>(),
  },
});
