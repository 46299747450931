import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InputColor } from 'src/app/core/interfaces/utilities/input-color';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  @Input() value!: string;
  @Input() color: InputColor = 'white';
  @Input() placeholder = 'Szukaj...';
  @Input() height = 40;

  @Output() valueChange = new EventEmitter<string>();
}
