import { Component, Injector, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroupDirective, NgControl } from '@angular/forms';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-form-control-error',
  templateUrl: './form-control-error.component.html',
  styleUrls: ['./form-control-error.component.scss'],
})
export class FormControlErrorComponent implements OnInit {
  @Input() formControls: (NgControl | AbstractControl | null | undefined)[] =
    [];
  @Input() paddingLeft?: number;

  formGroupDirective?: FormGroupDirective;

  constructor(private injector: Injector) {}

  get error(): string | null {
    for (const formControl of this.formControls) {
      if (!formControl) continue;
      if (
        formControl.errors &&
        ((formControl.dirty && formControl.touched) ||
          this.formGroupDirective?.submitted)
      ) {
        const error = Object.entries(formControl.errors)[0];
        return this.getErrorLabel(...error);
      }
    }
    return null;
  }

  ngOnInit(): void {
    this.formGroupDirective =
      this.injector.get(FormGroupDirective, undefined, {
        optional: true,
      }) ?? undefined;
  }

  getErrorLabel(errorCode: string, errorValue: any): string {
    const formatDate = (date: Date): string => {
      return DateTime.fromJSDate(date).toFormat('dd.MM.yyyy');
    };

    return (
      {
        min:
          errorValue.min === Number.EPSILON
            ? 'Wartość musi być większa niż 0'
            : `Minimalna wartość to ${errorValue.min}`,
        max:
          errorValue.max === Number.EPSILON
            ? 'Wartość musi być mniejsza niż 0'
            : `Maksymalna wartość to ${errorValue.max}`,
        required: 'Wymagane pole',
        email: 'Nieprawidłowe dane',
        minlength: `Minimalna długość to ${errorValue.requiredLength} znaków`,
        maxlength: `Maksymalna długość to ${errorValue.requiredLength} znaków`,
        pattern: `Nieprawidłowe dane`,

        dateRange: 'Nieprawidłowy zakres dat',
        equalValue: 'Hasła muszą być takie same',
        maxDate: `Data nie może być późniejsza niż ${formatDate(
          errorValue.max,
        )}`,
        minDate: `Data nie może być wcześniejsza niż ${formatDate(
          errorValue.min,
        )}`,
        passwordStrength: 'Nieprawidłowe dane',
        timeRange: 'Nieprawidłowy zakres czasu',
        unloadingDate:
          'Data rozładunku nie może być wcześniejsza niż załadunku',
      }[errorCode] ?? 'N/A'
    );
  }
}
