import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  computed,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NonNullableFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { Assignment } from 'src/app/core/interfaces/common/assignment';
import { OrdersActions } from 'src/app/core/state/orders/orders.actions';

interface Data {
  assignment: Assignment;
}

@Component({
  selector: 'app-order-assignment-rejection-dialog',
  templateUrl: './order-assignment-rejection-dialog.component.html',
  styleUrls: ['./order-assignment-rejection-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderAssignmentRejectionDialogComponent {
  form = this.fb.group({
    vehicleIssues: this.fb.control<boolean>(false),
    driverIssues: this.fb.control<boolean>(false),
    emptyDistance: this.fb.control<boolean>(false),
    waitTime: this.fb.control<boolean>(false),
    noValidDocuments: this.fb.control<boolean>(false),
    other: this.fb.control<string>(''),
  });

  close$ = new Subject<void>();

  formValue = toSignal(this.form.valueChanges);
  reassignVehicle = signal(false);
  showOtherInput = signal(false);

  reasons = computed<Record<string, any>>(() => {
    const value = this.formValue();
    if (!value) return {};

    return Object.fromEntries(
      Object.entries(value).filter(([_, value]) => !!value),
    );
  });
  reasonsLength = computed<number>(() => Object.entries(this.reasons()).length);

  disabled = computed<boolean>(() => {
    if (this.reassignVehicle()) return false;

    const reasons = this.reasons();
    if (Object.entries(reasons).length === 0) return true;

    if (this.showOtherInput() && !reasons['other']) return true;
    return false;
  });

  constructor(
    @Inject(DIALOG_DATA) private data: Data,
    protected dialogRef: DialogRef<void>,
    private fb: NonNullableFormBuilder,
    private store: Store,
  ) {}

  close(): void {
    this.close$.next();
  }

  submit(): void {
    if (this.disabled()) return;

    if (this.reassignVehicle()) {
      this.store.dispatch(
        OrdersActions.showAssignVehicle({
          orderParentUuid: this.data.assignment.orderParentUuid,
        }),
      );
    } else {
      this.store.dispatch(
        OrdersActions.rejectAssignment({
          assignment: this.data.assignment,
          reasons: this.reasons(),
        }),
      );
    }

    this.close$.next();
  }
}
