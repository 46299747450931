import { Injectable } from '@angular/core';
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
  QueryFn,
} from '@angular/fire/compat/database';

@Injectable({
  providedIn: 'root',
})
export class DatabaseService {
  private database = this.fireDatabase.database;

  constructor(private fireDatabase: AngularFireDatabase) {}

  connect(database: string): void {
    const app = this.fireDatabase.database.app;
    const databaseUrl = `https://${database}-planner.europe-west1.firebasedatabase.app`;
    this.database = app.database(databaseUrl);
  }

  list<T>(path: string, queryFn?: QueryFn): AngularFireList<T> {
    const ref = this.database.ref(path);
    return this.fireDatabase.list(ref, queryFn);
  }

  object<T>(path: string): AngularFireObject<T> {
    const ref = this.database.ref(path);
    return this.fireDatabase.object(ref);
  }
}
