export enum UserRole {
  DISPATCHER = 'dispatcher',
  MANAGER = 'manager',
  COMPANY_ADMIN = 'company-admin',
  MODERATOR = 'moderator',
  ADMIN = 'admin',
}

export const getUserRoleRank = (userRole: UserRole): number =>
  Object.values(UserRole).findIndex((role) => role === userRole);
