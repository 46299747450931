import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { FirebaseOrder } from 'src/app/core/interfaces/orders/firebase-order';
import { OrderStatusChange } from '../../enums/orders/order-status.change';
import { RejectAssignment } from '../../enums/orders/reject-assignment';
import { Assignment } from '../../interfaces/common/assignment';
import { Order } from '../../interfaces/orders/order';
import { SingleOrderForm } from '../../interfaces/orders/single-order-form';
import { PartialNull } from '../../interfaces/utilities/partial-null';

export const OrdersActions = createActionGroup({
  source: 'Orders',
  events: {
    load: emptyProps(),
    loadSuccess: emptyProps(),

    clear: emptyProps(),
    update: props<{ orders: FirebaseOrder[] }>(),

    addOrder: props<FirebaseOrder>(),
    editOrder: props<{ uuid: string; value: PartialNull<FirebaseOrder> }>(),
    deleteOrder: props<{ uuid: string }>(),
    changeStatus: props<{ uuid: string; statusChange: OrderStatusChange }>(),

    showRejectAssignment: props<Assignment>(),
    rejectAssignment: props<RejectAssignment>(),
    acceptAssignment: props<Assignment>(),
    clearRejections: props<{ orderParentUuid: string }>(),

    showAssignVehicle: props<{ orderParentUuid: string }>(),
    assignVehicle: props<{
      orderParentUuid: string;
      vehicleUuid: string;
      vehicleId?: string;
    }>(),

    showDetails: props<{ uuid: string }>(),

    showAddOrderForm: emptyProps(),
    submitAddOrder: props<SingleOrderForm>(),

    showEditOrderForm: props<{ uuid: string }>(),
    submitEditOrder: props<{ order: Order; form: SingleOrderForm }>(),
  },
});
