@if (label) {
  <p class="label f-label-small" (click)="switchPicker()">
    {{ label }}
    @if (required) {
      <span class="required">*</span>
    }
  </p>
}

<div class="container">
  <div
    class="input {{ color }}"
    [tabindex]="disabled ? -1 : 0"
    (focusout)="onTouched?.()"
    (click)="switchPicker()"
    (keydown)="handleInputKeyDown($event)"
    [ngClass]="{ active: picker(), invalid, disabled }"
    [ngStyle]="{ 'height.px': height }"
    #inputRef
  >
    <p
      [ngClass]="{
        placeholder: !value,
        medium: value
      }"
    >
      {{
        hours() !== undefined && minutes() !== undefined
          ? hours() + " : " + (minutes() | number: "2.0-0")
          : placeholder ?? "Ustaw"
      }}
    </p>

    <app-icon class="arrow" icon="clock" [size]="12"></app-icon>
  </div>

  @if (picker()) {
    <div class="picker" #pickerRef>
      <div class="input-wrapper">
        <input
          type="text"
          placeholder="0"
          [value]="hours() ?? ''"
          (keydown)="handleKeyDown($event, InputType.Hours)"
          (keypress)="handleKeyPress($event, InputType.Hours)"
          (paste)="$event.preventDefault()"
          #hoursInputRef
        />

        <div class="buttons">
          <button
            type="button"
            tabindex="-1"
            (click)="changeInputValue(InputType.Hours, 1)"
          >
            <app-icon icon="drop-arrow-up" [width]="8"></app-icon>
          </button>
          <button
            type="button"
            tabindex="-1"
            (click)="changeInputValue(InputType.Hours, -1)"
          >
            <app-icon icon="drop-arrow-down" [width]="8"></app-icon>
          </button>
        </div>
      </div>

      <div class="input-wrapper">
        <input
          type="text"
          placeholder="0"
          [value]="minutes() | number: '2.0-0'"
          (keydown)="handleKeyDown($event, InputType.Minutes)"
          (keypress)="handleKeyPress($event, InputType.Minutes)"
          (paste)="$event.preventDefault()"
          #minutesInputRef
        />

        <div class="buttons">
          <button
            type="button"
            tabindex="-1"
            (click)="changeInputValue(InputType.Minutes, 1)"
          >
            <app-icon icon="drop-arrow-up" [width]="8"></app-icon>
          </button>
          <button
            type="button"
            tabindex="-1"
            (click)="changeInputValue(InputType.Minutes, -1)"
          >
            <app-icon icon="drop-arrow-down" [width]="8"></app-icon>
          </button>
        </div>
      </div>
    </div>
  }
</div>

@if (formControl && errors) {
  <app-form-control-error
    [formControls]="[formControl]"
  ></app-form-control-error>
}
