<app-dialog
  type="center"
  [width]="480"
  [close$]="close$"
  (close)="dialogRef.close()"
>
  <h3 title>
    @switch (data.mode) {
      @case (VehicleEditMode.TYPE) {
        Typ pojazdu
      }
      @case (VehicleEditMode.WEIGHT_CAPACITY) {
        Ładowność pojazdu
      }
      @case (VehicleEditMode.CARGO_SPACE_CAPACITY) {
        Wymiary pojazdu
      }
    }
  </h3>

  <div class="container">
    @switch (data.mode) {
      @case (VehicleEditMode.TYPE) {
        <ng-container *ngTemplateOutlet="typeForm"></ng-container>
      }
      @case (VehicleEditMode.WEIGHT_CAPACITY) {
        <ng-container *ngTemplateOutlet="weightCapacityForm"></ng-container>
      }
      @case (VehicleEditMode.CARGO_SPACE_CAPACITY) {
        <ng-container *ngTemplateOutlet="cargoSpaceCapacityForm"></ng-container>
      }
    }

    <div class="button">
      <app-button [disabled]="form.invalid" (click)="submit()">
        Zmień
      </app-button>
    </div>
  </div>
</app-dialog>

<ng-template #typeForm>
  <form [formGroup]="form">
    <p>Edytuj typ pojazdu</p>

    <app-dropdown
      color="gray"
      formControlName="type"
      label="Typ pojazdu"
      [options]="vehicleTypes$ | async"
    ></app-dropdown>
  </form>
</ng-template>

<ng-template #weightCapacityForm>
  <form [formGroup]="form">
    <p>Edytuj maksymalną ładowność pojazdu</p>

    <app-input
      color="gray"
      formControlName="weightCapacity"
      [integer]="true"
      label="Ładowność"
      placeholder="0"
      unit="kg"
    ></app-input>
  </form>
</ng-template>

<ng-template #cargoSpaceCapacityForm>
  <form [formGroup]="form">
    <p>Edytuj wymiary pojazdu</p>

    <app-form-row formGroupName="cargoSpaceCapacity">
      <app-input
        color="gray"
        formControlName="depth"
        [integer]="true"
        label="Długość"
        placeholder="0"
        unit="mm"
      ></app-input>

      <app-input
        color="gray"
        formControlName="width"
        [integer]="true"
        label="Szerokość"
        placeholder="0"
        unit="mm"
      ></app-input>

      <app-input
        color="gray"
        formControlName="height"
        [integer]="true"
        label="Wysokość"
        placeholder="0"
        unit="mm"
      ></app-input>
    </app-form-row>
  </form>
</ng-template>
