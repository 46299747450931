import { Dialog } from '@angular/cdk/dialog';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { first, map, switchMap, tap } from 'rxjs';
import { DelegationsDialogComponent } from 'src/app/planner/delegations-dialog/delegations-dialog.component';
import { VehicleEditFormDialogComponent } from 'src/app/planner/map-view/vehicle-edit-form-dialog/vehicle-edit-form-dialog.component';
import { PartialNull } from '../../interfaces/utilities/partial-null';
import { MonthlyAnalytics } from '../../interfaces/vehicles/monthly-analytics';
import { VehiclesService } from '../../services/firebase/vehicles.service';
import { VehiclesActions } from './vehicles.actions';

@Injectable()
export class VehiclesEffects {
  constructor(
    private actions$: Actions,
    private vehiclesService: VehiclesService,
    private store: Store,
    private dialog: Dialog,
  ) {}

  load$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.load),
        switchMap(() =>
          this.vehiclesService
            .getVehicles$()
            .pipe(
              tap((vehicles) =>
                this.store.dispatch(VehiclesActions.update({ vehicles })),
              ),
            ),
        ),
      ),
    { dispatch: false },
  );
  loadSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesActions.load),
      switchMap(() =>
        this.actions$.pipe(
          ofType(VehiclesActions.update),
          first(),
          map(VehiclesActions.loadSuccess),
        ),
      ),
    ),
  );

  showEditVehicleForm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.showEditVehicleForm),
        map((data) =>
          this.dialog.open(VehicleEditFormDialogComponent, { data }),
        ),
      ),
    { dispatch: false },
  );
  editVehicle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.editVehicle),
        switchMap(({ uuid, value }) =>
          this.vehiclesService.editVehicle(uuid, value),
        ),
      ),
    { dispatch: false },
  );

  patchVehicle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.patchVehicle),
        switchMap(({ uuid, path, value }) =>
          this.vehiclesService.patchVehicle(uuid, path, value),
        ),
      ),
    { dispatch: false },
  );
  addIncome$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.addIncome),
        switchMap(({ vehicle, order }) => {
          const month = DateTime.fromJSDate(order.loadingDate!).toFormat(
            'yyyy-MM',
          );
          const monthAnalytics = vehicle.analytics?.months?.[month];

          return this.vehiclesService.patchVehicle(
            vehicle.uuid,
            `analytics/months/${month}`,
            {
              income: (monthAnalytics?.income ?? 0) + order.income!,
              distance: (monthAnalytics?.distance ?? 0) + order.distance!,
              lastOrder: {
                income: order.income!,
                distance: order.distance!,
                loadingDate: order.loadingDate!.toISOString(),
                unloadingDate: order.unloadingDate!.toISOString(),
              },
            } satisfies PartialNull<MonthlyAnalytics>,
          );
        }),
      ),
    { dispatch: false },
  );
  editIncome$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.editIncome),
        switchMap(({ vehicle, month, data }) => {
          return this.vehiclesService.patchVehicle(
            vehicle.uuid,
            `analytics/months/${month}`,
            {
              income: data.income!,
              distance: data.distance!,
            } satisfies PartialNull<MonthlyAnalytics>,
          );
        }),
      ),
    { dispatch: false },
  );
  addUnavailabilities$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.addUnavailabilities),
        switchMap((data) => {
          return this.vehiclesService.patchVehicle(
            data.vehicle!.uuid,
            `analytics/months/${data.month}`,
            {
              unavailabilities: data.unavailabilities.map((unavailability) => ({
                startDate: unavailability.startDate!.toISOString(),
                endDate: unavailability.endDate!.toISOString(),
                reason: unavailability.reason!,
                description: unavailability.description!,
              })),
            } satisfies PartialNull<MonthlyAnalytics>,
          );
        }),
      ),
    { dispatch: false },
  );

  showDelegationsDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesActions.showDelegationsDialog),
        map((data) => this.dialog.open(DelegationsDialogComponent, { data })),
      ),
    { dispatch: false },
  );
}
