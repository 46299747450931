<p class="heading f-label-small">PUNKTY ZAŁADUNKU ({{ orders.length }})</p>

<ul>
  @for (order of orders; track order.uuid) {
    <li (click)="showOrder(order)">
      <p class="f-label-large">{{ order.id }}</p>
      <p class="address f-label-small">
        {{ order.from.postcode }} {{ order.from.city }}
      </p>
    </li>
  }
</ul>
