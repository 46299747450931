@if (quantity > 1) {
  <span class="quantity f-numbers">{{ quantity }}</span>
}

<span class="transport-type {{ transportType }}">
  {{ TRANSPORT_TYPE + "." + transportType | translate }}
</span>

<span>{{ weight | number: "1.0-0" }} kg</span>

@if (vehicleWeightCapacity != null) {
  <span class="vehicle-weight-capacity">
    /{{ vehicleWeightCapacity | number: "1.0-0" }} kg
  </span>
}

@if (size) {
  <app-tooltip
    message="{{ size.depth / 1000 | number: '1.0-1' }} x {{
      size.width / 1000 | number: '1.0-1'
    }} x {{ size.height / 1000 | number: '1.0-1' }} m"
  ></app-tooltip>
}
