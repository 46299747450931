<app-dialog
  type="center"
  [width]="640"
  [close$]="close$"
  (close)="dialogRef.close()"
>
  <h2 title>Wskaż powód odrzucenia zlecenia</h2>

  <div class="container">
    <div class="message">
      <app-icon icon="tooltip" [size]="16"></app-icon>
      <p>
        Po odrzuceniu nie będziesz miał możliwości powrotu do tego zlecenia.
      </p>
    </div>

    <form [formGroup]="form">
      <p class="f-regular-1">
        Powody
        <span class="alt">
          (pozwoli to skuteczniej dopasowywać kolejne propozycje zleceń)
        </span>
      </p>

      <app-checkbox
        formControlName="unavailableVehicle"
        label="Niedostępny pojazd"
      ></app-checkbox>

      <app-checkbox
        formControlName="orderDirection"
        label="Kierunek zlecenia"
      ></app-checkbox>

      <app-checkbox
        formControlName="price"
        label="Wysokość stawki"
      ></app-checkbox>

      <app-checkbox
        label="Inny"
        [value]="showOther()"
        (valueChange)="showOther.set($event)"
      ></app-checkbox>

      @if (showOther()) {
        <app-input
          formControlName="other"
          label="Opisz powód odrzucenia"
        ></app-input>
      }
    </form>

    <div class="buttons">
      <app-button type="outline" [disabled]="loading()" (click)="close$.next()">
        Anuluj
      </app-button>

      <app-button
        type="primary"
        color="red"
        [disabled]="disabled() || loading()"
        (click)="!(disabled() || loading()) ? submit() : null"
      >
        Odrzuć zlecenie
      </app-button>
    </div>
  </div>
</app-dialog>
