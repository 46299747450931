<p class="f-regular-2">
  Powód
  @for (reason of reasons(); track reason.name) {
    {{ reason.number }}<ng-container *ngIf="!$last">;</ng-container>
  }
  @if (order().rejectedVehicles?.length; as rejectedVehicles) {
    <b>({{ rejectedVehicles }})</b>
  }
</p>

<app-tooltip [message]="message()"></app-tooltip>

@if (user()?.company == null) {
  @if (order().rejectedVehicles?.length) {
    <app-icon
      icon="delete"
      [size]="14"
      (click)="clearRejections(); $event.stopPropagation()"
    ></app-icon>
  }
}
