import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Injector,
  Input,
  Output,
  ViewChild,
  forwardRef,
  signal,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateTime } from 'luxon';
import { DropdownPositionHelper } from 'src/app/core/helpers/dropdown-position-helper';
import { InputColor } from 'src/app/core/interfaces/utilities/input-color';
import { BaseFormControlComponent } from '../../forms/base-form-control.component';

enum State {
  CLOSED,
  CALENDAR,
  MONTH,
  YEAR,
}

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true,
    },
  ],
})
export class DatepickerComponent
  extends BaseFormControlComponent
  implements ControlValueAccessor
{
  readonly State = State;

  readonly PICKER_HEIGHT = 336;
  readonly DAYS = ['PN', 'WT', 'ŚR', 'CZ', 'PT', 'SB', 'ND'];
  readonly MONTHS = Array.from({ length: 12 }).map((_, i) =>
    DateTime.utc().startOf('month').set({ month: i }).toJSDate()
  );

  @HostBinding('style.width') @Input() width = '100%';

  @ViewChild('picker') pickerRef!: ElementRef<HTMLDivElement>;
  @ViewChild('input') inputRef!: ElementRef<HTMLDivElement>;

  @Input() color: InputColor = 'white';
  @Input() label?: string;
  @Input() height = 40;
  @Input() placeholder?: string;
  @Input() disabled = false;
  @Input() errors = true;

  @Input() set value(value: Date | string | null | undefined) {
    if (!value) {
      this.value_.set(undefined);
      return;
    }

    const date = new Date(value);
    this.value_.set(
      date.getTime()
        ? DateTime.fromJSDate(date).startOf('day').toJSDate()
        : undefined
    );
  }
  get value(): Date | null {
    return this.value_() ?? null;
  }

  @Output() valueChange = new EventEmitter<Date | null>();

  state = signal(State.CLOSED);
  today = signal(new Date(new Date().toDateString()));
  calendar = signal<Date[]>([]);
  activeDate = signal<Date | undefined>(undefined);

  set calendarDate(date: Date | undefined) {
    const oldYear = this.activeDate()?.getFullYear();
    const oldMonth = this.activeDate()?.getMonth();

    this.calendarDate_.set(date);
    if (date?.getMonth() !== oldMonth || date?.getFullYear() !== oldYear) {
      this.updateCalendar();
    }
  }
  get calendarDate(): Date | undefined {
    return this.calendarDate_();
  }

  private value_ = signal<Date | undefined>(undefined);
  private calendarDate_ = signal<Date | undefined>(this.today());
  private clickInside_ = signal(false);

  constructor(
    protected override injector: Injector,
    private elementRef: ElementRef<HTMLElement>
  ) {
    super(injector);
  }

  get years(): number[] {
    const year = this.calendarDate!.getFullYear();
    return Array.from({ length: 7 }).map((_, i) => year - 3 + i);
  }

  onChange?: (value: Date | null) => void;
  onTouched?: () => void;

  registerOnChange(fn: (value: Date | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  writeValue(value: Date | string | null): void {
    this.value = value;
  }

  @HostListener('click', ['$event'])
  clickInside(): void {
    this.clickInside_.set(true);
  }

  @HostListener('document:click', ['$event'])
  clickOutside(): void {
    if (!this.clickInside_()) this.state.set(State.CLOSED);
    this.clickInside_.set(false);
  }

  @HostListener('focusout', ['$event'])
  handleFocusout(event: FocusEvent): void {
    const containerElement = this.elementRef.nativeElement;
    const relatedTarget = event.relatedTarget as HTMLElement | null;

    if (!relatedTarget || relatedTarget.className === 'cdk-dialog-container')
      return;
    if (!containerElement.contains(relatedTarget)) {
      this.state.set(State.CLOSED);
    }
  }

  focus(): void {
    setTimeout(() => {
      if (this.state() !== State.CLOSED) return;
      this.switchState();
    });
  }

  switchState(): void {
    if (this.disabled) return;
    this.state.update((state) =>
      state === State.CLOSED ? State.CALENDAR : State.CLOSED
    );

    if (this.state() !== State.CLOSED) {
      this.today.set(DateTime.utc().startOf('day').toJSDate());
      this.calendarDate = this.value ?? this.today();
      this.activeDate.set(this.value ?? undefined);

      setTimeout(() =>
        DropdownPositionHelper.setPosition(this.elementRef, this.pickerRef)
      );
    }
  }

  setRelativeDate(days: number): void {
    this.calendarDate = DateTime.utc().startOf('day').plus({ days }).toJSDate();
    this.activeDate.set(this.calendarDate);
  }

  setRelativeMonth(months: -1 | 1): void {
    this.calendarDate = DateTime.fromJSDate(this.calendarDate ?? new Date())
      .plus({ months })
      .startOf('day')
      .toJSDate();
  }

  setDate(date: Date): void {
    this.calendarDate = date;
    this.activeDate.set(date);
  }

  setMonth(month: number): void {
    this.calendarDate = DateTime.fromJSDate(this.calendarDate ?? new Date())
      .set({ month })
      .startOf('day')
      .toJSDate();
    this.state.set(State.CALENDAR);
  }

  setYear(year: number): void {
    this.calendarDate = DateTime.fromJSDate(this.calendarDate ?? new Date())
      .set({ year })
      .startOf('day')
      .toJSDate();
    this.state.set(State.CALENDAR);
  }

  resetValue(): void {
    this.activeDate.set(undefined);
    this.changeValue();
  }

  changeValue(): void {
    this.value_ = this.activeDate;
    this.state.set(State.CLOSED);
    this.onChange?.(this.value);
    this.valueChange.emit(this.value);
  }

  updateCalendar(): void {
    const year = this.calendarDate!.getFullYear();
    const month = this.calendarDate!.getMonth() + 1;

    const monthCalendar: Date[] = [];
    let monthDate = DateTime.fromObject({ year, month, day: 1 });

    while (monthDate.get('month') === month) {
      monthCalendar.push(monthDate.toJSDate());
      monthDate = monthDate.plus({ day: 1 });
    }

    const unshiftPrevious = () => {
      const previous = DateTime.fromJSDate(monthCalendar[0]).minus({ day: 1 });
      monthCalendar.unshift(previous.toJSDate());
    };

    if (monthCalendar[0].getDay() === 1) unshiftPrevious();
    while (monthCalendar[0].getDay() !== 1) unshiftPrevious();

    const pushNext = () => {
      const next = DateTime.fromJSDate(monthCalendar.at(-1)!).plus({ day: 1 });
      monthCalendar.push(next.toJSDate());
    };

    if (monthCalendar.at(-1)!.getDay() === 0) pushNext();
    while (monthCalendar.at(-1)!.getDay() !== 0) pushNext();

    this.calendar.set(monthCalendar);
  }

  handleInputKeyDown(event: KeyboardEvent): void {
    if (this.disabled) return;

    const key = event.key;
    if (key === ' ' || key === 'Enter') {
      this.switchState();
    }
  }
}
