import {
  Component,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  Output,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormControlComponent } from '../../forms/base-form-control.component';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent
  extends BaseFormControlComponent
  implements ControlValueAccessor
{
  @Input() label?: string;
  @Input() value = false;
  @Input() disabled = false;
  @Input() errors = true;

  @Output() valueChange = new EventEmitter<boolean>();

  constructor(protected override injector: Injector) {
    super(injector);
  }

  onChange?: (value: boolean) => void;
  onTouched?: () => void;

  registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  writeValue(value: boolean): void {
    this.value = value;
  }

  handleValueChange(value: boolean): void {
    this.value = value;
    this.onChange?.(value);
    this.valueChange.emit(value);
  }

  @HostListener('keydown.space', ['$event'])
  handleSpacePress(event: Event): void {
    if (this.disabled) return;
    this.handleValueChange(!this.value);
    event.preventDefault();
  }
}
