import { Injectable } from '@angular/core';
import { Observable, map, takeUntil, throttleTime } from 'rxjs';
import { THROTTLE_TIME } from '../../constants/utilities/throttle-time';
import { Assignment } from '../../interfaces/common/assignment';
import { DatabaseService } from '../utilities/database.service';
import { FirebaseAuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AssignmentsService {
  constructor(
    private databaseService: DatabaseService,
    private firebaseAuthService: FirebaseAuthService,
  ) {}

  getAssignments$(): Observable<Assignment[]> {
    return this.databaseService
      .list<string>('/inVehicle')
      .snapshotChanges()
      .pipe(
        takeUntil(this.firebaseAuthService.logout$),
        throttleTime(THROTTLE_TIME, undefined, { trailing: true }),
        map((actions): Assignment[] =>
          actions.map((action) => ({
            orderParentUuid: action.key!,
            vehicleUuid: action.payload.val()!,
          })),
        ),
      );
  }
}
