import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { first, map, switchMap, tap } from 'rxjs';
import { RejectionsService } from '../../services/firebase/rejections.service';
import { RejectionsActions } from './rejections.actions';

@Injectable()
export class RejectionsEffects {
  constructor(
    private actions$: Actions,
    private rejectionsService: RejectionsService,
    private store: Store,
  ) {}

  load$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RejectionsActions.load),
        switchMap(() =>
          this.rejectionsService
            .getRejections$()
            .pipe(
              tap((rejections) =>
                this.store.dispatch(RejectionsActions.update({ rejections })),
              ),
            ),
        ),
      ),
    { dispatch: false },
  );
  loadSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RejectionsActions.load),
      switchMap(() =>
        this.actions$.pipe(
          ofType(RejectionsActions.update),
          first(),
          map(RejectionsActions.loadSuccess),
        ),
      ),
    ),
  );
}
