import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  computed,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  AbstractControlOptions,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { TransportType } from 'src/app/core/enums/orders/transport-type';
import { DictionaryCode } from 'src/app/core/enums/utilities/dictionary-code';
import { VehicleType } from 'src/app/core/enums/vehicles/vehicle-type';
import { Address } from 'src/app/core/interfaces/common/address';
import { Order } from 'src/app/core/interfaces/orders/order';
import { OrdersFilters } from 'src/app/core/interfaces/orders/orders-filters';
import { SingleOrderForm } from 'src/app/core/interfaces/orders/single-order-form';
import { DictionariesService } from 'src/app/core/services/api/dictionaries.service';
import { OrdersActions } from 'src/app/core/state/orders/orders.actions';
import { dateRangeValidator } from 'src/app/core/validators/date-range';
import { unloadingDateValidator } from 'src/app/core/validators/unloading-date';

interface AddData {
  mode: 'add';
}

interface EditData {
  mode: 'edit';
  data: Order;
}

type Data = AddData | EditData;

@Component({
  selector: 'app-order-form-dialog',
  templateUrl: './order-form-dialog.component.html',
  styleUrls: ['./order-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderFormDialogComponent implements OnInit {
  form = this.buildForm();

  currencies$ = this.dictionariesService.getDictionary(DictionaryCode.CURRENCY);
  transportTypes$ = this.dictionariesService.getDictionary(
    DictionaryCode.TRANSPORT_TYPE,
  );
  vehicleTypes$ = this.dictionariesService.getDictionary(
    DictionaryCode.VEHICLE_TYPE,
  );

  weightValue = toSignal(this.form.get('weight')!.valueChanges);
  quantityValue = toSignal(this.form.get('quantity')!.valueChanges);
  totalWeight = computed<number>(() => {
    const weight = this.weightValue() ?? 0;
    const quantity = this.quantityValue() ?? 1;
    return weight * quantity;
  });

  close$ = new Subject<void>();

  constructor(
    @Inject(DIALOG_DATA) protected data: Data,
    protected dialogRef: DialogRef<OrdersFilters>,
    private fb: NonNullableFormBuilder,
    private dictionariesService: DictionariesService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  changeQuantity(difference: -1 | 1): void {
    const value = this.form.get('quantity')!.value ?? 0;
    const newValue = Math.max(1, value + difference);
    this.form.patchValue({ quantity: newValue });
  }

  submit(): void {
    if (this.form.invalid) return;

    const form = this.form.getRawValue() as SingleOrderForm;
    if (this.data.mode === 'add') {
      this.store.dispatch(OrdersActions.submitAddOrder(form));
    } else if (this.data.mode === 'edit') {
      this.store.dispatch(
        OrdersActions.submitEditOrder({
          order: this.data.data,
          form,
        }),
      );
    }

    this.close$.next();
  }

  loadData(): void {
    if (this.data.mode !== 'edit') return;

    const { data } = this.data;
    this.form.patchValue({
      id: data.id,
      client: data.client ?? '',
      price: data.price ?? null,
      currency: data.currency?.toLowerCase() ?? 'pln',
      transportType: data.transportType,

      vehicleType: data.vehicleType,
      weight: data.weight,
      quantity: data.quantity,

      pickupAddress: data.from,
      pickupDateStart: new Date(data.pickupTimeWindow.start),
      pickupDateEnd: new Date(data.pickupTimeWindow.end),
      pickupTimeStart: DateTime.fromJSDate(
        new Date(data.pickupTimeWindow.start),
      ).toFormat('HH:mm'),
      pickupTimeEnd: DateTime.fromJSDate(
        new Date(data.pickupTimeWindow.end),
      ).toFormat('HH:mm'),

      deliveryAddress: data.to,
      deliveryDateStart: new Date(data.deliveryTimeWindow.start),
      deliveryDateEnd: new Date(data.deliveryTimeWindow.end),
      deliveryTimeStart: DateTime.fromJSDate(
        new Date(data.deliveryTimeWindow.start),
      ).toFormat('HH:mm'),
      deliveryTimeEnd: DateTime.fromJSDate(
        new Date(data.deliveryTimeWindow.end),
      ).toFormat('HH:mm'),
    });
  }

  buildForm() {
    return this.fb.group(
      {
        // Details
        id: this.fb.control<string>('', [Validators.required]),
        client: this.fb.control<string>(''),
        price: this.fb.control<number | null>(null, [Validators.min(0)]),
        currency: this.fb.control<string>('pln'),
        transportType: this.fb.control<TransportType | null>(null),

        // Vehicle and cargo
        vehicleType: this.fb.control<VehicleType[] | null>(null, [
          Validators.required,
        ]),
        weight: this.fb.control<number | null>(null, [
          Validators.required,
          Validators.min(0),
        ]),
        quantity: this.fb.control<number>(1, [
          Validators.required,
          Validators.min(1),
        ]),

        // Loading point
        pickupAddress: this.fb.control<Address | null>(null, [
          Validators.required,
        ]),
        pickupDateStart: this.fb.control<Date | null>(null, [
          Validators.required,
        ]),
        pickupDateEnd: this.fb.control<Date | null>(null, [
          Validators.required,
        ]),
        pickupTimeStart: this.fb.control<string>('08:00', [
          Validators.required,
        ]),
        pickupTimeEnd: this.fb.control<string>('16:00', [Validators.required]),

        // Unloading point
        deliveryAddress: this.fb.control<Address | null>(null, [
          Validators.required,
        ]),
        deliveryDateStart: this.fb.control<Date | null>(null, [
          Validators.required,
        ]),
        deliveryDateEnd: this.fb.control<Date | null>(null, [
          Validators.required,
        ]),
        deliveryTimeStart: this.fb.control<string>('08:00', [
          Validators.required,
        ]),
        deliveryTimeEnd: this.fb.control<string>('16:00', [
          Validators.required,
        ]),
      },
      {
        validators: [
          dateRangeValidator('pickupDateStart', 'pickupDateEnd'),
          dateRangeValidator('deliveryDateStart', 'deliveryDateEnd'),
          unloadingDateValidator('pickupDateStart', 'deliveryDateStart'),
        ],
      } as AbstractControlOptions,
    );
  }
}
