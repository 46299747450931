import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ALPHABET } from 'src/app/core/constants/utilities/alphabet';
import { OrderHelper } from 'src/app/core/helpers/order-helper';
import { RoutePoint } from 'src/app/core/interfaces/vehicles/route-point';

@Component({
  selector: 'app-route',
  templateUrl: './route.component.html',
  styleUrls: ['./route.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouteComponent {
  readonly ALPHABET = ALPHABET;

  @Input({ required: true }) route!: RoutePoint[];
  @Input() alt?: boolean;
  @Input() selectedOrderUuid?: string;

  getLabel(routePoint: RoutePoint): string {
    return OrderHelper.getLabel(routePoint.timeWindow);
  }
}
