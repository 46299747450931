import { z } from 'zod';
import { zodErrorMessage } from '../../helpers/zod-error-message';

export const getDimensionsSchema = (
  requiredMessage: string,
  invalidMessage: string,
) =>
  z.object(
    {
      depth: z
        .number(zodErrorMessage(invalidMessage))
        .int(invalidMessage)
        .min(0, invalidMessage),
      width: z
        .number(zodErrorMessage(invalidMessage))
        .int(invalidMessage)
        .min(0, invalidMessage),
      height: z
        .number(zodErrorMessage(invalidMessage))
        .int(invalidMessage)
        .min(0, invalidMessage),
    },
    zodErrorMessage(requiredMessage),
  );

export type Dimensions = z.infer<ReturnType<typeof getDimensionsSchema>>;
