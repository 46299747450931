import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Injector,
  OnInit,
  effect,
  runInInjectionContext,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  AbstractControlOptions,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store, select } from '@ngrx/store';
import { isArray } from 'lodash';
import { DateTime } from 'luxon';
import { filter, first, map, switchMap } from 'rxjs';
import { TimeWindow } from 'src/app/core/interfaces/common/time-window';
import { Order } from 'src/app/core/interfaces/orders/order';
import { Dictionary } from 'src/app/core/interfaces/utilities/dictionary';
import { DelegationsForm } from 'src/app/core/interfaces/vehicles/delegations-form';
import { Vehicle } from 'src/app/core/interfaces/vehicles/vehicle';
import { DelegationsService } from 'src/app/core/services/api/delegations.service';
import { ToastService } from 'src/app/core/services/utilities/toast.service';
import { selectAllOrders } from 'src/app/core/state/orders/orders.selectors';
import { selectAllVehicles } from 'src/app/core/state/vehicles/vehicles.selectors';
import { dateRangeValidator } from 'src/app/core/validators/date-range';

export type DelegationDialogData = null | {
  vehicleUuid: string;
  workingIntervals?: TimeWindow[];
};

@UntilDestroy()
@Component({
  selector: 'app-delegations-dialog',
  templateUrl: './delegations-dialog.component.html',
  styleUrl: './delegations-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DelegationsDialogComponent implements OnInit {
  form = this.buildForm();
  isCyclic = toSignal(this.form.get('isCyclic')!.valueChanges);

  vehicles = signal<Dictionary<string> | null>(null);

  constructor(
    @Inject(DIALOG_DATA) private data: DelegationDialogData,
    protected dialogRef: DialogRef<void>,
    private fb: NonNullableFormBuilder,
    private injector: Injector,
    private store: Store,
    private toastService: ToastService,
    private delegationsService: DelegationsService,
  ) {}

  ngOnInit(): void {
    runInInjectionContext(this.injector, () => this.addCyclicEffect());
    this.getVehicles();
  }

  submit(): void {
    if (this.form.invalid) {
      return this.toastService.showError(
        'Wysyłanie nie powiodło się',
        'Popraw dane',
      );
    }

    this.form.disable();
    this.delegationsService
      .updateDelegations(this.form.getRawValue() as DelegationsForm)
      .subscribe({
        next: () => {
          this.toastService.showSuccess(
            'Zaktualizowano delegacje',
            'Akcja zakończona powodzeniem',
          );
          this.dialogRef.close();
        },
        error: () => {
          this.toastService.showError(
            'Wysyłanie nie powiodło się',
            'Spróbuj jeszcze raz',
          );
        },
      })
      .add(() => this.form.enable());
  }

  getVehicles(): void {
    this.store
      .pipe(
        select(selectAllOrders),
        filter((orders): orders is Order[] => isArray(orders)),
        first(),
        switchMap((orders) => this.store.select(selectAllVehicles(orders))),
        filter((vehicles): vehicles is Vehicle[] => isArray(vehicles)),
        first(),
        map(
          (vehicles): Dictionary<string> =>
            vehicles.map((vehicle) => ({
              name: vehicle.id,
              value: vehicle.uuid,
            })),
        ),
      )
      .subscribe((vehicles) => {
        this.vehicles.set(vehicles);

        const vehicleUuidsControl = this.form.get('vehicleUuids')!;
        if (this.data?.vehicleUuid) {
          vehicleUuidsControl.setValue([this.data.vehicleUuid]);

          if (this.data.workingIntervals?.length) {
            const startDate = DateTime.fromISO(
              this.data.workingIntervals[0].start,
            )
              .startOf('day')
              .toISO()!;

            const endDate = DateTime.fromISO(
              this.data.workingIntervals.at(-1)!.end,
            )
              .startOf('day')
              .toISO()!;

            this.form.patchValue({
              startDate,
              endDate,
            });
          }
        } else {
          vehicleUuidsControl.setValue(vehicles.map((v) => v.value));
        }
      });
  }

  addCyclicEffect(): void {
    const daysOnRouteControl = this.form.get('daysOnRoute')!;
    const daysOnBaseControl = this.form.get('daysOnBase')!;
    const cyclicControls = [daysOnRouteControl, daysOnBaseControl];

    effect(
      () => {
        for (const control of cyclicControls) {
          if (this.isCyclic()) {
            control.setValidators([Validators.required, Validators.min(1)]);
          } else {
            control.clearValidators();
            control.reset();
          }
        }
      },
      { allowSignalWrites: true },
    );
  }

  buildForm() {
    return this.fb.group(
      {
        vehicleUuids: this.fb.control<string[]>([], [Validators.required]),
        isCyclic: this.fb.control<boolean>(false),
        startDate: this.fb.control<string | null>(null, [Validators.required]),
        endDate: this.fb.control<string | null>(null, [Validators.required]),
        daysOnRoute: this.fb.control<number | null>(null),
        daysOnBase: this.fb.control<number | null>(null),
      },
      {
        validators: [dateRangeValidator('startDate', 'endDate')],
      } as AbstractControlOptions,
    );
  }
}
