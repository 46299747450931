import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Injector,
  OnInit,
  effect,
  runInInjectionContext,
  signal,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store, select } from '@ngrx/store';
import { isArray } from 'lodash';
import { Subscription, filter, switchMap } from 'rxjs';
import { DictionaryCode } from 'src/app/core/enums/utilities/dictionary-code';
import { VehicleStatus } from 'src/app/core/enums/vehicles/vehicle-status';
import { VehicleType } from 'src/app/core/enums/vehicles/vehicle-type';
import { Order } from 'src/app/core/interfaces/orders/order';
import { OrdersFilters } from 'src/app/core/interfaces/orders/orders-filters';
import { TableColumn } from 'src/app/core/interfaces/utilities/table-columns';
import { Vehicle } from 'src/app/core/interfaces/vehicles/vehicle';
import { DEFAULT_VEHICLES_FILTERS } from 'src/app/core/interfaces/vehicles/vehicles-filters';
import { DictionariesService } from 'src/app/core/services/api/dictionaries.service';
import { ToastService } from 'src/app/core/services/utilities/toast.service';
import { OrdersActions } from 'src/app/core/state/orders/orders.actions';
import { selectAllOrders } from 'src/app/core/state/orders/orders.selectors';
import { selectVehicles } from 'src/app/core/state/vehicles/vehicles.selectors';

interface Data {
  orderParentUuid: string;
}

@UntilDestroy()
@Component({
  selector: 'app-order-manual-assignment-dialog',
  templateUrl: './order-manual-assignment-dialog.component.html',
  styleUrl: './order-manual-assignment-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderManualAssignmentDialogComponent implements OnInit {
  readonly VehicleStatus = VehicleStatus;
  readonly VEHICLE_TYPE = DictionaryCode.VEHICLE_TYPE;
  readonly COLUMNS: TableColumn[] = [
    { name: '', width: '40px' },
    { name: 'Pojazd' },
    { name: 'Status', width: '200px' },
    { name: 'Typ pojazdu', width: '150px' },
    { name: 'Ładowność', width: '150px' },
  ];

  vehicleTypes$ = this.dictionariesService.getDictionary(
    DictionaryCode.VEHICLE_TYPE,
  );
  query = signal('');
  vehicleType = signal<VehicleType | null>(null);

  orders$ = this.store.pipe(
    select(selectAllOrders),
    untilDestroyed(this),
    filter((orders): orders is Order[] => isArray(orders)),
  );
  vehicles = signal<Vehicle[]>([]);
  vehiclesSubscription?: Subscription;
  selectedVehicle = signal<Vehicle | null>(null);

  constructor(
    @Inject(DIALOG_DATA) private data: Data,
    protected dialogRef: DialogRef<OrdersFilters>,
    private store: Store,
    private dictionariesService: DictionariesService,
    private injector: Injector,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    runInInjectionContext(this.injector, () => this.addFiltersEffect());
  }

  submit(): void {
    const vehicle = this.selectedVehicle();
    if (!vehicle) return;

    this.store.dispatch(
      OrdersActions.assignVehicle({
        orderParentUuid: this.data.orderParentUuid,
        vehicleUuid: vehicle.uuid,
        vehicleId: vehicle.id,
      }),
    );

    this.toastService.showSuccess(
      'Przypisano pojazd',
      'Akcja zakończona powodzeniem',
    );
    this.dialogRef.close();
  }

  addFiltersEffect(): void {
    effect(
      () => {
        this.query();
        this.vehicleType();
        this.updateVehicles();
      },
      { allowSignalWrites: true },
    );
  }

  updateVehicles(): void {
    this.vehiclesSubscription?.unsubscribe();

    const vehicles$ = this.orders$.pipe(
      switchMap((orders) =>
        this.store.select(
          selectVehicles(orders, {
            query: this.query(),
            filters: {
              ...DEFAULT_VEHICLES_FILTERS,
              type: this.vehicleType() ? [this.vehicleType()!] : null,
            },
          }),
        ),
      ),
      untilDestroyed(this),
      filter((vehicles): vehicles is Vehicle[] => isArray(vehicles)),
    );
    this.vehiclesSubscription = vehicles$.subscribe((vehicles) =>
      this.vehicles.set(vehicles),
    );
  }

  vehicleIdentity(_: number, item: Vehicle): string {
    return item.uuid;
  }
}
