import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Injector,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  forwardRef,
  signal,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { cloneDeep, get, isArray, isEqual } from 'lodash';
import { Observable } from 'rxjs';
import { DropdownPositionHelper } from 'src/app/core/helpers/dropdown-position-helper';
import { InputColor } from 'src/app/core/interfaces/utilities/input-color';
import { BaseFormControlComponent } from '../../forms/base-form-control.component';

interface Option<T> {
  name: string;
  value: T;
  selected?: boolean;
}

type Source<T> = (query: string) => Observable<T[]>;

const TEMPLATE_REGEX = /\${([^}]+)}/g;

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true,
    },
  ],
})
export class DropdownComponent<T>
  extends BaseFormControlComponent
  implements ControlValueAccessor
{
  @HostBinding('style.width') @Input() width = '100%';

  @ViewChild('input') inputRef!: ElementRef<HTMLDivElement>;
  @ViewChild('dropdown') dropdownRef!: ElementRef<HTMLDivElement>;
  @ViewChild('searchInput') searchInputRef!: ElementRef<HTMLInputElement>;
  @ViewChildren('option') optionRefs!: QueryList<ElementRef<HTMLLIElement>>;

  @Input() color: InputColor = 'white';
  @Input() label?: string;
  @Input() height = 40;
  @Input() placeholder?: string;
  @Input() default?: string;
  @Input() multiple = false;
  @Input() search = false;
  @Input() clearable = false;
  @Input() disabled = false;
  @Input() errors = true;

  @Input() set options(options: Option<T>[] | null | undefined) {
    this.options_ = options ? cloneDeep(options) : undefined;
    if (this.options) this.checkTemp();
  }
  get options(): Option<T>[] | null {
    return this.options_ ?? null;
  }

  @Input() set source(source: Source<T> | undefined) {
    if (!source) return;

    this.source_ = source;
    this.updateOptions();
  }
  get source(): Source<T> | undefined {
    return this.source_;
  }

  @Input() nameTemplate!: string;
  @Input() comparePath!: string;

  @Input() set value(value: T | null | undefined) {
    if (this.loading) {
      this.tempValue_ = value ?? undefined;
      return;
    }

    if (value == null) {
      this.selectOption(null, false);
      return;
    }

    this.selectOption(
      this.options?.find((option) =>
        isEqual(
          this.getCompareValue(option.value),
          this.getCompareValue(value),
        ),
      ) ?? null,
      false,
    );
  }
  get value(): T | null {
    return this.selectedOptions()[0].value ?? null;
  }

  @Input() set values(values: T[] | null | undefined) {
    if (this.loading) {
      this.tempValues_ = values ?? undefined;
      return;
    }

    if (!values || values.length === 0) {
      this.selectedOptions.set([]);
      for (const option of this.options!) {
        option.selected = false;
      }
      return;
    }

    for (const value of values) {
      const option =
        this.options?.find((option) =>
          isEqual(
            this.getCompareValue(option.value),
            this.getCompareValue(value),
          ),
        ) ?? null;
      if (option) this.switchOption(option, true);
    }
  }
  get values(): T[] | null {
    const values = this.selectedOptions().map((o) => o.value);
    return values.length ? values : null;
  }

  @Output() valueChange = new EventEmitter<T>();
  @Output() valuesChange = new EventEmitter<T[]>();

  selectedOptions = signal<Option<T>[]>([]);
  dropdown = signal(false);
  query = signal('');

  private source_?: Source<T>;
  private options_?: Option<T>[];
  private tempValue_?: T;
  private tempValues_?: T[];

  constructor(
    override injector: Injector,
    private elementRef: ElementRef<HTMLElement>,
  ) {
    super(injector);
  }

  get loading(): boolean {
    return !this.options;
  }

  onChange?: (value: T | T[] | null) => void;
  onTouched?: () => void;

  writeValue(value: T | T[] | null): void {
    if (isArray(value)) this.values = value;
    else this.value = value;
  }

  registerOnChange(fn: (value: T | T[] | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  @HostListener('document:click', ['$event'])
  checkClickOutside(event: MouseEvent): void {
    if (!this.dropdown) return;

    const containerElement = this.elementRef.nativeElement;
    const target = event.target as Node;

    if (target.parentNode && !containerElement.contains(target)) {
      this.dropdown.set(false);
    }
  }

  @HostListener('focusout', ['$event'])
  handleFocusout(event: FocusEvent): void {
    const containerElement = this.elementRef.nativeElement;
    const relatedTarget = event.relatedTarget as HTMLElement | null;

    if (!relatedTarget || relatedTarget.className === 'cdk-dialog-container')
      return;
    if (!containerElement.contains(relatedTarget)) {
      this.dropdown.set(false);
    }
  }

  focus(): void {
    setTimeout(() => {
      if (this.dropdown()) return;
      this.switchDropdown();
    });
  }

  switchDropdown(): void {
    if (this.loading || this.disabled) return;
    this.dropdown.update((dropdown) => !dropdown);

    if (this.dropdown()) {
      setTimeout(() => {
        DropdownPositionHelper.setPosition(
          this.elementRef,
          this.dropdownRef,
          300,
        );
      });

      if (this.search) {
        this.query.set('');
        setTimeout(() => this.searchInputRef.nativeElement.focus());
      } else {
        setTimeout(() => {
          if (this.selectedOptions().length) {
            this.optionRefs
              .find(
                (optionRef) =>
                  optionRef.nativeElement.dataset['value'] ===
                  this.selectedOptions()[0].value,
              )
              ?.nativeElement.focus();
          } else {
            this.optionRefs.first?.nativeElement?.focus();
          }
        });
      }
    }
  }

  updateOptions(): void {
    if (!this.source) return;

    this.source(this.query()).subscribe((data) => {
      this.options_ = data.map((element) => {
        const option: Option<T> = {
          name: this.getName(element),
          value: element,
        };

        if (this.selectedOptions().find((o) => this.compare(o, option))) {
          option.selected = true;
        }

        return option;
      });
      this.checkTemp();
    });
  }

  getFilteredOptions(): Option<T>[] {
    if (!this.options) return [];
    if (!this.search || !this.query() || this.source) return this.options;

    const searchFilter = this.query().toLowerCase();
    return this.options.filter((option) =>
      option.name.toLowerCase().includes(searchFilter),
    );
  }

  selectOption(option: Option<T> | null, emit = true): void {
    if (this.selectedOptions().length) {
      const selectedOption = this.selectedOptions()[0];
      if (this.source) {
        const findOption = this.options?.find((o) =>
          this.compare(o, selectedOption),
        );
        if (findOption) findOption.selected = false;
      } else {
        this.selectedOptions.update((options) => {
          const cloned = cloneDeep(options);
          options[0].selected = false;
          return cloned;
        });
      }
    }

    this.selectedOptions.set(option ? [option] : []);
    if (option) option.selected = true;

    setTimeout(() => {
      if (emit) this.onChange?.(option?.value ?? null);
      this.valueChange.emit(option?.value);
      this.dropdown.set(false);
    });
  }

  switchOption(option: Option<T>, force?: boolean) {
    if (force != null && option.selected === force) return;
    force ??= !option.selected;

    if (force) {
      this.selectedOptions.update((options) => [...options, option]);
    } else {
      const index = this.selectedOptions().findIndex((o) =>
        this.compare(o, option),
      );
      if (index !== -1) {
        this.selectedOptions.update((options) => {
          const cloned = cloneDeep(options);
          cloned.splice(index, 1);
          return cloned;
        });
      }
    }

    option.selected = force;

    setTimeout(() => {
      const values = this.values;
      this.onChange?.(values);
      this.valuesChange.emit(values ?? undefined);
    });
  }

  handleSelectKeyDown(event: KeyboardEvent): void {
    const key = event.key;

    if (key === ' ' || key === 'Enter') {
      this.switchDropdown();
      return;
    }

    if (
      (key === 'ArrowUp' || key === 'ArrowDown') &&
      !this.dropdown &&
      !this.multiple
    ) {
      const filteredOptions = this.getFilteredOptions();
      const index = this.selectedOptions().length
        ? filteredOptions.findIndex((o) =>
            this.compare(o, this.selectedOptions()[0]),
          )
        : -1;
      const nextIndex = index + (key === 'ArrowUp' ? -1 : 1);

      if (nextIndex >= 0 && nextIndex < filteredOptions.length) {
        if (!this.multiple) this.selectOption(filteredOptions[nextIndex]);
        else this.switchOption(filteredOptions[nextIndex]);
      } else if (nextIndex === -1 && this.clearable && !this.multiple) {
        this.selectOption(null);
      }

      event.preventDefault();
      return;
    }

    if (key === 'ArrowDown' && this.dropdown()) {
      this.optionRefs.first?.nativeElement?.focus();

      event.preventDefault();
      return;
    }
  }

  handleSearchInputKeyDown(event: KeyboardEvent): void {
    const key = event.key;

    if (key === 'Enter' || key === 'ArrowDown') {
      this.optionRefs.first?.nativeElement?.focus();

      event.preventDefault();
      return;
    }

    if (key === 'Escape') {
      this.dropdown.set(false);
      this.inputRef.nativeElement.focus();
      return;
    }
  }

  handleOptionKeyDown(event: KeyboardEvent): void {
    const [key, optionValue] = [
      event.key,
      (event.target as HTMLElement).dataset['value'],
    ];

    if (key === 'ArrowUp' || key === 'ArrowDown') {
      const filteredOptions = this.getFilteredOptions();
      const index = filteredOptions.findIndex(
        (option) => option.value === optionValue,
      );
      const nextIndex = index + (key === 'ArrowUp' ? -1 : 1);

      if (nextIndex >= 0 && nextIndex < filteredOptions.length) {
        const nextOptionValue = this.getCompareValue(
          filteredOptions[nextIndex].value,
        );
        this.optionRefs
          .find(
            (optionRef) =>
              optionRef.nativeElement.dataset['value'] === nextOptionValue,
          )
          ?.nativeElement.focus();
      } else if (nextIndex === -1 && this.clearable) {
        this.optionRefs.first.nativeElement.focus();
      } else if (nextIndex <= -1 && this.search) {
        this.searchInputRef.nativeElement.focus();
      }

      event.preventDefault();
      return;
    }

    if (key === 'Enter') {
      const option =
        this.options?.find((option) =>
          isEqual(this.getCompareValue(option.value), optionValue),
        ) ?? null;

      if (!this.multiple) {
        this.selectOption(option);
        this.inputRef.nativeElement.focus();
      } else if (option) {
        this.switchOption(option);
      }

      return;
    }

    if (key === 'Escape') {
      this.dropdown.set(false);
      this.inputRef.nativeElement.focus();
      return;
    }
  }

  getName(value: T): string {
    return this.nameTemplate.replaceAll(TEMPLATE_REGEX, (_, path) => {
      return get(value, path);
    });
  }

  getCompareValue(value: T): any {
    if (!this.comparePath) return value;
    return get(value, this.comparePath);
  }

  compare(a: Option<T>, b: Option<T>): boolean {
    return isEqual(
      this.getCompareValue(a.value),
      this.getCompareValue(b.value),
    );
  }

  checkTemp(): void {
    if (this.tempValue_ != undefined) {
      setTimeout(() => {
        this.value = this.tempValue_!;
        this.tempValue_ = undefined;
      });
    } else if (this.tempValues_ != undefined) {
      setTimeout(() => {
        this.values = this.tempValues_!;
        this.tempValues_ = undefined;
      });
    }
  }
}
