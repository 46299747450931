@if (
  ![NegotiationStatus.SENDER_ACCEPTED, NegotiationStatus.COMPLETED].includes(
    negotiation().status
  )
) {
  <div class="timer">
    <app-icon icon="timer" [size]="16"></app-icon>
    <p class="f-medium-1">{{ negotiationTimer() }}</p>
  </div>
}

@if (showAlert()) {
  <div class="alert">
    <app-icon icon="alert" [size]="16"></app-icon>
    <p class="f-medium-1">Wymagana akcja</p>
  </div>
}
