@if (label) {
  <p class="input-label f-label-small" (click)="switchState()">
    {{ label }}
    @if (required) {
      <span class="required">*</span>
    }
  </p>
}

<div class="container">
  <div
    class="input {{ color }}"
    [tabindex]="disabled ? -1 : 0"
    (focusout)="onTouched?.()"
    (click)="switchState()"
    (keydown)="handleInputKeyDown($event)"
    [ngClass]="{ active: state() !== State.CLOSED, invalid, disabled }"
    [ngStyle]="{ 'height.px': height }"
    #input
  >
    <p
      *ngLet="value as value"
      [ngClass]="{
        placeholder: !value,
        'f-medium': value
      }"
    >
      {{
        value ? (value | date: "d MMM y" | titlecase) : placeholder ?? "Ustaw"
      }}
    </p>

    <app-icon class="arrow" icon="calendar" [size]="12"></app-icon>
  </div>

  @if (state() !== State.CLOSED) {
    <div class="picker" #picker>
      @switch (state()) {
        @case (State.CALENDAR) {
          <div class="calendar">
            <ul class="quick-select">
              <li (click)="setRelativeDate(0); changeValue()">
                <p>Dzisiaj</p>
              </li>
              <li (click)="setRelativeDate(1); changeValue()"><p>Jutro</p></li>
              <li (click)="setRelativeDate(2); changeValue()">
                <p>Pojutrze</p>
              </li>
            </ul>

            <div class="calendar-view">
              <div class="heading">
                <div class="active-date" (click)="state.set(State.MONTH)">
                  <p class="f-medium">
                    {{ calendarDate | date: "LLLL y" | titlecase }}
                  </p>
                  <app-icon icon="chevron-down" [width]="8"></app-icon>
                </div>

                <div class="buttons">
                  <button type="button" (click)="setRelativeMonth(-1)">
                    <app-icon icon="chevron-left" [width]="6"></app-icon>
                  </button>
                  <button type="button" (click)="setRelativeMonth(1)">
                    <app-icon icon="chevron-right" [width]="6"></app-icon>
                  </button>
                </div>
              </div>

              <div class="container">
                <div class="days">
                  @for (day of DAYS; track day) {
                    <p class="f-label-small">
                      {{ day }}
                    </p>
                  }
                </div>

                <div class="month">
                  @for (day of calendar(); track day) {
                    <p
                      class="day"
                      (click)="setDate(day); changeValue()"
                      [ngClass]="{
                        inactive: day.getMonth() !== calendarDate?.getMonth(),
                        'f-medium today': day.getTime() === today().getTime(),
                        active: day.getTime() === activeDate()?.getTime()
                      }"
                    >
                      {{
                        day.getDate() === 1
                          ? (day | date: "d.MM")
                          : (day | date: "d")
                      }}
                    </p>
                  }
                </div>
              </div>

              <div class="footer">
                @if (!value) {
                  <p
                    class="f-link"
                    tabindex="0"
                    (click)="switchState()"
                    (keydown.space)="switchState()"
                  >
                    Anuluj
                  </p>
                } @else {
                  <p
                    class="f-link"
                    tabindex="0"
                    (click)="resetValue()"
                    (keydown.space)="resetValue()"
                  >
                    Wyczyść
                  </p>
                }
              </div>
            </div>
          </div>
        }

        @default {
          <div class="select">
            <div class="tabs">
              <button
                type="button"
                (click)="state.set(State.MONTH)"
                [ngClass]="{ active: state() === State.MONTH }"
              >
                <p class="f-medium">
                  {{ calendarDate | date: "LLLL" | titlecase }}
                </p>
                <app-icon icon="chevron-down" [width]="10"></app-icon>
              </button>

              <button
                type="button"
                (click)="state.set(State.YEAR)"
                [ngClass]="{ active: state() === State.YEAR }"
              >
                <p class="f-medium">{{ calendarDate?.getFullYear() }}</p>
                <app-icon icon="chevron-down" [width]="10"></app-icon>
              </button>
            </div>

            <ul>
              @switch (state()) {
                @case (State.MONTH) {
                  @for (date of MONTHS; track date) {
                    <li
                      (click)="setMonth(date.getMonth())"
                      [ngClass]="{
                        active: calendarDate?.getMonth() === date.getMonth()
                      }"
                    >
                      <app-icon icon="confirm" [width]="12"></app-icon>
                      <p
                        [ngClass]="{
                          'f-medium':
                            calendarDate?.getMonth() === date.getMonth()
                        }"
                      >
                        {{ date | date: "LLLL" | titlecase }}
                      </p>
                    </li>
                  }
                }

                @case (State.YEAR) {
                  @for (year of years; track year) {
                    <li
                      (click)="setYear(year)"
                      [ngClass]="{
                        active: calendarDate?.getFullYear() === year
                      }"
                    >
                      <app-icon icon="confirm" [width]="12"></app-icon>
                      <p
                        [ngClass]="{
                          medium: calendarDate?.getFullYear() === year
                        }"
                      >
                        {{ year }}
                      </p>
                    </li>
                  }
                }
              }
            </ul>
          </div>
        }
      }
    </div>
  }
</div>

@if (formControl && errors) {
  <app-form-control-error
    [formControls]="[formControl]"
  ></app-form-control-error>
}
