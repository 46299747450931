import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
  signal,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent<T> implements OnInit {
  @ViewChild('dialog') dialogRef!: ElementRef<HTMLElement>;

  @Input({ required: true }) type!: 'left' | 'center' | 'right';
  @Input() alt = false;
  @Input() width?: number;
  @Input() heading = true;
  @Input() close$?: Subject<T>;

  @Output() close = new EventEmitter<T | undefined>();

  opened = signal(false);
  @HostBinding('class.close-animation') closeAnimation = false;

  private clickInside_ = signal(false);

  clickInside(): void {
    this.clickInside_.set(true);
  }

  clickOutside(): void {
    if (!this.clickInside_() && this.opened()) this.closeDialog();
    this.clickInside_.set(false);
  }

  ngOnInit(): void {
    this.close$
      ?.asObservable()
      .pipe(untilDestroyed(this))
      .subscribe(this.closeDialog.bind(this));
    setTimeout(() => this.opened.set(true));
  }

  closeDialog(value?: T): void {
    this.opened.set(false);
    this.closeAnimation = true;
    setTimeout(() => this.close.emit(value), 300);
  }
}
