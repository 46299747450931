import { User } from '../../interfaces/auth/user';

export interface AuthState {
  loading: boolean;
  loginDisabled: boolean;
  client?: string;
  user?: User;
}

export const initialAuthState: AuthState = {
  loading: true,
  loginDisabled: false,
  client: undefined,
  user: undefined,
};
