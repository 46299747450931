<label>
  @if (label) {
    <p class="label f-label-small">
      {{ label }}
      @if (required) {
        <span class="required">*</span>
      }

      <span class="hint f-label-small">*{{ hint }}</span>
    </p>
  }

  <div class="input-wrapper">
    <input
      [class]="color"
      [disabled]="disabled"
      [attr.placeholder]="placeholder"
      (focusout)="onTouched?.()"
      (keydown)="handleInputKeyDown($event)"
      [(ngModel)]="query"
      (ngModelChange)="updateOptions()"
      [ngClass]="{ invalid }"
      [ngStyle]="{ 'height.px': height }"
      #inputRef
    />

    <app-icon icon="location" [width]="12" [height]="14"></app-icon>
  </div>

  <ul class="dropdown" #dropdownRef>
    @if (dropdown()) {
      @for (option of options(); track $index) {
        <li
          tabindex="-1"
          [attr.data-id]="getAddressId(option)"
          [ngClass]="{
            selected: value && getAddressId(option) === getAddressId(value)
          }"
          (keydown)="handleOptionKeyDown($event)"
          (click)="selectOption(option)"
          #optionRef
        >
          <p>{{ getAddressLabel(option) }}</p>

          <app-icon icon="confirm" [width]="12"></app-icon>
        </li>
      }

      <li class="not-found">
        <p>Brak wyników</p>
      </li>
    }
  </ul>
</label>

@if (formControl && errors) {
  <app-form-control-error
    [formControls]="[formControl]"
  ></app-form-control-error>
}
