import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Badge } from 'src/app/core/interfaces/utilities/badge';

@Component({
  selector: 'app-status-badge',
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusBadgeComponent {
  @Input({ required: true }) status!: Badge<unknown>;
  @Input() type: 'primary' | 'dot' = 'primary';
  @Input() label?: string;
}
