import { createReducer, on } from '@ngrx/store';
import { VehiclesActions } from './vehicles.actions';
import { initialVehiclesState } from './vehicles.state';

export const vehiclesReducer = createReducer(
  initialVehiclesState,

  on(VehiclesActions.clear, () => initialVehiclesState),
  on(VehiclesActions.update, (_, { vehicles }) =>
    Object.fromEntries(vehicles.map((vehicle) => [vehicle.uuid, vehicle])),
  ),
);
