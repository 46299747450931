import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { UnassignedReasons } from '../../interfaces/orders/unassigned-reasons';

export const UnassignedReasonsActions = createActionGroup({
  source: 'UnassignedReasons',
  events: {
    load: emptyProps(),
    loadSuccess: emptyProps(),

    clear: emptyProps(),
    update: props<Record<string, UnassignedReasons>>(),
  },
});
