import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { first, map, switchMap, tap } from 'rxjs';
import { HistoricalCostsService } from '../../services/firebase/historical-costs.service';
import { HistoricalCostsActions } from './historical-costs.actions';

@Injectable()
export class HistoricalCostsEffects {
  constructor(
    private actions$: Actions,
    private historicalCostsService: HistoricalCostsService,
    private store: Store,
  ) {}

  load$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HistoricalCostsActions.load),
        switchMap(() =>
          this.historicalCostsService
            .getHistoricalCosts$()
            .pipe(
              tap((historicalCosts) =>
                this.store.dispatch(
                  HistoricalCostsActions.update({ historicalCosts }),
                ),
              ),
            ),
        ),
      ),
    { dispatch: false },
  );
  loadSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HistoricalCostsActions.load),
      switchMap(() =>
        this.actions$.pipe(
          ofType(HistoricalCostsActions.update),
          first(),
          map(HistoricalCostsActions.loadSuccess),
        ),
      ),
    ),
  );
}
