import { ClipboardModule } from '@angular/cdk/clipboard';
import { DialogModule } from '@angular/cdk/dialog';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  OnyxAddButtonComponent,
  OnyxAvatarComponent,
  OnyxBackButtonComponent,
  OnyxButtonComponent,
  OnyxCheckboxComponent,
  OnyxCustomIconComponent,
  OnyxIconButtonComponent,
  OnyxIconComponent,
  OnyxInputComponent,
  OnyxLinkComponent,
  OnyxLogoComponent,
  OnyxNumberLabelComponent,
  OnyxRadioButtonComponent,
  OnyxSelectComponent,
  OnyxSpinnerComponent,
  OnyxToggleComponent,
  OnyxUploadComponent,
} from '@onyx/angular-kit';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgLetModule } from 'ng-let';
import { ActionButtonComponent } from './components/buttons/action-button/action-button.component';
import { AddButtonComponent } from './components/buttons/add-button/add-button.component';
import { BackButtonComponent } from './components/buttons/back-button/back-button.component';
import { ButtonComponent } from './components/buttons/button/button.component';
import { ExpandButtonComponent } from './components/buttons/expand-button/expand-button.component';
import { FilterButtonComponent } from './components/buttons/filter-button/filter-button.component';
import { OptionsComponent } from './components/buttons/options/options.component';
import { DialogComponent } from './components/dialogs/dialog/dialog.component';
import { FiltersDialogComponent } from './components/dialogs/filters-dialog/filters-dialog.component';
import { BaseFormControlComponent } from './components/forms/base-form-control.component';
import { FormControlErrorComponent } from './components/forms/form-control-error/form-control-error.component';
import { FormGroupComponent } from './components/forms/form-group/form-group.component';
import { FormRowComponent } from './components/forms/form-row/form-row.component';
import { AddressInputComponent } from './components/inputs/address-input/address-input.component';
import { CheckboxComponent } from './components/inputs/checkbox/checkbox.component';
import { DatepickerComponent } from './components/inputs/datepicker/datepicker.component';
import { DropdownComponent } from './components/inputs/dropdown/dropdown.component';
import { FileInputComponent } from './components/inputs/file-input/file-input.component';
import { InputComponent } from './components/inputs/input/input.component';
import { SearchComponent } from './components/inputs/search/search.component';
import { SwitchComponent } from './components/inputs/switch/switch.component';
import { TabsComponent } from './components/inputs/tabs/tabs.component';
import { TimepickerComponent } from './components/inputs/timepicker/timepicker.component';
import { LoadingPointsClusterBubbleComponent } from './components/map/loading-points-cluster-bubble/loading-points-cluster-bubble.component';
import { MapComponent } from './components/map/map.component';
import { EngineStatusComponent } from './components/others/engine-status/engine-status.component';
import { FlagComponent } from './components/others/flag/flag.component';
import { IconComponent } from './components/others/icon/icon.component';
import { PasswordStrengthComponent } from './components/others/password-strength/password-strength.component';
import { SpinnerComponent } from './components/others/spinner/spinner.component';
import { StatusBadgeComponent } from './components/others/status-badge/status-badge.component';
import { TableComponent } from './components/others/table/table.component';
import { ToastComponent } from './components/others/toaster/toast/toast.component';
import { ToasterComponent } from './components/others/toaster/toaster.component';
import { TooltipComponent } from './components/others/tooltip/tooltip.component';
import { InnerHtmlDirective } from './directives/inner-html.directive';
@NgModule({
  declarations: [
    ButtonComponent,
    BaseFormControlComponent,
    FormControlErrorComponent,
    FormRowComponent,
    InputComponent,
    ToasterComponent,
    ToastComponent,
    IconComponent,
    BackButtonComponent,
    AddButtonComponent,
    TabsComponent,
    TableComponent,
    SpinnerComponent,
    FlagComponent,
    StatusBadgeComponent,
    ActionButtonComponent,
    ExpandButtonComponent,
    DropdownComponent,
    CheckboxComponent,
    InnerHtmlDirective,
    DialogComponent,
    FiltersDialogComponent,
    FilterButtonComponent,
    DatepickerComponent,
    FormGroupComponent,
    MapComponent,
    TooltipComponent,
    AddressInputComponent,
    TimepickerComponent,
    SearchComponent,
    SwitchComponent,
    LoadingPointsClusterBubbleComponent,
    FileInputComponent,
    OptionsComponent,
    PasswordStrengthComponent,
    EngineStatusComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    OnyxToggleComponent,
    OnyxIconComponent,
    OnyxRadioButtonComponent,
    OnyxButtonComponent,
    OnyxAddButtonComponent,
    OnyxNumberLabelComponent,
    OnyxCheckboxComponent,
    OnyxSelectComponent,
    OnyxInputComponent,
    OnyxIconButtonComponent,
    OnyxLogoComponent,
    OnyxCustomIconComponent,
    OnyxBackButtonComponent,
    OnyxAvatarComponent,
    OnyxLinkComponent,
    OnyxUploadComponent,
    OnyxSpinnerComponent,

    TranslateModule,
    AngularSvgIconModule,
    NgLetModule,
    ScrollingModule,
    DialogModule,
    ClipboardModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    TranslateModule,
    AngularSvgIconModule,
    NgLetModule,
    ScrollingModule,
    DialogModule,
    ClipboardModule,

    OnyxToggleComponent,
    OnyxIconComponent,
    OnyxRadioButtonComponent,
    OnyxButtonComponent,
    OnyxAddButtonComponent,
    OnyxNumberLabelComponent,
    OnyxCheckboxComponent,
    OnyxSelectComponent,
    OnyxInputComponent,
    OnyxIconButtonComponent,
    OnyxLogoComponent,
    OnyxCustomIconComponent,
    OnyxBackButtonComponent,
    OnyxAvatarComponent,
    OnyxLinkComponent,
    OnyxUploadComponent,
    OnyxSpinnerComponent,

    ButtonComponent,
    BaseFormControlComponent,
    FormControlErrorComponent,
    FormRowComponent,
    InputComponent,
    ToasterComponent,
    ToastComponent,
    IconComponent,
    BackButtonComponent,
    AddButtonComponent,
    TabsComponent,
    TableComponent,
    SpinnerComponent,
    FlagComponent,
    StatusBadgeComponent,
    ActionButtonComponent,
    ExpandButtonComponent,
    DropdownComponent,
    CheckboxComponent,
    InnerHtmlDirective,
    DialogComponent,
    FiltersDialogComponent,
    FilterButtonComponent,
    DatepickerComponent,
    FormGroupComponent,
    MapComponent,
    TooltipComponent,
    AddressInputComponent,
    TimepickerComponent,
    SearchComponent,
    SwitchComponent,
    FileInputComponent,
    OptionsComponent,
    PasswordStrengthComponent,
    EngineStatusComponent,
  ],
})
export class SharedModule {}
