<svg-icon
  [src]="iconSrc"
  [stretch]="true"
  [svgStyle]="{
    width: width ? width.toString() + 'px' : 'auto',
    height: height ? height.toString() + 'px' : 'auto'
  }"
  [ngStyle]="{
    'min-width.px': width,
    'min-height.px': height
  }"
></svg-icon>
