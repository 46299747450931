import { createReducer, on } from '@ngrx/store';
import { AuthActions } from './auth.actions';
import { initialAuthState } from './auth.state';

export const authReducer = createReducer(
  initialAuthState,

  on(AuthActions.loadSuccess, (state, data) => ({
    ...state,
    ...data,
    loading: false,
  })),

  on(AuthActions.login, (state) => ({
    ...state,
    loginDisabled: true,
  })),
  on(AuthActions.loginSuccess, (state, { client, user }) => ({
    ...state,
    loginDisabled: false,
    client,
    user,
  })),
  on(AuthActions.loginFailure, (state) => ({
    ...state,
    loginDisabled: false,
  })),

  on(AuthActions.logout, (state) => ({
    ...state,
    user: undefined,
  }))
);
