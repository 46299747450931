@if (label) {
  <p class="label f-label-small" (click)="focus()">
    {{ label }}
    @if (required) {
      <span class="required">*</span>
    }
  </p>
}

<div class="container">
  <div
    class="input {{ color }}"
    [tabindex]="disabled ? -1 : 0"
    [ngClass]="{
      active: dropdown(),
      invalid,
      disabled,
      loading
    }"
    [ngStyle]="{ 'height.px': height }"
    (focusout)="onTouched?.()"
    (keydown)="handleSelectKeyDown($event)"
    (click)="switchDropdown()"
    #input
  >
    @if (!loading) {
      @if (!multiple) {
        <p
          *ngLet="selectedOptions()[0] as selectedOption"
          class="single"
          [appInnerHTML]="
            selectedOption?.name ?? default ?? placeholder ?? 'Wybierz'
          "
          [ngClass]="{
            placeholder: !selectedOption && !default,
            'f-medium-1': selectedOption
          }"
        ></p>
      } @else {
        <div class="multiple">
          @if (selectedOptions().length === 0) {
            <p [ngClass]="{ placeholder: !default }">
              {{ default ?? placeholder ?? "Wybierz" }}
            </p>
          }

          @for (option of selectedOptions(); track option.value) {
            <p
              class="option f-medium-1"
              (click)="switchOption(option, false); $event.stopPropagation()"
            >
              {{ option.name }}
              <app-icon icon="close" [size]="8"></app-icon>
            </p>
          }
        </div>
      }

      <app-icon
        class="arrow"
        [width]="8"
        [icon]="dropdown() ? 'chevron-up' : 'chevron-down'"
      ></app-icon>
    } @else {
      <app-spinner size="small"></app-spinner>
    }
  </div>

  @if (dropdown()) {
    <ul class="dropdown" #dropdown>
      @if (search) {
        <div class="search-container">
          <app-icon class="search-icon" icon="search" [size]="14"></app-icon>
          <input
            class="search-input"
            placeholder="Szukaj..."
            [ngModel]="query()"
            (ngModelChange)="query.set($event); updateOptions()"
            (keydown)="handleSearchInputKeyDown($event)"
            #searchInput
          />
        </div>
      }

      @if (clearable && !multiple) {
        <li
          class="clear"
          tabindex="-1"
          [attr.data-value]="null"
          [ngClass]="{ selected: selectedOptions().length === 0 }"
          (keydown)="handleOptionKeyDown($event)"
          (click)="selectOption(null)"
          #option
        >
          <p [ngClass]="{ default }">
            {{ default ?? placeholder ?? "Wybierz" }}
          </p>
          <app-icon icon="confirm" [width]="12"></app-icon>
        </li>
      }

      @for (option of getFilteredOptions(); track option.value) {
        <li
          tabindex="-1"
          [attr.data-value]="getCompareValue(option.value)"
          [ngClass]="{ selected: option?.selected }"
          (keydown)="handleOptionKeyDown($event)"
          (click)="
            !multiple ? selectOption(option) : switchOption(option);
            $event.stopPropagation()
          "
          #option
        >
          @if (multiple) {
            <app-checkbox
              [value]="option?.selected ?? false"
              [errors]="false"
            ></app-checkbox>
          }

          <p
            [appInnerHTML]="option.name"
            [ngClass]="{ 'f-medium-1': option?.selected && !multiple }"
          ></p>

          @if (!multiple) {
            <app-icon icon="confirm" [width]="12"></app-icon>
          }
        </li>
      }

      <li class="not-found">
        <p>Brak wyników</p>
      </li>
    </ul>
  }
</div>

@if (formControl && errors) {
  <app-form-control-error
    [formControls]="[formControl]"
  ></app-form-control-error>
}
