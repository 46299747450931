export enum DictionaryCode {
  COUNTRY = 'country',
  CURRENCY = 'currency',
  IMPORT_TYPE = 'import-type',
  TRANSPORT_TYPE = 'transport-type',
  UNNASIGNMENT_REASON = 'unnasignment-reason',
  VEHICLE_DATA_SOURCE = 'vehicle-data-source',
  VEHICLE_TYPE = 'vehicle-type',
  VEHICLE_UNAVAILABILITY_REASON = 'vehicle-unavailability-reason',
}
