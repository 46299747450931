import { z } from 'zod';
import { CountryCodeHelper } from '../../helpers/country-code-helper';
import { zodErrorMessage } from '../../helpers/zod-error-message';

export const AllowedCountriesSchema = z.object({
  countryCodes: z.array(
    z
      .string(zodErrorMessage('allowedCountries.invalid'))
      .refine(
        (countryCode) =>
          CountryCodeHelper.toAlpha3(countryCode.toUpperCase()) != countryCode,
        { message: 'allowedCountries.invalid' }
      )
  ),
  negated: z
    .boolean(zodErrorMessage('allowedCountries.invalid'))
    .default(false),
});

export type AllowedCountries = z.infer<typeof AllowedCountriesSchema>;
