import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExecutionDataForm } from 'src/app/planner/common/components/order-details-dialog/order-negotiation/order-negotiation.component';
import { environment } from 'src/config/environment';

@Injectable({
  providedIn: 'root',
})
export class NegotiationsService {
  constructor(private http: HttpClient) {}

  public rejectNegotiation(
    orderUuid: string,
    reasons: object,
  ): Observable<void> {
    return this.http.post<void>(
      `${environment.apiUrl}/negotiations/${orderUuid}/reject`,
      { reasons },
    );
  }

  public counterOfferNegotiation(
    orderUuid: string,
    counterOffer: number,
  ): Observable<void> {
    return this.http.post<void>(
      `${environment.apiUrl}/negotiations/${orderUuid}/counter-offer`,
      { counterOffer },
    );
  }

  public acceptNegotiation(orderUuid: string): Observable<void> {
    return this.http.post<void>(
      `${environment.apiUrl}/negotiations/${orderUuid}/accept`,
      {},
    );
  }

  public completeNegotiation(
    orderUuid: string,
    form: ExecutionDataForm,
  ): Observable<void> {
    return this.http.post<void>(
      `${environment.apiUrl}/negotiations/${orderUuid}/complete`,
      form,
    );
  }

  public expireNegotiation(orderUuid: string): Observable<void> {
    return this.http.post<void>(
      `${environment.apiUrl}/negotiations/${orderUuid}/expire`,
      {},
    );
  }
}
