import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  @Input() icon?: string;
  @Input() source?: string;
  @Input() width?: number;
  @Input() height?: number;

  @Input() set size(size: number) {
    if (!size) return;
    this.width = this.height = size;
  }

  get iconSrc(): string {
    if (this.source) return this.source;
    return `/assets/icons/${this.icon!}.svg`;
  }
}
