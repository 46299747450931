<h4>
  Trasa
  <span class="length f-numbers" [ngClass]="{ alt }">{{ route.length }}</span>
</h4>

<div class="route">
  @for (item of route; track $index) {
    <div></div>

    <div class="item">
      <span
        class="letter f-medium-1"
        [ngClass]="{
          completed: item.completed,
          selected: item.orderUuid === selectedOrderUuid
        }"
      >
        {{ ALPHABET[$index] | uppercase }}
      </span>

      <div class="details">
        <p class="heading">
          <span class="type f-label-small">
            {{ item.pickup ? "Załadunek" : "Rozładunek" }}
          </span>

          @if (item.estimatedTime?.pointInTime) {
            <span class="separator f-label-small">•</span>
            <span class="eta-label f-label-small">ETA: </span>
            <span class="eta f-label-small">{{
              item.estimatedTime!.pointInTime
                | date: "HH:mm, d MMM y"
                | titlecase
            }}</span>
          }
        </p>

        <p class="address">
          <app-flag [countryCode]="item.address.countryCode"></app-flag>
          <span>
            <span class="f-medium-1">
              {{ item.address.city }} ({{ item.address.countryCode }}),
            </span>
            {{ item.address.postcode }}
          </span>
        </p>

        <p class="time-window">{{ getLabel(item) }}</p>
      </div>
    </div>

    <div class="dot" [ngClass]="{ completed: item.completed }"></div>
    <div class="line"></div>
  }
</div>
