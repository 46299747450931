import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from './auth.state';

export const selectAuth = createFeatureSelector<AuthState>('auth');

export const selectLoginDisabled = createSelector(
  selectAuth,
  (state) => state.loginDisabled
);

export const selectClient = createSelector(selectAuth, (state) => state.client);

export const selectUser = createSelector(selectAuth, (state) => state.user);
