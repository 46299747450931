<div class="container" [ngClass]="{ center: type() === 'primary' }">
  @if (assignment()) {
    <p
      class="assignment {{ type() }} {{ assignment() }}"
      [ngClass]="{
        'f-label-large': type() === 'primary',
        'f-medium-1': type() === 'secondary'
      }"
    >
      @switch (assignment()) {
        @case (OrderAssignment.ONYX) {
          ONYX
        }
        @case (OrderAssignment.MANUAL) {
          {{ user()?.company?.id ?? client() | uppercase }}
        }
      }
    </p>
  } @else if (status() === OrderStatus.SEARCHING_VEHICLE) {
    <onyx-spinner></onyx-spinner>
  } @else {
    <p>-</p>
  }
</div>
