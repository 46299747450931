@switch (view()) {
  @case (NegotiationView.OFFERS) {
    <div class="negotiation-offers">
      <ng-container
        *ngTemplateOutlet="
          offerTemplate;
          context: {
            label:
              companyType() === CompanyType.CARRIER
                ? 'Oferta startowa'
                : 'Twoja oferta startowa',
            offer: negotiation().initialOfferPrice,
            createdAt: negotiation().createdAt
          }
        "
      ></ng-container>

      @if (negotiation().carrierCounterOffer; as counterOffer) {
        <ng-container
          *ngTemplateOutlet="
            offerTemplate;
            context: {
              label:
                companyType() === CompanyType.CARRIER
                  ? 'Twoja kontroferta'
                  : 'Kontroferta przewoźnika',
              offer: counterOffer.price,
              createdAt: counterOffer.createdAt
            }
          "
        ></ng-container>
      }

      @if (negotiation().senderFinalOffer; as finalOffer) {
        <ng-container
          *ngTemplateOutlet="
            offerTemplate;
            context: {
              label:
                companyType() === CompanyType.CARRIER
                  ? 'Ostateczna oferta'
                  : 'Twoja ostateczna oferta',
              offer: finalOffer.price,
              createdAt: finalOffer.createdAt
            }
          "
        ></ng-container>
      }

      @if (showMessage()) {
        <div
          *ngLet="
            [
              NegotiationStatus.CARRIER_ACCEPTED,
              NegotiationStatus.SENDER_ACCEPTED
            ].includes(negotiation().status) as success
          "
          class="message"
          [ngClass]="{ success }"
        >
          <app-icon
            [icon]="success ? 'check' : 'tooltip'"
            [size]="16"
          ></app-icon>

          <p class="f-regular-1">
            @if (success) {
              <span class="f-medium-1">Zaakceptowano ofertę</span>
            }

            <span>
              Oczekiwanie na odpowiedź
              @switch (companyType()) {
                @case (CompanyType.CARRIER) {
                  od zleceniodawcy
                }
                @case (CompanyType.SENDER) {
                  od przewoźnika
                  @if (success) {
                    (przesłanie danych kierowcy i zestawu)
                  }
                }
              }
            </span>
          </p>
        </div>
      } @else {
        @switch (companyType()) {
          @case (CompanyType.CARRIER) {
            <p class="terms f-regular-1">
              Akceptuję
              <span class="details f-link" (click)="showTerms()">
                warunki zlecenia
              </span>
              i po zaakceptowaniu go, zobowiązuję się do jego realizacji.
            </p>
          }
          @case (CompanyType.SENDER) {
            <p class="terms f-regular-1">
              Po zaakceptowaniu oferty, przewoźnik zobowiązuje się do realizacji
              zlecenia.

              @if (order().maxPrice; as maxPrice) {
                <br />
                <br />
                <span class="f-medium-1">
                  Maksymalna stawka: {{ maxPrice | number: "1.0-2" }}
                  {{ order().currency | uppercase }}
                </span>
              }
            </p>
          }
        }

        <div class="buttons">
          <app-button
            color="red"
            [disabled]="loading()"
            (click)="loading() ? null : reject()"
          >
            Odrzuć
          </app-button>

          @if (!counterOfferDisabled()) {
            <app-button
              color="blue"
              [disabled]="loading()"
              (click)="loading() ? null : counterOffer()"
            >
              Negocjuj
            </app-button>
          }

          @if (!acceptDisabled()) {
            <app-button
              color="green"
              [disabled]="loading()"
              (click)="loading() ? null : accept()"
            >
              Akceptuj
            </app-button>
          }
        </div>
      }
    </div>
  }

  @case (NegotiationView.COUNTER_OFFER) {
    <form class="negotiation-counter-offer" [formGroup]="counterOfferForm">
      <p class="f-medium-1">
        @switch (companyType()) {
          @case (CompanyType.CARRIER) {
            Oferta startowa
          }
          @case (CompanyType.SENDER) {
            Kontrooferta przewoźnika
          }
        }
      </p>

      <p class="f-medium-1">
        @switch (companyType()) {
          @case (CompanyType.CARRIER) {
            Twoja oferta
          }
          @case (CompanyType.SENDER) {
            Twoja ostateczna oferta
          }
        }
      </p>

      <div class="row">
        <app-input
          label="Fracht"
          [value]="counterOfferBasePrices().price ?? '??'"
          unit="{{ order().currency | uppercase }}"
          [disabled]="true"
        ></app-input>

        <app-icon icon="arrow-right"></app-icon>

        <app-input
          formControlName="price"
          label="Fracht"
          unit="{{ order().currency | uppercase }}"
          [float]="true"
          (valueChange)="updateCounterOfferPricePerKilometer($event)"
        ></app-input>
      </div>

      <div class="row">
        <app-input
          label="Stawka za kilometr"
          [value]="counterOfferBasePrices().pricePerKilometer ?? '??'"
          unit="{{ order().currency | uppercase }}/km"
          [disabled]="true"
        ></app-input>

        <app-icon icon="arrow-right"></app-icon>

        <app-input
          formControlName="pricePerKilometer"
          label="Stawka za kilometr"
          unit="{{ order().currency | uppercase }}/km"
          [float]="true"
          (valueChange)="updateCounterOfferPrice($event)"
        ></app-input>
      </div>

      <app-button
        type="outline"
        [disabled]="loading()"
        (click)="loading() ? null : cancelCounterOffer()"
      >
        Anuluj
      </app-button>

      <app-button
        color="blue"
        [disabled]="loading()"
        (click)="loading() ? null : submitCounterOffer()"
      >
        Wyślij ofertę
      </app-button>
    </form>
  }

  @case (NegotiationView.EXECUTION_DATA) {
    <div class="negotiation-execution-data">
      <div class="message success">
        <app-icon icon="check" [size]="16"></app-icon>
        <p class="f-regular-1">
          <span class="f-medium-1">Przyjęto zlecenie</span>
          <span>
            Do realizacji zlecenia wymagane jest podanie danych kierowcy.
          </span>
        </p>
      </div>

      <form [formGroup]="executionDataForm">
        <ng-container formGroupName="driver">
          <app-input
            formControlName="firstName"
            label="Imię kierowcy"
          ></app-input>

          <app-input
            formControlName="lastName"
            label="Nazwisko kierowcy"
          ></app-input>

          <app-input
            formControlName="phoneNumber"
            label="Numer telefonu kierowcy"
            placeholder="+48123456789"
          ></app-input>
        </ng-container>

        <app-input
          formControlName="vehiclePlateNumber"
          label="Pojazd (numer rejestracyjny)"
        ></app-input>

        <app-input
          class="semi-trailer"
          formControlName="semiTrailerPlateNumber"
          label="Naczepa (numer rejestracyjny)"
        ></app-input>
      </form>

      <app-button
        color="blue"
        [disabled]="loading()"
        (click)="loading() ? null : submitExecutionData()"
      >
        Zapisz
      </app-button>
    </div>
  }

  @case (NegotiationView.SUMMARY) {
    <div class="negotiation-summary">
      <ng-container
        *ngTemplateOutlet="
          offerTemplate;
          context: {
            label: 'Zaakceptowana oferta',
            offer: finalPrice(),
            createdAt: negotiation().senderAcceptance!.createdAt
          }
        "
      ></ng-container>

      <div
        *ngLet="negotiation().carrierExecutionData! as executionData"
        class="execution-data"
      >
        <div *ngLet="executionData.driver as driver" class="item">
          <p class="f-semibold">Dane kierowcy</p>
          <p>{{ driver.firstName }} {{ driver.lastName }}</p>
          <p>{{ driver.phoneNumber }}</p>
        </div>

        <div class="item">
          <p class="f-semibold">Dane zestawu</p>
          <p>Pojazd: {{ executionData.vehiclePlateNumber }}</p>
          <p>Naczepa: {{ executionData.semiTrailerPlateNumber }}</p>
        </div>
      </div>

      @if (companyType() === CompanyType.CARRIER) {
        <p class="terms f-link" (click)="showTerms()">Warunki zlecenia</p>
      }
    </div>
  }
}

<ng-template
  let-label="label"
  let-offer="offer"
  let-createdAt="createdAt"
  #offerTemplate
>
  <div class="offer">
    <p class="label f-medium-1">
      {{ label }}
      <span class="alt">{{ createdAt | date: "HH:mm, d MMM yyyy" }}</span>
    </p>

    <div class="item">
      <p>Fracht</p>
      <h3>
        <span class="value">
          @if (!offer) {
            ??
          } @else {
            {{ offer | number: "1.0-2" }}
          }
        </span>

        {{ order().currency | uppercase }}
      </h3>
    </div>

    <div class="item">
      <p>
        Stawka za kilometr
        <app-tooltip
          message="Stawka uwzględnia ładowne i puste kilometry"
          align="right"
        ></app-tooltip>
      </p>

      <h3>
        <span class="value">
          @if (!offer) {
            ??
          } @else {
            {{ offer / totalDistance() | number: "1.0-2" }}
          }
        </span>

        {{ order().currency | uppercase }}/km
      </h3>
    </div>
  </div>
</ng-template>
