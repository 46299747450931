import { createReducer, on } from '@ngrx/store';
import { UnassignedReasonsActions } from './unassigned-reasons.actions';
import { initialUnassignedReasonsState } from './unassigned-reasons.state';

export const unassignedReasonsReducer = createReducer(
  initialUnassignedReasonsState,

  on(UnassignedReasonsActions.clear, () => initialUnassignedReasonsState),
  on(
    UnassignedReasonsActions.update,
    (_, unassignedReasons) => unassignedReasons
  )
);
