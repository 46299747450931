export enum BadgeColor {
  BLACK = '#0F172A',
  BLUE = '#0078FF',
  GRAY = '#94A3B8',
  GREEN = '#16A34A',
  ORANGE = '#FF971D',
  RED = '#FF5252',
  VIOLET = '#AB53FF',
  YELLOW = '#FFDA44',
}
