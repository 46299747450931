import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { LoginForm } from '../../interfaces/auth/login-form';
import { User } from '../../interfaces/auth/user';
import { AuthState } from './auth.state';

export const AuthActions = createActionGroup({
  source: 'Auth',
  events: {
    load: emptyProps(),
    loadSuccess: props<Pick<AuthState, 'client' | 'user'>>(),

    login: props<LoginForm>(),
    loginSuccess: props<{ client: string; user: User }>(),
    loginFailure: emptyProps(),

    logout: emptyProps(),
  },
});
