import { createReducer, on } from '@ngrx/store';
import { SolutionsActions } from './solutions.actions';
import { initialSolutionsState } from './solutions.state';

export const solutionsReducer = createReducer(
  initialSolutionsState,

  on(SolutionsActions.clear, () => initialSolutionsState),
  on(SolutionsActions.update, (_, solutions) => solutions)
);
