import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TableColumn } from 'src/app/core/interfaces/utilities/table-columns';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent {
  @Input({ required: true }) columns!: TableColumn[];
  @Input() padding = false;
  @Input() sticky?: string;

  @Input() data?: unknown[] | null;
  @Input() customNotFound = false;
  @Input() notFoundText = 'Brak danych';
  @Input() spinner: 'inline' | 'center' = 'center';
}
