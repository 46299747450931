import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AssignmentsState } from './assignments.state';

const selectAssignments =
  createFeatureSelector<AssignmentsState>('assignments');

export const selectOrdersAssignments = createSelector(
  selectAssignments,
  (state) => state.orders
);

export const selectVehiclesAssignments = createSelector(
  selectAssignments,
  (state) => state.vehicles
);
