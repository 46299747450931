import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Assignment } from '../../interfaces/common/assignment';

export const AssignmentsActions = createActionGroup({
  source: 'Assignments',
  events: {
    load: emptyProps(),
    loadSuccess: emptyProps(),

    clear: emptyProps(),
    update: props<{ assignments: Assignment[] }>(),
  },
});
