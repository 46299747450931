import { createReducer, on } from '@ngrx/store';
import { PlannerActions } from './planner.actions';
import { initialPlannerState } from './planner.state';

export const plannerReducer = createReducer(
  initialPlannerState,

  on(PlannerActions.load, (state) => ({
    ...state,
    loading: true,
  })),
  on(PlannerActions.loadSuccess, (state) => ({
    ...state,
    loading: false,
  })),

  on(PlannerActions.selectVehicle, (state, { vehicleUuid }) => ({
    ...state,
    selectedVehicleUuid: vehicleUuid,
  })),
);
