<div class="toast {{ toast.type }}">
  <app-icon [icon]="icon" [width]="toast.iconWidth ?? 20"></app-icon>

  <div class="content">
    <p class="f-semibold">{{ toast.title }}</p>
    @if (toast.message) {
      <p>{{ toast.message }}</p>
    }
  </div>

  <app-icon class="close" icon="close" [size]="10" (click)="hide()"></app-icon>
</div>
