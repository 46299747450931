import { Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import {
  Observable,
  bindCallback,
  catchError,
  filter,
  forkJoin,
  from,
  map,
  of,
  switchMap,
  zip,
} from 'rxjs';
import { environment } from 'src/config/environment';
import { Address } from '../../interfaces/common/address';
import { Coordinates } from '../../interfaces/common/coordinates';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {
  private readonly loader: Loader;

  constructor() {
    this.loader = new Loader({
      apiKey: environment.googleMapsApiKey,
      version: 'weekly',
      language: 'pl',
      libraries: ['geocoding'],
    });
  }

  public geocode(address: string, exact = false): Observable<Address[]> {
    return from(this.loader.importLibrary('places')).pipe(
      map((library) => ({
        autocompleteService: new library.AutocompleteService(),
        placesService: new library.PlacesService(document.createElement('div')),
      })),
      switchMap(({ autocompleteService, placesService }) =>
        zip(
          of(placesService),
          from(autocompleteService.getPlacePredictions({ input: address })),
        ),
      ),
      switchMap(([placesService, response]) =>
        this.mapAutocompleteResponse(placesService, response, exact),
      ),
      catchError(() => of([])),
    );
  }

  public reverseGeocode(
    coordinates: Coordinates,
    exact = false,
  ): Observable<Address[]> {
    return from(this.loader.importLibrary('geocoding')).pipe(
      map((library) => new library.Geocoder()),
      switchMap((geocoder) =>
        from(
          geocoder.geocode({
            location: {
              lat: coordinates.lat,
              lng: coordinates.lon,
            },
          }),
        ),
      ),
      map((response) => this.mapGeocoderResponse(response, exact)),
      catchError(() => of([])),
    );
  }

  private mapAutocompleteResponse(
    placesService: google.maps.places.PlacesService,
    response: google.maps.places.AutocompleteResponse,
    exact: boolean,
  ): Observable<Address[]> {
    const getDetails$ = bindCallback(
      placesService.getDetails.bind(placesService),
    );

    return forkJoin(
      response.predictions
        .filter(
          (prediction) =>
            !exact ||
            prediction.types.includes('street_address') ||
            prediction.types.includes('point_of_interest'),
        )
        .slice(0, 5)
        .map((prediction) =>
          getDetails$({ placeId: prediction.place_id }).pipe(
            map((response) => response[0]),
            filter(
              (result): result is google.maps.places.PlaceResult =>
                result != null,
            ),
            map((result) => this.mapResult(result)),
          ),
        ),
    );
  }

  private mapGeocoderResponse(
    response: google.maps.GeocoderResponse,
    exact: boolean,
  ): Address[] {
    return response.results
      .filter((result) => !exact || result.types.includes('street_address'))
      .map((result) => this.mapResult(result));
  }

  private mapResult(
    result: google.maps.places.PlaceResult | google.maps.GeocoderResult,
  ): Address {
    const route: string | null =
      result.address_components?.find((component: any) =>
        component.types.includes('route'),
      )?.long_name || null;
    const streetNumber: string | null =
      result.address_components?.find((component: any) =>
        component.types.includes('street_number'),
      )?.long_name || null;

    let street = route;
    if (route && streetNumber) {
      const numberBeforeRoute = `${streetNumber} ${route}`;
      const numberAfterRoute = `${route} ${streetNumber}`;

      if (result.formatted_address?.includes(numberBeforeRoute)) {
        street = numberBeforeRoute;
      } else {
        street = numberAfterRoute;
      }
    }

    return {
      lat: result.geometry?.location?.lat() ?? 0,
      lon: result.geometry?.location?.lng() ?? 0,
      street,
      postcode:
        result.address_components?.find((component: any) =>
          component.types.includes('postal_code'),
        )?.long_name || '??',
      city:
        result.address_components?.find((component: any) =>
          component.types.includes('locality'),
        )?.long_name || '??',
      countryCode:
        result.address_components?.find((component: any) =>
          component.types.includes('country'),
        )?.short_name || '??',
      name: (result as google.maps.places.PlaceResult).name || null,
    };
  }
}
