import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[appInnerHTML]',
})
export class InnerHtmlDirective implements OnChanges {
  @Input() appInnerHTML?: string;

  private uniqueId?: string;

  constructor(private elementRef: ElementRef) {}

  ngOnChanges(): void {
    if (this.appInnerHTML) {
      this.elementRef.nativeElement.innerHTML = this.appInnerHTML;
      this.uniqueId ??= [...this.elementRef.nativeElement.attributes].find(
        (attr) => attr.name.startsWith('_ngcontent-')
      ).name;

      const descendants = this.elementRef.nativeElement.querySelectorAll('*');
      for (const element of descendants) {
        element.setAttribute(this.uniqueId, '');
      }
    } else {
      this.elementRef.nativeElement.innerHTML = null;
    }
  }
}
