import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { first, map, switchMap, tap } from 'rxjs';
import { UnassignedReasonsService } from '../../services/firebase/unassigned-reasons.service';
import { UnassignedReasonsActions } from './unassigned-reasons.actions';

@Injectable()
export class UnassignedReasonsEffects {
  constructor(
    private actions$: Actions,
    private unassignedReasonsService: UnassignedReasonsService,
    private store: Store
  ) {}

  load$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UnassignedReasonsActions.load),
        switchMap(() =>
          this.unassignedReasonsService
            .getUnassignedReasons$()
            .pipe(
              tap((unassignedReasons) =>
                this.store.dispatch(
                  UnassignedReasonsActions.update(unassignedReasons)
                )
              )
            )
        )
      ),
    { dispatch: false }
  );
  loadSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UnassignedReasonsActions.load),
      switchMap(() =>
        this.actions$.pipe(
          ofType(UnassignedReasonsActions.update),
          first(),
          map(UnassignedReasonsActions.loadSuccess)
        )
      )
    )
  );
}
