import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { VehicleGroup } from '../../interfaces/vehicles/vehicle-group';

export const VehiclesAnalyticsActions = createActionGroup({
  source: 'VehiclesAnalytics',
  events: {
    load: emptyProps(),
    loadSuccess: emptyProps(),

    editGroups: props<{ groups: VehicleGroup[] }>(),

    clear: emptyProps(),
    update: props<{ groups: VehicleGroup[] }>(),
  },
});
