import { z } from 'zod';
import { CompanyType } from '../../enums/auth/company-type';
import { NegotiationStatus } from '../../enums/orders/negotiation-status';

const NegotiationActionSchema = z.object({
  actor: z.object({
    uid: z.string(),
    email: z.string().email(),
    company: z.object({
      id: z.string(),
      name: z.string(),
      type: z.nativeEnum(CompanyType),
    }),
  }),
  createdAt: z.string().datetime(),
});

const NegotiationOfferSchema = NegotiationActionSchema.extend({
  price: z.number(),
});

const NegotiationExecutionDataSchema = NegotiationActionSchema.extend({
  driver: z.object({
    firstName: z.string(),
    lastName: z.string(),
    phoneNumber: z.string(),
  }),
  vehiclePlateNumber: z.string(),
  semiTrailerPlateNumber: z.string(),
});

export const NegotiationSchema = z.object({
  status: z.nativeEnum(NegotiationStatus),
  vehicleUuid: z.string().uuid(),
  initialOfferPrice: z.number().nullable().optional(),
  carrierCounterOffer: NegotiationOfferSchema.optional(),
  senderFinalOffer: NegotiationOfferSchema.optional(),
  rejection: NegotiationActionSchema.optional(),
  carrierAcceptance: NegotiationActionSchema.optional(),
  senderAcceptance: NegotiationActionSchema.optional(),
  carrierExecutionData: NegotiationExecutionDataSchema.optional(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export type Negotiation = z.infer<typeof NegotiationSchema>;
