import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { Toast } from 'src/app/core/interfaces/utilities/toast';
import { ToastService } from 'src/app/core/services/utilities/toast.service';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToasterComponent {
  toasts: Toast[] = [];

  constructor(
    private toastService: ToastService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.toastService.toasts$.subscribe((toast) => {
      this.toasts = [...this.toasts, toast];
      this.changeDetectorRef.detectChanges();
    });
  }

  disposeToast(index: number): void {
    const toasts = [...this.toasts];
    toasts.splice(index, 1);
    this.toasts = toasts;
  }
}
