<label>
  @if (label) {
    <p class="label f-label-small">
      {{ label }}
      @if (required) {
        <span class="required">*</span>
      }
    </p>
  }

  <div class="input-container">
    <input
      #input
      [class]="color"
      [type]="type"
      [disabled]="disabled"
      [attr.autocomplete]="autocomplete"
      [attr.placeholder]="placeholder"
      [attr.maxlength]="maxLength"
      (focusout)="onTouched?.()"
      (keypress)="validateInput($event)"
      (paste)="validatePaste($event)"
      (input)="handleValueChange($event)"
      [ngClass]="{ invalid }"
      [ngStyle]="{
        'height.px': height,
        textAlign,
        'paddingRight.px': paddingRight()
      }"
    />

    @if (unit) {
      <p class="unit" #unitRef>{{ unit }}</p>
    }
  </div>
</label>

@if (formControl && errors) {
  <app-form-control-error
    [formControls]="[formControl]"
  ></app-form-control-error>
}

<ng-content></ng-content>
