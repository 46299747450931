import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { HistoricalCost } from '../../interfaces/planner/historical-cost';

export const HistoricalCostsActions = createActionGroup({
  source: 'HistoricalCosts',
  events: {
    load: emptyProps(),
    loadSuccess: emptyProps(),

    clear: emptyProps(),
    update: props<{ historicalCosts: HistoricalCost[] }>(),
  },
});
