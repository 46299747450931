import { Injectable } from '@angular/core';
import { Observable, from, takeUntil, throttleTime } from 'rxjs';
import { THROTTLE_TIME } from '../../constants/utilities/throttle-time';
import { PartialNull } from '../../interfaces/utilities/partial-null';
import { FirebaseVehicle } from '../../interfaces/vehicles/firebase-vehicle';
import { VehicleGroup } from '../../interfaces/vehicles/vehicle-group';
import { DatabaseService } from '../utilities/database.service';
import { FirebaseAuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class VehiclesService {
  constructor(
    private databaseService: DatabaseService,
    private firebaseAuthService: FirebaseAuthService,
  ) {}

  getVehicles$(): Observable<FirebaseVehicle[]> {
    return this.databaseService
      .list<FirebaseVehicle>('/vehicles/collection')
      .valueChanges()
      .pipe(
        takeUntil(this.firebaseAuthService.logout$),
        throttleTime(THROTTLE_TIME, undefined, { trailing: true }),
      );
  }

  getGroups$(): Observable<VehicleGroup[]> {
    return this.databaseService
      .list<VehicleGroup>('/vehicles/groups')
      .valueChanges()
      .pipe(
        takeUntil(this.firebaseAuthService.logout$),
        throttleTime(THROTTLE_TIME, undefined, { trailing: true }),
      );
  }

  editGroups(groups: VehicleGroup[]): Observable<void> {
    return from(
      this.databaseService
        .object<VehicleGroup[]>('/vehicles/groups')
        .set(groups),
    );
  }

  editVehicle(
    uuid: string,
    value: PartialNull<FirebaseVehicle>,
  ): Observable<void> {
    return from(
      this.databaseService
        .object<PartialNull<FirebaseVehicle>>(`/vehicles/collection/${uuid}`)
        .update(value),
    );
  }

  patchVehicle(uuid: string, path: string, value: object): Observable<void> {
    return from(
      this.databaseService
        .object<any>(`/vehicles/collection/${uuid}/${path}`)
        .update(value),
    );
  }
}
