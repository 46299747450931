import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-row',
  templateUrl: './form-row.component.html',
  styleUrls: ['./form-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormRowComponent {
  @Input() gap = 16;
  @Input() height?: number;
  @Input() marginTop?: number;
  @Input() marginBottom?: number;
  @Input() center = false;
}
