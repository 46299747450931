import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FormGroupComponent {
  @HostBinding('style.width') @Input() width = 'auto';

  @Input() label?: string;
  @Input() hint?: string;
  @Input() formControls?: AbstractControl[];
  @Input() gap = 0;
  @Input() errors = true;

  @Output() labelCallback = new EventEmitter<void>();

  get required(): boolean {
    if (this.formControls == null) return false;

    for (const control of this.formControls) {
      if (!control.hasValidator(Validators.required)) return false;
    }
    return true;
  }

  get disabled(): boolean {
    if (this.formControls == null) return false;

    for (const control of this.formControls) {
      if (!control.disabled) return false;
    }
    return true;
  }
}
