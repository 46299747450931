import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  Inject,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { NegotiationsService } from 'src/app/core/services/api/negotiations.service';
import { ToastService } from 'src/app/core/services/utilities/toast.service';

@Component({
  selector: 'app-order-negotiation-rejection-modal',
  templateUrl: './order-negotiation-rejection-modal.component.html',
  styleUrl: './order-negotiation-rejection-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderNegotiationRejectionModalComponent {
  protected form = this.buildForm();
  protected showOther = signal(false);
  protected loading = signal(false);
  protected close$ = new Subject<void>();

  protected disabled = computed<boolean>(() => {
    const reasons = this.reasons();
    if (Object.entries(reasons).length === 0) return true;

    if (this.showOther() && !reasons['other']) return true;
    return false;
  });

  private formValue = toSignal(this.form.valueChanges);
  private reasons = computed<Record<string, any>>(() => {
    const value = this.formValue();
    if (!value) return {};

    return Object.fromEntries(
      Object.entries(value).filter(([_, value]) => !!value),
    );
  });

  constructor(
    @Inject(DIALOG_DATA) private orderUuid: string,
    protected dialogRef: DialogRef,
    private fb: NonNullableFormBuilder,
    private negotiationsService: NegotiationsService,
    private toastService: ToastService,
  ) {
    effect(
      () => {
        const control = this.form.controls.other;
        if (this.showOther()) {
          control.setValidators([Validators.required]);
        } else {
          control.clearValidators();
          control.reset();
        }
      },
      { allowSignalWrites: true },
    );
  }

  protected submit(): void {
    if (this.disabled()) return;

    this.loading.set(true);
    this.negotiationsService
      .rejectNegotiation(this.orderUuid, this.reasons())
      .subscribe({
        next: () => {
          this.toastService.showSuccess(
            'Zlecenie odrzucone',
            'Akcja zakończona powodzeniem',
          );
          this.close$.next();
        },
        error: () =>
          this.toastService.showError(
            'Wysyłanie nie powiodło się',
            'Spróbuj jeszcze raz',
          ),
      })
      .add(() => this.loading.set(false));
  }

  private buildForm() {
    return this.fb.group({
      unavailableVehicle: this.fb.control(false),
      orderDirection: this.fb.control(false),
      price: this.fb.control(false),
      other: this.fb.control<string | null>(null),
    });
  }
}
