@if (label) {
  <p class="label f-label-small" (click)="labelCallback.emit()">
    {{ label }}
    @if (required) {
      <span class="required">*</span>
    }
    @if (hint) {
      <span class="hint">{{ hint }}</span>
    }
  </p>
}

<div class="group" [ngStyle]="{ 'gap.px': gap }">
  <ng-content></ng-content>
</div>

@if (formControls && errors) {
  <app-form-control-error
    [formControls]="formControls"
  ></app-form-control-error>
}
