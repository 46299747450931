import { DateTime } from 'luxon';
import { z } from 'zod';
import { zodErrorMessage } from '../../helpers/zod-error-message';

export const getTimeWindowSchema = (
  requiredMessage: string,
  invalidMessage: string,
) =>
  z
    .object(
      {
        start: z.string(zodErrorMessage(invalidMessage)),
        end: z.string(zodErrorMessage(invalidMessage)),
      },
      zodErrorMessage(requiredMessage),
    )
    .refine(
      (data) => {
        const startDate = DateTime.fromJSDate(new Date(data.start));
        const endDate = DateTime.fromJSDate(new Date(data.end));

        return endDate >= startDate;
      },
      { message: invalidMessage },
    );

export type TimeWindow = z.infer<ReturnType<typeof getTimeWindowSchema>>;
