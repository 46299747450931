<button
  class="{{ type }} {{ size }} {{ color }}"
  [tabIndex]="disabled ? -1 : 0"
  [type]="action"
  [disabled]="disabled"
  [ngStyle]="{ 'height.px': height }"
>
  <p [class]="type === 'primary' ? 'f-semibold' : 'f-medium'">
    <ng-content></ng-content>
  </p>
</button>
