<app-dialog app-dialog type="center" [width]="480" (close)="dialogRef.close()">
  <h3 title>Ustaw delegacje</h3>
  <form class="container" [formGroup]="form" (ngSubmit)="submit()">
    <div class="section">
      <p class="f-medium">Pojazd</p>

      <app-dropdown
        formControlName="vehicleUuids"
        label="Pojazdy"
        [options]="vehicles()"
        [multiple]="true"
        [search]="true"
        color="gray"
      ></app-dropdown>
    </div>

    <div class="section" [ngClass]="{ cyclic: isCyclic() }">
      <p class="f-medium">Okres delegacji</p>

      <app-checkbox
        formControlName="isCyclic"
        label="Delegacja cykliczna"
      ></app-checkbox>

      <app-form-group class="span">
        <app-datepicker
          formControlName="startDate"
          label="Data rozpoczęcia delegacji"
          color="gray"
        ></app-datepicker>

        <div class="separator">-</div>

        <app-datepicker
          formControlName="endDate"
          [label]="
            !isCyclic()
              ? 'Data zakończenia delegacji'
              : 'Data zakończenia cyklu'
          "
          color="gray"
        ></app-datepicker>
      </app-form-group>

      @if (isCyclic()) {
        <app-form-group>
          <app-input
            formControlName="daysOnRoute"
            label="Dni w trasie"
            placeholder="0"
            [integer]="true"
            color="gray"
          ></app-input>

          <div class="separator">/</div>

          <app-input
            formControlName="daysOnBase"
            label="Dni w trasie"
            placeholder="0"
            [integer]="true"
            color="gray"
          ></app-input>
        </app-form-group>
      }
    </div>

    <app-button action="submit" [disabled]="form.disabled">
      Zaktualizuj
    </app-button>
  </form>
</app-dialog>
