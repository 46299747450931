import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { OrderRejections } from '../../interfaces/orders/order-rejections';

export const RejectionsActions = createActionGroup({
  source: 'Rejections',
  events: {
    load: emptyProps(),
    loadSuccess: emptyProps(),

    clear: emptyProps(),
    update: props<{ rejections: OrderRejections[] }>(),
  },
});
