<span>{{ totalDistance / 1000 | number: "1.0-1" }} km</span>

@if (emptyDistance != null) {
  <p class="empty-distance">
    <app-icon icon="empty" [size]="14"></app-icon>
    <span>
      {{ emptyDistance / 1000 | number: "1.0-1" }} km ({{
        emptyDistance / totalDistance | percent: "1.0-1"
      }})
    </span>
  </p>
}
