import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { first, map, switchMap, tap } from 'rxjs';
import { VehicleGroup } from '../../interfaces/vehicles/vehicle-group';
import { VehiclesService } from '../../services/firebase/vehicles.service';
import { VehiclesAnalyticsActions } from './vehicles-analytics.actions';

@Injectable()
export class VehiclesAnalyticsEffects {
  constructor(
    private actions$: Actions,
    private vehiclesService: VehiclesService,
    private store: Store,
  ) {}

  load$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesAnalyticsActions.load),
        switchMap(() =>
          this.vehiclesService
            .getGroups$()
            .pipe(
              tap((groups) =>
                this.store.dispatch(
                  VehiclesAnalyticsActions.update({ groups }),
                ),
              ),
            ),
        ),
      ),
    { dispatch: false },
  );
  loadSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VehiclesAnalyticsActions.load),
      switchMap(() =>
        this.actions$.pipe(
          ofType(VehiclesAnalyticsActions.update),
          first(),
          map(VehiclesAnalyticsActions.loadSuccess),
        ),
      ),
    ),
  );

  editGroups$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VehiclesAnalyticsActions.editGroups),
        switchMap(({ groups }) =>
          this.vehiclesService.editGroups(groups as VehicleGroup[]),
        ),
      ),
    { dispatch: false },
  );
}
