import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { select, Store } from '@ngrx/store';
import { filter, first } from 'rxjs';
import { CompanyType } from 'src/app/core/enums/auth/company-type';
import { OrderStatus } from 'src/app/core/enums/orders/order-status';
import { OrderStatusChange } from 'src/app/core/enums/orders/order-status.change';
import { BadgeColor } from 'src/app/core/enums/utilities/badge-color';
import { User } from 'src/app/core/interfaces/auth/user';
import { Order } from 'src/app/core/interfaces/orders/order';
import { selectUser } from 'src/app/core/state/auth/auth.selectors';
import { OrdersActions } from 'src/app/core/state/orders/orders.actions';

@Component({
  selector: 'app-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderStatusComponent {
  OrderStatus = OrderStatus;
  OrderStatusChange = OrderStatusChange;
  BadgeColor = BadgeColor;
  CompanyType = CompanyType;

  @Input({ required: true }) order!: Order;

  protected user = toSignal(
    this.store.pipe(
      select(selectUser),
      filter((user): user is User => !!user),
      first(),
    ),
  );
  protected dropdown = signal(false);

  constructor(
    private elementRef: ElementRef,
    private store: Store,
  ) {}

  @HostListener('document:click', ['$event'])
  checkClickOutside(event: MouseEvent): void {
    if (!this.dropdown() || !this.elementRef) return;

    const containerElement = this.elementRef.nativeElement;
    const target = event.target as Node;

    if (!containerElement.contains(target)) {
      this.dropdown.set(false);
    }
  }

  clearRejections(): void {
    this.store.dispatch(
      OrdersActions.clearRejections({
        orderParentUuid: this.order.parentUuid,
      }),
    );
  }

  switchDropdown(event: MouseEvent): void {
    this.dropdown.update((dropdown) => !dropdown);
    event.stopPropagation();
  }

  changeStatus(event: MouseEvent, statusChange: OrderStatusChange): void {
    this.dropdown.set(false);
    event.stopPropagation();

    this.store.dispatch(
      OrdersActions.changeStatus({
        uuid: this.order.uuid,
        statusChange,
      }),
    );
  }
}
