<div class="heading">
  <h4>{{ heading }}</h4>
  <ng-content></ng-content>
</div>

<div class="content">
  <div class="item stretch">
    <span class="f-medium-1">ID zlecenia</span>
    <div class="copy">
      <span>{{ order.id }}</span>
      <app-icon icon="copy" [size]="14" (click)="copy(order.id)"></app-icon>
    </div>
  </div>

  <div class="item stretch">
    <span class="f-medium-1">UUID zlecenia</span>
    <div class="copy">
      <span>{{ order.uuid }}</span>
      <app-icon icon="copy" [size]="14" (click)="copy(order.uuid)"></app-icon>
    </div>
  </div>

  <div class="item status">
    <span class="f-medium-1">Status zlecenia</span>
    <app-order-status [order]="order"></app-order-status>
  </div>

  <div class="item">
    <span class="f-medium-1">Przydział</span>
    <app-order-assignment
      type="secondary"
      [assignment]="order.assignment"
      [status]="order.status.code"
    ></app-order-assignment>
  </div>

  <div class="item">
    <span class="f-medium-1">Typ pojazdu</span>
    <p>
      {{ VEHICLE_TYPE + "." + order.vehicleType[0] | translate
      }}<ng-container *ngIf="order.vehicleType.length > 1"
        >, +{{ order.vehicleType.length - 1 }}
      </ng-container>
    </p>
  </div>

  <div class="item">
    <span class="f-medium-1">Numer rej.</span>
    <div class="copy">
      <p>
        @if (order.vehicle) {
          {{ order.vehicle.id }}
        } @else if (order.assignedVehicleId) {
          ({{ order.assignedVehicleId | uppercase }})
        } @else {
          -
        }
      </p>

      @if (order.vehicle?.id || order.assignedVehicleId; as vehicleId) {
        <app-icon icon="copy" [size]="14" (click)="copy(vehicleId)"></app-icon>
      }
    </div>
  </div>

  <div class="item stretch">
    <span class="f-medium-1">Ładunek</span>
    <app-order-cargo
      [transportType]="order.transportType"
      [weight]="order.weight"
      [quantity]="order.quantity"
      [vehicleWeightCapacity]="order.vehicle?.weightCapacity"
    ></app-order-cargo>
  </div>

  <div class="item">
    <span class="f-medium-1">Km w zleceniu</span>
    <app-order-distance
      [distance]="order.distance"
      [emptyDistance]="order.emptyDistance"
    ></app-order-distance>
  </div>

  <div class="item">
    <span class="f-medium-1">Zleceniodawca</span>
    <p class="client">{{ order.client || "-" }}</p>
  </div>

  <div class="item stretch">
    <span class="f-medium-1">Notatka wewnętrzna</span>
    <p class="additional-information">
      {{ order.additionalInformation || "-" }}
    </p>
  </div>
</div>
