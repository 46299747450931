import { z } from 'zod';
import { zodErrorMessage } from '../../helpers/zod-error-message';

export const getCoordinatesSchema = (
  requiredMessage: string,
  invalidMessage: string,
) =>
  z.object(
    {
      lat: z
        .number(zodErrorMessage(invalidMessage))
        .min(-90, invalidMessage)
        .max(90, invalidMessage),
      lon: z
        .number(zodErrorMessage(invalidMessage))
        .min(-180, invalidMessage)
        .max(180, invalidMessage),
    },
    zodErrorMessage(requiredMessage),
  );

export type Coordinates = z.infer<ReturnType<typeof getCoordinatesSchema>>;
