import { Badge } from '../../interfaces/utilities/badge';
import { BadgeColor } from '../utilities/badge-color';

export enum OrderStatus {
  SEARCHING_VEHICLE = 'searching-vehicle',
  AWAITING_APPROVAL = 'awaiting-approval',
  UNASSIGNED = 'unassigned',
  IN_PROGRESS = 'in-progress',
  SOLD = 'sold',
  FINISHED = 'finished',
  INVALID = 'invalid',
}

export const getOrderStatus = (code: OrderStatus): Badge<OrderStatus> => {
  switch (code) {
    case OrderStatus.SEARCHING_VEHICLE:
      return { code, color: BadgeColor.VIOLET };
    case OrderStatus.AWAITING_APPROVAL:
      return { code, color: BadgeColor.ORANGE };
    case OrderStatus.UNASSIGNED:
      return { code, color: BadgeColor.GRAY };
    case OrderStatus.IN_PROGRESS:
      return { code, color: BadgeColor.BLUE };
    case OrderStatus.SOLD:
      return { code, color: BadgeColor.YELLOW };
    case OrderStatus.FINISHED:
      return { code, color: BadgeColor.GREEN };
    case OrderStatus.INVALID:
      return { code, color: BadgeColor.RED };
  }
};
