import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PlannerState } from './planner.state';

export const selectPlanner = createFeatureSelector<PlannerState>('planner');

export const selectPlannerLoading = createSelector(
  selectPlanner,
  (state) => state.loading,
);

export const selectSelectedVehicleUuid = createSelector(
  selectPlanner,
  (state) => state.selectedVehicleUuid,
);
