import { createReducer, on } from '@ngrx/store';
import { OrdersActions } from './orders.actions';
import { initialOrdersState } from './orders.state';

export const ordersReducer = createReducer(
  initialOrdersState,

  on(OrdersActions.clear, () => initialOrdersState),
  on(OrdersActions.update, (_, { orders }) =>
    Object.fromEntries(orders.map((order) => [order.uuid, order]))
  )
);
