import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Toast } from '../../interfaces/utilities/toast';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toasts$!: Observable<Toast>;

  private toasts$_ = new Subject<Toast>();

  constructor() {
    this.toasts$ = this.toasts$_.asObservable();
  }

  show(toast: Toast): void {
    this.toasts$_.next(toast);
  }

  showSuccess(title: string, message: string): void {
    this.show({
      type: 'success',
      title,
      message,
    });
  }

  showError(title: string, message: string): void {
    this.show({
      type: 'error',
      title,
      message,
    });
  }
}
