import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DelegationDialogData } from 'src/app/planner/delegations-dialog/delegations-dialog.component';
import { VehicleUnavailabilityForm } from 'src/app/planner/profitability-calculator/profitability-calculator-edit-unavailabilities-dialog/profitability-calculator-edit-unavailabilities-dialog.component';
import { ProfitabilityCalculatorOrderForm } from 'src/app/planner/profitability-calculator/profitability-calculator.component';
import { PartialNull } from '../../interfaces/utilities/partial-null';
import { FirebaseVehicle } from '../../interfaces/vehicles/firebase-vehicle';
import { Vehicle } from '../../interfaces/vehicles/vehicle';
import { VehicleEditData } from '../../interfaces/vehicles/vehicle-edit-data';

export const VehiclesActions = createActionGroup({
  source: 'Vehicles',
  events: {
    load: emptyProps(),
    loadSuccess: emptyProps(),

    clear: emptyProps(),
    update: props<{ vehicles: FirebaseVehicle[] }>(),

    showEditVehicleForm: props<VehicleEditData>(),
    editVehicle: props<{ uuid: string; value: PartialNull<FirebaseVehicle> }>(),

    patchVehicle: props<{ uuid: string; path: string; value: object }>(),
    addIncome: props<{
      vehicle: Vehicle;
      order: ProfitabilityCalculatorOrderForm;
    }>(),
    editIncome: props<{
      vehicle: Vehicle;
      month: string;
      data: Pick<ProfitabilityCalculatorOrderForm, 'income' | 'distance'>;
    }>(),
    addUnavailabilities: props<{
      vehicle: Vehicle;
      month: string;
      unavailabilities: VehicleUnavailabilityForm[];
    }>(),

    showDelegationsDialog: props<DelegationDialogData>(),
  },
});
