import { Injectable } from '@angular/core';
import { Observable, map, takeUntil, throttleTime } from 'rxjs';
import { THROTTLE_TIME } from '../../constants/utilities/throttle-time';
import { FirebaseSolutionPoint } from '../../interfaces/vehicles/firebase-solution-point';
import { DatabaseService } from '../utilities/database.service';
import { FirebaseAuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class SolutionsService {
  constructor(
    private databaseService: DatabaseService,
    private firebaseAuthService: FirebaseAuthService,
  ) {}

  getSolutions$(): Observable<Record<string, FirebaseSolutionPoint[]>> {
    return this.databaseService
      .list<FirebaseSolutionPoint[]>('/solution')
      .snapshotChanges()
      .pipe(
        takeUntil(this.firebaseAuthService.logout$),
        throttleTime(THROTTLE_TIME, undefined, { trailing: true }),
        map((actions) =>
          Object.fromEntries(
            actions.map((action) => [action.key!, action.payload.val()!]),
          ),
        ),
      );
  }
}
