export class TwoWayMap<T> {
  private map: Map<T, T>;
  private reverseMap: Map<T, T>;

  constructor(data: [T, T][]) {
    this.map = new Map(data);
    this.reverseMap = new Map(data.map((e) => e.reverse()) as [T, T][]);
  }

  get(key: T): T | undefined {
    return this.map.get(key);
  }

  revGet(key: T): T | undefined {
    return this.reverseMap.get(key);
  }
}
