import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/config/environment';
import { DelegationsForm } from '../../interfaces/vehicles/delegations-form';

@Injectable({
  providedIn: 'root',
})
export class DelegationsService {
  constructor(private http: HttpClient) {}

  updateDelegations(form: DelegationsForm): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/delegations`, form);
  }
}
