import { createReducer, on } from '@ngrx/store';
import { AssignmentsActions } from './assignments.actions';
import { initialAssignmentsState } from './assignments.state';

export const assignmentsReducer = createReducer(
  initialAssignmentsState,

  on(AssignmentsActions.clear, () => initialAssignmentsState),
  on(AssignmentsActions.update, (_, { assignments }) => ({
    orders: Object.fromEntries(
      assignments.map((assignment) => [
        assignment.orderParentUuid,
        assignment.vehicleUuid,
      ])
    ),
    vehicles: assignments.reduce(
      (vehicles: Record<string, string[]>, assignment) => {
        vehicles[assignment.vehicleUuid] ??= [];
        vehicles[assignment.vehicleUuid].push(assignment.orderParentUuid);
        return vehicles;
      },
      {}
    ),
  }))
);
