import { ElementRef } from '@angular/core';

const VERTICAL_OFFSET = 8;
const VERTICAL_POSITION = `calc(100% + ${VERTICAL_OFFSET}px)`;
const HORIZONTAL_POSITION = '0';

export class DropdownPositionHelper {
  static setPosition(
    containerRef: ElementRef<HTMLElement>,
    dropdownRef: ElementRef<HTMLElement>,
    maxHeight?: number
  ): void {
    const container = containerRef.nativeElement;
    const dropdown = dropdownRef.nativeElement;
    const containerRect = container.getBoundingClientRect();
    const dropdownRect = dropdown.getBoundingClientRect();
    const dropdownHeight = (maxHeight ?? dropdownRect.height) + VERTICAL_OFFSET;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    this.resetPosition(dropdown);
    this.setVerticalPosition(
      containerRect,
      dropdownHeight,
      windowHeight,
      dropdown
    );
    this.setHorizontalPosition(
      containerRect,
      dropdownRect,
      windowWidth,
      dropdown
    );
  }

  private static resetPosition(element: HTMLElement): void {
    const properties = ['top', 'right', 'bottom', 'left'];
    for (const property of properties) {
      element.style.removeProperty(property);
    }
    element.style.visibility = 'visible';
  }

  private static setVerticalPosition(
    containerRect: DOMRect,
    dropdownHeight: number,
    windowHeight: number,
    dropdown: HTMLElement
  ) {
    if (
      containerRect.bottom + dropdownHeight > windowHeight &&
      containerRect.top - dropdownHeight >= 0
    ) {
      dropdown.style.bottom = VERTICAL_POSITION;
    } else {
      dropdown.style.top = VERTICAL_POSITION;
    }
  }

  private static setHorizontalPosition(
    containerRect: DOMRect,
    dropdownRect: DOMRect,
    windowWidth: number,
    dropdown: HTMLElement
  ) {
    if (
      containerRect.left + dropdownRect.width > windowWidth &&
      containerRect.right - dropdownRect.width >= 0
    ) {
      dropdown.style.right = HORIZONTAL_POSITION;
    } else {
      dropdown.style.left = HORIZONTAL_POSITION;
    }
  }
}
