import { createReducer, on } from '@ngrx/store';
import { HistoricalCostsActions } from './historical-costs.actions';
import { initialHistoricalCostsState } from './historical-costs.state';

export const historicalCostsReducer = createReducer(
  initialHistoricalCostsState,

  on(HistoricalCostsActions.clear, () => initialHistoricalCostsState),
  on(
    HistoricalCostsActions.update,
    (_, { historicalCosts }) => historicalCosts,
  ),
);
