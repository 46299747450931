<app-icon class="search" icon="search" [size]="12"></app-icon>

<input
  [class]="color"
  type="text"
  [placeholder]="placeholder"
  [(ngModel)]="value"
  (ngModelChange)="valueChange.emit(value)"
  [ngStyle]="{ 'height.px': height }"
/>
