import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/config/environment';
import { DictionaryCode } from '../../enums/utilities/dictionary-code';
import { Dictionary } from '../../interfaces/utilities/dictionary';

@Injectable({
  providedIn: 'root',
})
export class DictionariesService {
  constructor(private http: HttpClient) {}

  getDictionary<T = string>(
    code: DictionaryCode | string,
  ): Observable<Dictionary<T>> {
    return this.http.get<Dictionary<T>>(
      `${environment.apiUrl}/dictionaries/${code}`,
    );
  }
}
