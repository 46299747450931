import { createReducer, on } from '@ngrx/store';
import { VehiclesAnalyticsActions } from './vehicles-analytics.actions';
import { initialVehiclesAnalyticsState } from './vehicles-analytics.state';

export const vehiclesAnalyticsReducer = createReducer(
  initialVehiclesAnalyticsState,

  on(VehiclesAnalyticsActions.clear, () => initialVehiclesAnalyticsState),
  on(VehiclesAnalyticsActions.update, (_, { groups }) => ({ groups })),
);
