export class StringHelper {
  static toTitleCase(value: string): string {
    return value
      .split(' ')
      .map(
        (substring) => substring.charAt(0).toUpperCase() + substring.slice(1)
      )
      .join(' ');
  }
}
