import { FormGroup } from '@angular/forms';

export const dateRangeValidator = (
  fromControlName: string,
  toControlName: string,
) => {
  return (formGroup: FormGroup): void => {
    const fromControl = formGroup.get(fromControlName)!;
    const toControl = formGroup.get(toControlName)!;

    if (!fromControl.valid || !toControl.valid) return;
    if (!fromControl.value || !toControl.value) return;

    const fromDate = new Date(fromControl.value);
    const toDate = new Date(toControl.value);
    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(0, 0, 0, 0);

    if (fromDate > toDate) {
      fromControl.setErrors({ dateRange: true });
    } else {
      fromControl.setErrors(null);
    }
  };
};
