import { Badge } from '../../interfaces/utilities/badge';
import { BadgeColor } from '../utilities/badge-color';

export enum VehicleStatus {
  AVAILABLE = 'available',
  AVAILABLE_PLANNED = 'available-planned',
  IN_PROGRESS = 'in-progress',
  IN_PROGRESS_PLANNED = 'in-progress-planned',
  TRAILER = 'trailer',
  INVALID = 'invalid',
  INACTIVE = 'inactive',
}

export const getVehicleStatus = (code: VehicleStatus): Badge<VehicleStatus> => {
  switch (code) {
    case VehicleStatus.AVAILABLE:
      return { code, color: BadgeColor.GREEN };
    case VehicleStatus.AVAILABLE_PLANNED:
      return { code, color: BadgeColor.BLACK };
    case VehicleStatus.IN_PROGRESS:
      return { code, color: BadgeColor.BLUE };
    case VehicleStatus.IN_PROGRESS_PLANNED:
      return { code, color: BadgeColor.VIOLET };
    case VehicleStatus.TRAILER:
      return { code, color: BadgeColor.ORANGE };
    case VehicleStatus.INVALID:
      return { code, color: BadgeColor.RED };
    case VehicleStatus.INACTIVE:
      return { code, color: BadgeColor.GRAY };
  }
};
