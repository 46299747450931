<div
  class="row"
  [ngStyle]="{
    'gap.px': gap,
    'height.px': height,
    'marginTop.px': marginTop,
    'marginBottom.px': marginBottom,
    alignItems: center ? 'center' : 'flex-start'
  }"
>
  <ng-content></ng-content>
</div>

<ng-content select="app-form-control-error"></ng-content>
