export enum NegotiationStatus {
  INITIAL_OFFER = 'initial-offer',
  COUNTER_OFFER = 'counter-offer',
  FINAL_OFFER = 'final-offer',
  CARRIER_ACCEPTED = 'carrier-accepted',
  SENDER_ACCEPTED = 'sender-accepted',
  COMPLETED = 'completed',
  REJECTED = 'rejected',
  EXPIRED = 'expired',
}
