import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { AppState } from './core/state/app.state';
import { AuthActions } from './core/state/auth/auth.actions';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private translateService: TranslateService,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.translateService.setDefaultLang('');
    this.store.dispatch(AuthActions.load());

    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        gtag('config', 'G-2PD9F271HE', {
          page_path: event.urlAfterRedirects,
          send_page_view: true,
        });
      });
  }
}
