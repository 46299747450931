<app-dialog
  type="center"
  [width]="480"
  [close$]="close$"
  (close)="dialogRef.close()"
>
  <h3 title>Odrzuć pojazd w zleceniu</h3>

  <div class="container">
    <form [formGroup]="form">
      <p>
        Powód odrzucenia ({{ reasonsLength() }})
        <span class="required">*</span>
      </p>

      <app-checkbox
        formControlName="vehicleIssues"
        label="Problem z pojazdem"
        [disabled]="reassignVehicle()"
      ></app-checkbox>

      <app-checkbox
        formControlName="driverIssues"
        label="Problem z kierowcą"
        [disabled]="reassignVehicle()"
      ></app-checkbox>

      <app-checkbox
        formControlName="emptyDistance"
        label="Puste kilometry"
        [disabled]="reassignVehicle()"
      ></app-checkbox>

      <app-checkbox
        formControlName="waitTime"
        label="Czas oczekiwania"
        [disabled]="reassignVehicle()"
      ></app-checkbox>

      <app-checkbox
        formControlName="noValidDocuments"
        label="Brak ważnego dokumentu"
        [disabled]="reassignVehicle()"
      ></app-checkbox>

      <app-checkbox
        label="Inne"
        [value]="showOtherInput()"
        [disabled]="reassignVehicle()"
        (valueChange)="showOtherInput.set($event)"
      ></app-checkbox>

      @if (showOtherInput()) {
        <app-input
          formControlName="other"
          placeholder="Powód"
          [disabled]="reassignVehicle()"
        ></app-input>
      }

      <onyx-toggle
        class="reassign-vehicle"
        formControlName="reassignVehicle"
        label="Chcę przypisać inny pojazd"
        size="s"
        [value]="reassignVehicle()"
        (valueChange)="reassignVehicle.set($event)"
      ></onyx-toggle>
    </form>

    <div class="buttons">
      <p class="f-semibold f-link" (click)="close()">Anuluj</p>
      <app-button
        [color]="reassignVehicle() ? 'blue' : 'red'"
        [disabled]="disabled()"
        (click)="submit()"
      >
        {{ reassignVehicle() ? "Przypisz inny pojazd" : "Odrzuć" }}
      </app-button>
    </div>
  </div>
</app-dialog>
