import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Injector,
  Input,
  Output,
  ViewChild,
  forwardRef,
  signal,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownPositionHelper } from 'src/app/core/helpers/dropdown-position-helper';
import { InputColor } from 'src/app/core/interfaces/utilities/input-color';
import { BaseFormControlComponent } from '../../forms/base-form-control.component';

enum InputType {
  Hours,
  Minutes,
}

const TIME_PATTERN = /^(?<hours>[0-9]{2}):(?<minutes>[0-9]{2}):?(?:[0-9]{2})?$/;

@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimepickerComponent),
      multi: true,
    },
  ],
})
export class TimepickerComponent
  extends BaseFormControlComponent
  implements ControlValueAccessor
{
  readonly InputType = InputType;

  @HostBinding('style.width') @Input() width = '100%';

  @ViewChild('inputRef') inputRef!: ElementRef<HTMLDivElement>;
  @ViewChild('pickerRef') pickerRef!: ElementRef<HTMLDivElement>;
  @ViewChild('hoursInputRef') hoursInputRef!: ElementRef<HTMLInputElement>;
  @ViewChild('minutesInputRef') minutesInputRef!: ElementRef<HTMLInputElement>;

  @Input() color: InputColor = 'white';
  @Input() label?: string;
  @Input() height = 40;
  @Input() placeholder?: string;
  @Input() disabled = false;
  @Input() errors = true;

  @Input() set value(value: string | null) {
    const match = value?.match(TIME_PATTERN);
    if (match) {
      this.hours.set(Number(match.groups!['hours']));
      this.minutes.set(Number(match.groups!['minutes']));
    } else {
      this.hours.set(undefined);
      this.minutes.set(undefined);
    }
  }
  get value(): string | null {
    const hours = this.hours()?.toString()?.padStart(2, '0');
    const minutes = this.minutes()?.toString()?.padStart(2, '0');

    const value = `${hours}:${minutes}:00`;
    return TIME_PATTERN.test(value) ? value : null;
  }

  @Output() valueChange = new EventEmitter<string | null>();

  picker = signal(false);
  hours = signal<number | undefined>(undefined);
  minutes = signal<number | undefined>(undefined);

  constructor(
    protected override injector: Injector,
    private elementRef: ElementRef<HTMLElement>,
  ) {
    super(injector);
  }

  onChange?: (value: string | null) => void;
  onTouched?: () => void;

  registerOnChange(fn: (value: string | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  writeValue(value: string | null): void {
    this.value = value;
  }

  @HostListener('document:click', ['$event'])
  checkClickOutside(event: MouseEvent): void {
    if (!this.picker()) return;

    const containerElement = this.elementRef.nativeElement;
    const target = event.target as Node;

    if (target.parentNode && !containerElement.contains(target)) {
      this.picker.set(false);
    }
  }

  @HostListener('focusout', ['$event'])
  handleFocusout(event: FocusEvent): void {
    const containerElement = this.elementRef.nativeElement;
    const relatedTarget = event.relatedTarget as HTMLElement | null;

    if (!relatedTarget || relatedTarget.className === 'cdk-dialog-container')
      return;
    if (!containerElement.contains(relatedTarget)) {
      this.picker.set(false);
    }
  }

  switchPicker(): void {
    this.picker.update((picker) => !picker);
    if (this.picker()) {
      setTimeout(() => {
        DropdownPositionHelper.setPosition(this.elementRef, this.pickerRef);
        this.hoursInputRef.nativeElement.focus();
      });
    }
  }

  handleInputKeyDown(event: KeyboardEvent): void {
    const key = event.key;
    if (key === ' ' || key === 'Enter') {
      this.switchPicker();
    }
  }

  handleKeyDown(event: KeyboardEvent, type: InputType): boolean {
    const [key, target] = [event.key, event.target as HTMLInputElement];

    if (key === 'Escape') {
      this.inputRef.nativeElement.focus();
      this.picker.set(false);
      event.preventDefault();
      return false;
    }

    if (key === 'Backspace' || key === 'Delete') {
      target.value = '';

      if (type === InputType.Hours) {
        this.hours.set(undefined);
      } else if (type === InputType.Minutes) {
        if (this.minutes() == null) {
          this.hours.set(undefined);
          this.hoursInputRef.nativeElement.focus();
        } else {
          this.minutes.set(undefined);
        }
      }
      this.handleValueChange();

      event.preventDefault();
      return false;
    }

    if (key === 'ArrowUp' || key === 'ArrowDown') {
      this.changeInputValue(type, key === 'ArrowUp' ? 1 : -1);
      event.preventDefault();
      return false;
    }

    return true;
  }

  handleKeyPress(event: KeyboardEvent, type: InputType): boolean {
    const digit = event.key;
    if (digit < '0' || digit > '9') return false;

    if (type === InputType.Hours) {
      const value = Number((this.hours()?.toString() ?? '') + digit) % 100;

      if (value > 23) return false;
      if (value >= 10) {
        const minutesInput = this.minutesInputRef.nativeElement;
        minutesInput.focus();
        setTimeout(() => minutesInput.select());
      }

      this.hours.set(value);
    } else if (type === InputType.Minutes) {
      const value = Number((this.minutes()?.toString() ?? '') + digit) % 100;

      if (value > 59) return false;
      this.minutes.set(value);
    }

    this.handleValueChange();
    return false;
  }

  changeInputValue(type: InputType, direction: -1 | 1): void {
    if (type === InputType.Hours) {
      const change = direction;
      const value = (this.hours() ?? 0) + change;
      this.hours.set(value < 0 ? 23 : value % 24);
    } else if (type === InputType.Minutes) {
      const minutes = this.minutes() ?? 0;
      const change =
        minutes % 10 === 0 ? 10 * direction : (minutes % 10) * direction;
      const value = minutes + change;
      this.minutes.set(value < 0 ? 50 : value % 60);
    }
    this.handleValueChange();
  }

  handleValueChange(): void {
    const value = this.value;
    this.onChange?.(value);
    this.valueChange.emit(value);
  }
}
