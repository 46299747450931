<div
  class="container {{ type }}"
  [ngClass]="{ 'close-animation': closeAnimation }"
  (mouseup)="clickOutside()"
>
  <div
    class="dialog"
    [ngClass]="{ alt }"
    [ngStyle]="{ 'width.px': width }"
    (mousedown)="clickInside()"
    #dialog
  >
    @if (heading) {
      <div class="heading">
        <ng-content select="[title]"></ng-content>

        @switch (type) {
          @case ("center") {
            <ng-container
              *ngTemplateOutlet="closeButtonTemplate"
            ></ng-container>
          }
          @default {
            <ng-content select="[title-options]"></ng-content>
          }
        }
      </div>
    }

    <div class="content">
      <ng-content></ng-content>

      <div class="bottom"><ng-content select="[bottom]"></ng-content></div>
    </div>
  </div>

  @if (type !== "center") {
    <ng-container *ngTemplateOutlet="closeButtonTemplate"></ng-container>
  }
</div>

<ng-template #closeButtonTemplate>
  @if (type === "center") {
    <app-icon
      class="close-button"
      icon="close"
      [size]="12"
      (click)="closeDialog()"
    ></app-icon>
  }
</ng-template>
