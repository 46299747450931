import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @HostBinding('style.width') @Input() width?: string;

  @Input() type: 'primary' | 'outline' | 'ghost' | 'action' = 'primary';
  @Input() size: 's' | 'm' | 'l' = 'm';
  @Input() color: 'blue' | 'green' | 'red' = 'blue';
  @Input() action: 'button' | 'submit' | 'reset' = 'button';
  @Input() height?: number;
  @Input() disabled = false;
}
