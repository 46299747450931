import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { AuthInterceptorSkipHeader } from '../constants/auth/auth-interceptor-skip-header';
import { FirebaseAuthService } from '../services/firebase/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private firebaseAuthService: FirebaseAuthService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (request.headers.has(AuthInterceptorSkipHeader)) {
      const headers = request.headers.delete(AuthInterceptorSkipHeader);
      return next.handle(request.clone({ headers }));
    }

    return this.firebaseAuthService.getIdToken().pipe(
      switchMap((token) => {
        const client = this.firebaseAuthService.getClient();
        if (token && client) request = this.addHeaders(request, token, client);

        return next.handle(request);
      }),
    );
  }

  addHeaders(request: HttpRequest<unknown>, token: string, client: string) {
    return request.clone(
      request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          'X-Client': client,
        },
      }),
    );
  }
}
