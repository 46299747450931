import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import {
  combineLatest,
  filter,
  first,
  map,
  repeat,
  switchMap,
  tap,
} from 'rxjs';
import { AssignmentsActions } from '../assignments/assignments.actions';
import { selectAuth } from '../auth/auth.selectors';
import { OrdersActions } from '../orders/orders.actions';
import { RejectionsActions } from '../rejections/rejections.actions';
import { SolutionsActions } from '../solutions/solutions.actions';
import { UnassignedReasonsActions } from '../unassigned-reasons/unassigned-reasons.actions';
import { VehiclesAnalyticsActions } from '../vehicles-analytics/vehicles-analytics.actions';
import { VehiclesActions } from '../vehicles/vehicles.actions';
import { PlannerActions } from './planner.actions';

@Injectable()
export class PlannerEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private router: Router,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlannerActions.load),
      switchMap(() =>
        this.store.pipe(
          select(selectAuth),
          filter((auth) => !!auth.user),
          first(),
        ),
      ),
      switchMap(() => [
        AssignmentsActions.load(),
        OrdersActions.load(),
        RejectionsActions.load(),
        SolutionsActions.load(),
        UnassignedReasonsActions.load(),
        VehiclesActions.load(),
        // HistoricalCostsActions.load(),
        VehiclesAnalyticsActions.load(),
      ]),
    ),
  );
  loadSuccess$ = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType(AssignmentsActions.loadSuccess), first()),
      this.actions$.pipe(ofType(OrdersActions.loadSuccess), first()),
      this.actions$.pipe(ofType(RejectionsActions.loadSuccess), first()),
      this.actions$.pipe(ofType(SolutionsActions.loadSuccess), first()),
      this.actions$.pipe(ofType(UnassignedReasonsActions.loadSuccess), first()),
      this.actions$.pipe(ofType(VehiclesActions.loadSuccess), first()),
      // this.actions$.pipe(ofType(HistoricalCostsActions.loadSuccess), first()),
      this.actions$.pipe(ofType(VehiclesAnalyticsActions.loadSuccess), first()),
    ]).pipe(map(PlannerActions.loadSuccess), repeat()),
  );

  selectVehicle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PlannerActions.selectVehicle),
        tap(() => this.router.navigateByUrl('/planner/map')),
      ),
    { dispatch: false },
  );

  clear$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlannerActions.clear),
      switchMap(() => [
        AssignmentsActions.clear(),
        OrdersActions.clear(),
        RejectionsActions.clear(),
        SolutionsActions.clear(),
        UnassignedReasonsActions.clear(),
        VehiclesActions.clear(),
        // HistoricalCostsActions.clear(),
        VehiclesAnalyticsActions.clear(),
      ]),
    ),
  );
}
