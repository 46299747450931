import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { first, map, switchMap, tap } from 'rxjs';
import { SolutionsService } from '../../services/firebase/solutions.service';
import { SolutionsActions } from './solutions.actions';

@Injectable()
export class SolutionsEffects {
  constructor(
    private actions$: Actions,
    private solutionsService: SolutionsService,
    private store: Store
  ) {}

  load$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SolutionsActions.load),
        switchMap(() =>
          this.solutionsService
            .getSolutions$()
            .pipe(
              tap((solutions) =>
                this.store.dispatch(SolutionsActions.update(solutions))
              )
            )
        )
      ),
    { dispatch: false }
  );
  loadSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SolutionsActions.load),
      switchMap(() =>
        this.actions$.pipe(
          ofType(SolutionsActions.update),
          first(),
          map(SolutionsActions.loadSuccess)
        )
      )
    )
  );
}
