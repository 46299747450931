import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { first, map, switchMap, tap } from 'rxjs';
import { AssignmentsService } from 'src/app/core/services/firebase/assignments.service';
import { AssignmentsActions } from './assignments.actions';

@Injectable()
export class AssignmentsEffects {
  constructor(
    private actions$: Actions,
    private assignmentsService: AssignmentsService,
    private store: Store
  ) {}

  load$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AssignmentsActions.load),
        switchMap(() =>
          this.assignmentsService
            .getAssignments$()
            .pipe(
              tap((assignments) =>
                this.store.dispatch(AssignmentsActions.update({ assignments }))
              )
            )
        )
      ),
    { dispatch: false }
  );
  loadSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssignmentsActions.load),
      switchMap(() =>
        this.actions$.pipe(
          ofType(AssignmentsActions.update),
          first(),
          map(AssignmentsActions.loadSuccess)
        )
      )
    )
  );
}
