<table [ngClass]="{ padding }">
  <thead>
    <tr>
      @for (column of columns; track $index) {
        <th
          [ngClass]="{ sticky }"
          [ngStyle]="{ width: column.width, top: sticky }"
        >
          <p class="f-label-small">{{ column.name }}</p>
        </th>
      }
    </tr>
  </thead>

  <tbody>
    @if (!data || (data.length === 0 && !customNotFound)) {
      <tr class="not-found-row">
        <td [attr.colspan]="columns.length">
          @if (!data) {
            <app-spinner
              [class]="spinner"
              [size]="spinner === 'inline' ? 'small' : 'medium'"
            ></app-spinner>
          } @else if (data.length === 0) {
            <p class="not-found">
              {{ notFoundText }}
            </p>
          }
        </td>
      </tr>
    }

    <ng-content></ng-content>
  </tbody>
</table>
