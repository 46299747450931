import { Component, Injector } from '@angular/core';
import { FormGroupDirective, NgControl, Validators } from '@angular/forms';

@Component({
  template: ``,
})
export class BaseFormControlComponent {
  protected formGroupDirective?: FormGroupDirective;
  protected formControl?: NgControl;

  constructor(protected injector: Injector) {}

  protected get invalid(): boolean {
    if (!this.formGroupDirective || !this.formControl) return false;
    return (
      (this.formControl.invalid ?? false) &&
      ((this.formControl.dirty && this.formControl.touched) ||
        this.formGroupDirective.submitted)
    );
  }

  protected get required(): boolean {
    if (!this.formGroupDirective || !this.formControl?.control) return false;
    return this.formControl.control.hasValidator(Validators.required);
  }

  ngOnInit(): void {
    this.formGroupDirective =
      this.injector.get(FormGroupDirective, undefined, {
        optional: true,
      }) ?? undefined;
    if (!this.formGroupDirective) return;

    this.formControl =
      this.injector.get(NgControl, undefined, {
        optional: true,
      }) ?? undefined;
  }
}
