import { Injectable } from '@angular/core';
import {
  Observable,
  concat,
  filter,
  from,
  ignoreElements,
  map,
  of,
  takeUntil,
  throttleTime,
} from 'rxjs';
import { THROTTLE_TIME } from '../../constants/utilities/throttle-time';
import { Assignment } from '../../interfaces/common/assignment';
import { FirebaseOrder } from '../../interfaces/orders/firebase-order';
import { Order } from '../../interfaces/orders/order';
import { SingleOrderForm } from '../../interfaces/orders/single-order-form';
import { MapRoute } from '../../interfaces/utilities/here-maps/map-route';
import { PartialNull } from '../../interfaces/utilities/partial-null';
import { DatabaseService } from '../utilities/database.service';
import { HereMapsService } from '../utilities/here-maps.service';
import { ToastService } from '../utilities/toast.service';
import { FirebaseAuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class FirebaseOrdersService {
  constructor(
    private databaseService: DatabaseService,
    private firebaseAuthService: FirebaseAuthService,
    private hereMapsService: HereMapsService,
    private toastService: ToastService,
  ) {}

  getOrders$(): Observable<FirebaseOrder[]> {
    return this.databaseService
      .list<FirebaseOrder>('/orders/collection')
      .valueChanges()
      .pipe(
        takeUntil(this.firebaseAuthService.logout$),
        throttleTime(THROTTLE_TIME, undefined, { trailing: true }),
      );
  }

  addOrder(order: FirebaseOrder): Observable<void> {
    return from(
      this.databaseService
        .object<FirebaseOrder>(`/orders/collection/${order.uuid}`)
        .set(order),
    );
  }

  editOrder(uuid: string, value: PartialNull<FirebaseOrder>): Observable<void> {
    return from(
      this.databaseService
        .object<PartialNull<FirebaseOrder>>(`/orders/collection/${uuid}`)
        .update(value),
    );
  }

  deleteOrder(uuid: string): Observable<void> {
    return from(
      this.databaseService
        .object<FirebaseOrder>(`/orders/collection/${uuid}`)
        .remove(),
    );
  }

  assignVehicle(
    orderParentUuid: string,
    orders: Order[],
    vehicle: { uuid: string; id?: string },
    assignedByOnyx: boolean,
  ): Observable<void> {
    const clearRejects$ = assignedByOnyx
      ? of()
      : from(
          this.databaseService
            .object(`/rejectedVehiclesForOrder/collection/${orderParentUuid}`)
            .remove(),
        );
    const assignOrders$ = from(
      Promise.all(
        orders.map((order) =>
          this.databaseService
            .object<FirebaseOrder>(`/orders/collection/${order.uuid}`)
            .update({
              assigned: true,
              assignedVehicleUuid: vehicle.uuid,
              ...(vehicle.id && { assignedVehicleId: vehicle.id }),
              assignedByOnyx,
            }),
        ),
      ),
    ).pipe(ignoreElements());

    return concat(clearRejects$, assignOrders$);
  }

  rejectAssignment(
    assignment: Assignment,
    reasons: Record<string, any>,
  ): Observable<void> {
    const { orderParentUuid, vehicleUuid } = assignment;
    return from(
      this.databaseService
        .object(
          `/rejectedVehiclesForOrder/collection/${orderParentUuid}/${vehicleUuid}`,
        )
        .set(reasons),
    );
  }

  clearRejections(orderParentUuid: string): Observable<void> {
    return from(
      this.databaseService
        .object(`/rejectedVehiclesForOrder/collection/${orderParentUuid}`)
        .remove(),
    );
  }

  calculateDistance(form: SingleOrderForm): Observable<number> {
    const waypoints = [form.pickupAddress, form.deliveryAddress].map(
      (address) => ({ address }),
    );

    return this.hereMapsService
      .findRoute(waypoints, {
        routingMode: 'fast',
        return: ['summary'],
      })
      .pipe(
        map((routes) => routes[0]),
        filter((route): route is MapRoute => {
          if (route == null) {
            this.toastService.showError('Wystąpił błąd', 'Nieprawidłowa trasa');
          }
          return route != null;
        }),
        map((route) => route.summary!.distance),
      );
  }
}
