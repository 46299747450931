import { DateTime } from 'luxon';
import { TimeWindow } from '../interfaces/common/time-window';
import { StringHelper } from './string-helper';

export class OrderHelper {
  static getLabel(timeWindow: TimeWindow, long = false): string {
    const hoursLabel = this.getHoursLabel(timeWindow);
    const datesLabel = this.getDatesLabel(timeWindow, long);
    return `${hoursLabel}, ${datesLabel}`;
  }

  static getHoursLabel(timeWindow: TimeWindow): string {
    const startDate = DateTime.fromJSDate(new Date(timeWindow.start));
    const endDate = DateTime.fromJSDate(new Date(timeWindow.end));

    const startString = startDate.toFormat('HH:mm');
    const endString = endDate.toFormat('HH:mm');

    if (startString === endString) return startString;
    return `${startString}-${endString}`;
  }

  static getDatesLabel(timeWindow: TimeWindow, long = false): string {
    const yearFormat = long ? 'y' : '\u2018yy';
    const startDate = DateTime.fromJSDate(new Date(timeWindow.start)).startOf(
      'day',
    );
    const endDate = DateTime.fromJSDate(new Date(timeWindow.end)).startOf(
      'day',
    );

    if (startDate.equals(endDate))
      return StringHelper.toTitleCase(
        startDate.toFormat(`d MMM ${yearFormat}`),
      );

    if (startDate.hasSame(endDate, 'year')) {
      if (startDate.hasSame(endDate, 'month')) {
        return StringHelper.toTitleCase(
          startDate.toFormat('d') +
            '-' +
            endDate.toFormat(`d MMM ${yearFormat}`),
        );
      }

      return StringHelper.toTitleCase(
        startDate.toFormat('d MMM') +
          ' - ' +
          endDate.toFormat(`d MMM ${yearFormat}`),
      );
    }

    return StringHelper.toTitleCase(
      startDate.toFormat(`d MMM ${yearFormat}`) +
        ' - ' +
        endDate.toFormat(`d MMM ${yearFormat}`),
    );
  }
}
