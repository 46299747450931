import { createReducer, on } from '@ngrx/store';
import { RejectionsActions } from './rejections.actions';
import { initialRejectionsState } from './rejections.state';

export const rejectionsReducer = createReducer(
  initialRejectionsState,

  on(RejectionsActions.clear, () => initialRejectionsState),
  on(RejectionsActions.update, (_, { rejections }) =>
    Object.fromEntries(
      rejections.map((rejection) => [
        rejection.orderParentUuid,
        rejection.vehicleUuids,
      ])
    )
  )
);
