import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const PlannerActions = createActionGroup({
  source: 'Planner',
  events: {
    load: emptyProps(),
    loadSuccess: emptyProps(),

    selectVehicle: props<{ vehicleUuid?: string }>(),

    clear: emptyProps(),
  },
});
