import { z } from 'zod';
import { zodErrorMessage } from '../../helpers/zod-error-message';

export const DriverTimeSchema = z
  .object(
    {
      express: z.boolean(zodErrorMessage('driverTime.invalid')).default(false),
      pointInTime: z
        .string(zodErrorMessage('driverTime.invalid'))
        .datetime('driverTime.invalid'),
      nextBreakTimeRemaining: z
        .number(zodErrorMessage('driverTime.invalid'))
        .int('driverTime.invalid')
        .min(0, 'driverTime.invalid')
        .optional(),
      dayTimeRemaining: z
        .number(zodErrorMessage('driverTime.invalid'))
        .int('driverTime.invalid')
        .min(0, 'driverTime.invalid')
        .optional(),
      weekTimeRemaining: z
        .number(zodErrorMessage('driverTime.invalid'))
        .int('driverTime.invalid')
        .min(0, 'driverTime.invalid')
        .optional(),
      fortnightTimeRemaining: z
        .number(zodErrorMessage('driverTime.invalid'))
        .int('driverTime.invalid')
        .min(0, 'driverTime.invalid')
        .optional(),
      breakEndTime: z
        .string(zodErrorMessage('driverTime.invalid'))
        .datetime('driverTime.invalid')
        .optional(),
    },
    zodErrorMessage('driverTime.required'),
  )
  .refine(
    (data) => {
      if (data.express) return true;
      return (
        data.nextBreakTimeRemaining != null &&
        data.dayTimeRemaining != null &&
        data.weekTimeRemaining != null &&
        data.fortnightTimeRemaining != null &&
        data.breakEndTime != null
      );
    },
    { message: 'driverTime.invalid' },
  );

export type DriverTime = z.infer<typeof DriverTimeSchema>;
