import { VehicleType } from '../../enums/vehicles/vehicle-type';
import { Filters } from '../utilities/filters';

export interface VehiclesFilters extends Filters {
  type?: VehicleType[] | null;
  loadingPointsStartCountryCode?: string[] | null;
  loadingPointsEndCountryCode?: string[] | null;
  showAvailable: boolean;
  showAvailablePlanned: boolean;
  showInProgress: boolean;
  showInProgressPlanned: boolean;
  showInvalid: boolean;
  showInactive: boolean;
  showTrailer: boolean;
  showLoadingPoints: boolean;
}

export const DEFAULT_VEHICLES_FILTERS: VehiclesFilters = {
  showAvailable: true,
  showAvailablePlanned: true,
  showInProgress: true,
  showInProgressPlanned: true,
  showTrailer: true,
  showInvalid: true,
  showInactive: false,
  showLoadingPoints: true,
};
