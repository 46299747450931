import { Dialog } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  signal,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Order } from 'src/app/core/interfaces/orders/order';
import { OrdersActions } from 'src/app/core/state/orders/orders.actions';

@Component({
  selector: 'app-loading-points-cluster-bubble',
  templateUrl: './loading-points-cluster-bubble.component.html',
  styleUrls: ['./loading-points-cluster-bubble.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingPointsClusterBubbleComponent {
  @Input({ required: true }) orders!: Order[];

  @Output() close = new EventEmitter<void>();

  private initialized = signal(false);
  private order = signal(false);

  constructor(
    private elementRef: ElementRef,
    private store: Store,
    private dialog: Dialog
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => this.initialized.set(true));
  }

  showOrder(order: Order): void {
    this.order.set(true);
    this.store.dispatch(
      OrdersActions.showDetails({
        uuid: order.uuid,
      })
    );

    this.dialog.afterAllClosed.subscribe(() => this.order.set(false));
  }

  @HostListener('document:click', ['$event'])
  checkClickOutside(event: MouseEvent): void {
    if (!this.initialized() || this.order()) return;

    const containerElement = this.elementRef.nativeElement;
    const target = event.target as Node;

    if (target.parentNode && !containerElement.contains(target)) {
      this.close.emit();
    }
  }
}
