<div class="dot" [ngStyle]="{ background: status.color }"></div>

@if (type !== "dot") {
  <p>
    <span>{{ label }}</span>
    <span class="optional">
      <ng-content></ng-content>
    </span>
  </p>
}
