<label [ngClass]="{ disabled }">
  <input
    type="checkbox"
    tabindex="-1"
    [disabled]="disabled"
    [ngModel]="value"
    (ngModelChange)="handleValueChange($event)"
  />

  <div
    class="checkbox"
    [ngClass]="{ invalid, checked: value }"
    [tabindex]="disabled ? -1 : 0"
    (focusout)="onTouched?.()"
  >
    <app-icon
      [hidden]="!value"
      class="checkbox-icon"
      icon="checkbox"
      [width]="10"
    ></app-icon>
  </div>

  @if (label) {
    <p class="label">
      @if (required) {
        <span class="required">*</span>
      }
      {{ label }}
    </p>
  }
</label>

@if (formControl && errors) {
  <app-form-control-error
    [formControls]="[formControl]"
    [paddingLeft]="28"
  ></app-form-control-error>
}
