import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-order-distance',
  templateUrl: './order-distance.component.html',
  styleUrls: ['./order-distance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderDistanceComponent {
  @Input({ required: true }) distance!: number;
  @Input({ required: true }) emptyDistance?: number;

  protected get totalDistance(): number {
    return this.distance + (this.emptyDistance ?? 0);
  }
}
