import { Injectable } from '@angular/core';
import { Observable, map, takeUntil, throttleTime } from 'rxjs';
import { THROTTLE_TIME } from '../../constants/utilities/throttle-time';
import { OrderRejections } from '../../interfaces/orders/order-rejections';
import { DatabaseService } from '../utilities/database.service';
import { FirebaseAuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class RejectionsService {
  constructor(
    private databaseService: DatabaseService,
    private firebaseAuthService: FirebaseAuthService,
  ) {}

  getRejections$(): Observable<OrderRejections[]> {
    return this.databaseService
      .list<string[]>('/rejectedVehiclesForOrder/collection')
      .snapshotChanges()
      .pipe(
        takeUntil(this.firebaseAuthService.logout$),
        throttleTime(THROTTLE_TIME, undefined, { trailing: true }),
        map((actions): OrderRejections[] =>
          actions.map((action) => ({
            orderParentUuid: action.key!,
            vehicleUuids: Object.keys(action.payload.val()!),
          })),
        ),
      );
  }
}
