<ng-content></ng-content>

<div
  class="controls"
  [ngClass]="{ skeleton: !map }"
  [ngStyle]="{ transform: 'translateX(' + controlsTransform + 'px)' }"
>
  <button (click)="switchMapType()">
    <app-icon icon="map-type" [width]="16"></app-icon>
  </button>

  @if (fullscreen) {
    <button (click)="switchFullscreen()">
      <app-icon icon="fullscreen" [size]="14"></app-icon>
    </button>
  }

  @if (zoom) {
    <div class="group">
      <button (click)="zoomIn()">
        <app-icon icon="zoom-in" [width]="10"></app-icon>
      </button>
      <button (click)="zoomOut()">
        <app-icon icon="zoom-out" [width]="10"></app-icon>
      </button>
    </div>
  }
</div>
