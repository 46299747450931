import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Injector,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  forwardRef,
  signal,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { DropdownPositionHelper } from 'src/app/core/helpers/dropdown-position-helper';
import { Address } from 'src/app/core/interfaces/common/address';
import { Coordinates } from 'src/app/core/interfaces/common/coordinates';
import { InputColor } from 'src/app/core/interfaces/utilities/input-color';
import { GoogleMapsService } from 'src/app/core/services/utilities/google-maps.service';
import { BaseFormControlComponent } from '../../forms/base-form-control.component';

const COORDINATES_PATTERN =
  /^\s*(?<lat>-?(?:[0-8]?\d(?:\s*\d{1,2})?(?:[.,]\d+)?|90(?:[.,]0+)?))\s*[;, ]\s*(?<lon>-?(?:1?[0-7]?\d{1,2}(?:\s*\d{1,2})?(?:[.,]\d+)?|180(?:[.,]0+)?))\s*$/;

@Component({
  selector: 'app-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressInputComponent),
      multi: true,
    },
  ],
})
export class AddressInputComponent
  extends BaseFormControlComponent
  implements ControlValueAccessor
{
  @HostBinding('style.width') @Input() width = '100%';

  @ViewChild('inputRef') inputRef!: ElementRef<HTMLInputElement>;
  @ViewChild('dropdownRef') dropdownRef!: ElementRef<HTMLDivElement>;
  @ViewChildren('optionRef') optionRefs!: QueryList<ElementRef<HTMLLIElement>>;

  @Input() color: InputColor = 'white';
  @Input() label?: string;
  @Input() height = 40;
  @Input() placeholder = 'Adres';
  @Input() hint = 'wprowadź adres lub współrzędne';
  @Input() exact = false;
  @Input() disabled = false;
  @Input() errors = true;

  @Input() set value(value: Address | null | undefined) {
    this.value_ = value ?? undefined;
    const label = value ? this.getAddressLabel(value) : '';
    setTimeout(() => (this.inputRef.nativeElement.value = label));
  }
  get value(): Address | null {
    return this.value_ ?? null;
  }

  @Output() valueChange = new EventEmitter<Address | null>();

  query = '';
  dropdown = signal(false);
  options = signal<Address[]>([]);

  private value_?: Address;

  constructor(
    protected override injector: Injector,
    private elementRef: ElementRef<HTMLElement>,
    private googleMapsService: GoogleMapsService,
  ) {
    super(injector);
  }

  onChange?: (value: Address | null) => void;
  onTouched?: () => void;

  registerOnChange(fn: (value: Address | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  writeValue(value: Address | null): void {
    this.value = value;
  }

  @HostListener('document:click', ['$event'])
  checkClickOutside(event: MouseEvent): void {
    if (!this.dropdown()) return;

    const containerElement = this.elementRef.nativeElement;
    const target = event.target as Node;

    if (target.parentNode && !containerElement.contains(target)) {
      this.query = this.value ? this.getAddressLabel(this.value) : '';
      this.dropdown.set(false);
    }
  }

  updateOptions(): void {
    if (this.query.length >= 2) {
      let source: Observable<Address[]>;

      const coordinatesMatch = this.query.match(COORDINATES_PATTERN);
      if (coordinatesMatch) {
        const coordinates: Coordinates = {
          lat: Number(coordinatesMatch.groups!['lat'].replace(',', '.')),
          lon: Number(coordinatesMatch.groups!['lon'].replace(',', '.')),
        };
        source = this.googleMapsService.reverseGeocode(coordinates);
      } else {
        source = this.googleMapsService.geocode(this.query, this.exact);
      }

      source.subscribe((placePredictions) => {
        this.options.set(placePredictions);

        if (!this.dropdown()) {
          this.dropdown.set(true);
          setTimeout(() =>
            DropdownPositionHelper.setPosition(
              this.elementRef,
              this.dropdownRef,
            ),
          );
        }
      });
    } else {
      this.dropdown.set(false);
    }

    if (this.query.length === 0) this.selectOption(null);
  }

  selectOption(option: Address | null): void {
    this.query = option ? this.getAddressLabel(option) : '';
    this.value = option;

    this.onChange?.(option);
    this.valueChange.emit(option);
    setTimeout(() => this.dropdown.set(false));
  }

  handleInputKeyDown(event: KeyboardEvent): void {
    const key = event.key;
    if (key === 'Enter' || key === 'ArrowDown') {
      this.optionRefs.first?.nativeElement?.focus();

      event.preventDefault();
      return;
    }
  }

  handleOptionKeyDown(event: KeyboardEvent): void {
    const [key, optionId] = [
      event.key,
      (event.target as HTMLElement).dataset['id'],
    ];

    if (key === 'ArrowUp' || key === 'ArrowDown') {
      const index = this.options().findIndex(
        (option) => this.getAddressId(option) === optionId,
      );
      const nextIndex = index + (key === 'ArrowUp' ? -1 : 1);

      if (nextIndex >= 0 && nextIndex < this.options.length) {
        const nextOptionId = this.getAddressId(this.options()[nextIndex]);
        this.optionRefs
          .find(
            (optionRef) =>
              optionRef.nativeElement.dataset['id'] === nextOptionId,
          )
          ?.nativeElement.focus();
      } else if (nextIndex === -1) {
        this.inputRef.nativeElement.focus();
      }

      event.preventDefault();
      return;
    }

    if (key === 'Enter') {
      const option =
        this.options().find(
          (option) => this.getAddressId(option) === optionId,
        ) ?? null;
      this.selectOption(option);
      this.inputRef.nativeElement.focus();
      event.preventDefault();
      return;
    }

    if (key === 'Escape') {
      this.inputRef.nativeElement.focus();
      event.preventDefault();
      return;
    }
  }

  getAddressLabel(address: Address): string {
    return [
      address.name,
      address.street,
      `${address.postcode} ${address.city}`,
      address.countryCode,
    ]
      .filter(Boolean)
      .join(', ');
  }

  getAddressId(address: Address): string {
    return `${address.lat};${address.lon}`;
  }
}
