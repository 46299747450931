import { CommonModule } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpBackend,
  HttpClientModule,
} from '@angular/common/http';
import '@angular/common/locales/global/pl';
import {
  LOCALE_ID,
  NgModule,
  Optional,
  SkipSelf,
  isDevMode,
} from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { Settings } from 'luxon';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { environment } from 'src/config/environment';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AppState } from './state/app.state';
import { AssignmentsEffects } from './state/assignments/assignments.effects';
import { assignmentsReducer } from './state/assignments/assignments.reducer';
import { AuthEffects } from './state/auth/auth.effects';
import { authReducer } from './state/auth/auth.reducer';
import { HistoricalCostsEffects } from './state/historical-costs/historical-costs.effects';
import { historicalCostsReducer } from './state/historical-costs/historical-costs.reducer';
import { OrdersEffects } from './state/orders/orders.effects';
import { ordersReducer } from './state/orders/orders.reducers';
import { PlannerEffects } from './state/planner/planner.effects';
import { plannerReducer } from './state/planner/planner.reducer';
import { RejectionsEffects } from './state/rejections/rejections.effects';
import { rejectionsReducer } from './state/rejections/rejections.reducer';
import { SolutionsEffects } from './state/solutions/solutions.effects';
import { solutionsReducer } from './state/solutions/solutions.reducer';
import { UnassignedReasonsEffects } from './state/unassigned-reasons/unassigned-reasons.effects';
import { unassignedReasonsReducer } from './state/unassigned-reasons/unassigned-reasons.reducer';
import { VehiclesAnalyticsEffects } from './state/vehicles-analytics/vehicles-analytics.effects';
import { vehiclesAnalyticsReducer } from './state/vehicles-analytics/vehicles-analytics.reducers';
import { VehiclesEffects } from './state/vehicles/vehicles.effects';
import { vehiclesReducer } from './state/vehicles/vehicles.reducers';

export const httpLoaderFactory = (
  http: HttpBackend,
): MultiTranslateHttpLoader => {
  return new MultiTranslateHttpLoader(http, [
    { prefix: `assets/i18n/onyx-angular-kit/`, suffix: 'pl.json' },
    { prefix: `assets/i18n/`, suffix: 'pl.json' },
    { prefix: `${environment.apiUrl}/dictionaries`, suffix: ' ' },
  ]);
};

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpBackend],
      },
    }),

    StoreModule.forRoot<AppState>({
      auth: authReducer,
      planner: plannerReducer,
      assignments: assignmentsReducer,
      orders: ordersReducer,
      rejections: rejectionsReducer,
      solutions: solutionsReducer,
      unassignedReasons: unassignedReasonsReducer,
      vehicles: vehiclesReducer,
      historicalCosts: historicalCostsReducer,
      vehiclesAnalytics: vehiclesAnalyticsReducer,
    }),
    EffectsModule.forRoot([
      AuthEffects,
      PlannerEffects,
      AssignmentsEffects,
      OrdersEffects,
      RejectionsEffects,
      SolutionsEffects,
      UnassignedReasonsEffects,
      VehiclesEffects,
      HistoricalCostsEffects,
      VehiclesAnalyticsEffects,
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: !isDevMode(),
      autoPause: true,
      connectInZone: true,
    }),

    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireDatabaseModule,

    AngularSvgIconModule.forRoot(),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pl-PL' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() otherInstance: CoreModule) {
    if (otherInstance)
      throw new Error(
        'CoreModule has already been loaded. Import it in only once in the AppModule.',
      );

    Settings.defaultLocale = 'pl-PL';
  }
}
