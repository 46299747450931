import { FormGroup } from '@angular/forms';

export const unloadingDateValidator = (
  pickupDateControlName: string,
  deliveryDateControlName: string
) => {
  return (formGroup: FormGroup): void => {
    const loadingDateControl = formGroup.get(pickupDateControlName)!;
    if (!loadingDateControl.valid) return;

    const loadingDate = new Date(loadingDateControl.value);
    loadingDate.setHours(0, 0, 0, 0);

    const unloadingDateControl = formGroup.get(deliveryDateControlName)!;
    if (
      unloadingDateControl.invalid &&
      !unloadingDateControl.hasError('unloadingDate')
    )
      return;

    const unloadingDate = new Date(unloadingDateControl.value);
    unloadingDate.setHours(0, 0, 0, 0);

    if (unloadingDate < loadingDate) {
      unloadingDateControl.setErrors({ unloadingDate: true });
    } else {
      unloadingDateControl.setErrors(null);
    }
  };
};
