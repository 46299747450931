import { Environment } from '../app/core/interfaces/utilities/environment';

export const environment: Environment = {
  apiUrl: 'https://api.planner.onyxtms.com',
  hereMapsApiKey: 'VJP7YZAaVqlM96fYBKoIiQbbivX6Gorrftvkj2OqqQQ',
  googleMapsApiKey: 'AIzaSyBiICiBHnDJf-vNGxcuZWUJ3qfy0EtUAlE',
  firebase: {
    projectId: 'snarto-planner',
    appId: '1:496941921840:web:f81ee951dfe1e74fbf9ff5',
    databaseURL:
      'https://snarto-planner-default-rtdb.europe-west1.firebasedatabase.app',
    storageBucket: 'snarto-planner.appspot.com',
    apiKey: 'AIzaSyB2kcuyu2dcXlr0DzqFkiZDrBGoFfy5B10',
    authDomain: 'snarto-planner.firebaseapp.com',
    messagingSenderId: '496941921840',
    measurementId: 'G-2PD9F271HE',
  },
} as const;
