<app-dialog type="center" [width]="800" (close)="dialogRef.close()">
  <h3 title>Przypisz pojazd do zlecenia</h3>
  <div class="container">
    <div class="heading">
      <app-search
        [value]="query()"
        (valueChange)="query.set($event)"
        [height]="32"
      ></app-search>

      <app-dropdown
        default="Typ pojazdu"
        [value]="vehicleType()"
        (valueChange)="vehicleType.set($event)"
        [options]="vehicleTypes$ | async"
        [clearable]="true"
        width="250px"
        [height]="32"
      ></app-dropdown>
    </div>

    <cdk-virtual-scroll-viewport [itemSize]="56">
      <app-table
        [columns]="COLUMNS"
        [padding]="true"
        [data]="vehicles()"
        notFoundText="Brak pojazdów"
      >
        <tr
          *cdkVirtualFor="let vehicle of vehicles(); trackBy: vehicleIdentity"
          (click)="
            vehicle.uuid === selectedVehicle()?.uuid
              ? selectedVehicle.set(null)
              : selectedVehicle.set(vehicle)
          "
        >
          <td>
            <div class="checkbox">
              <app-checkbox
                [value]="vehicle.uuid === selectedVehicle()?.uuid"
              ></app-checkbox>
            </div>
          </td>

          <td>
            <div class="vehicle">
              <app-icon icon="vehicle" [width]="20"></app-icon>
              {{ vehicle.id }}
            </div>
          </td>

          <td>
            <app-status-badge
              type="primary"
              [status]="vehicle.status"
              [label]="'vehicleStatus.' + vehicle.status.code | translate"
            ></app-status-badge>
          </td>

          <td>
            {{ VEHICLE_TYPE + "." + vehicle.type | translate }}
          </td>

          <td>{{ vehicle.weightCapacity | number }} kg</td>
        </tr>
      </app-table>
    </cdk-virtual-scroll-viewport>

    <div class="bar">
      <app-button [disabled]="!selectedVehicle()" (click)="submit()">
        Przypisz
      </app-button>
    </div>
  </div>
</app-dialog>
