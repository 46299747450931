import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TransportType } from 'src/app/core/enums/orders/transport-type';
import { DictionaryCode } from 'src/app/core/enums/utilities/dictionary-code';
import { Dimensions } from 'src/app/core/interfaces/common/dimensions';

@Component({
  selector: 'app-order-cargo',
  templateUrl: './order-cargo.component.html',
  styleUrls: ['./order-cargo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderCargoComponent {
  readonly TRANSPORT_TYPE = DictionaryCode.TRANSPORT_TYPE;

  @Input({ required: true }) transportType!: TransportType;
  @Input({ required: true }) weight!: number;
  @Input({ required: true }) quantity!: number;
  @Input() vehicleWeightCapacity?: number;
  @Input() size?: Dimensions;
}
