import { DateTime } from 'luxon';
import { z } from 'zod';
import { zodErrorMessage } from '../../helpers/zod-error-message';
import { getCoordinatesSchema } from '../common/coordinates';
import { getDimensionsSchema } from '../common/dimensions';
import { getTimeWindowSchema } from '../common/time-window';
import { AllowedCountriesSchema } from './allowed-countries';
import { getCurrentLocationSchema } from './current-location';
import { DriverTimeSchema } from './driver-time';

export const FirebaseVehicleSchema = z.object({
  uuid: z.string(zodErrorMessage('uuid.required')).uuid('uuid.invalid'),
  companyId: z.string(zodErrorMessage('companyId.required')),
  weightCapacity: z
    .number(zodErrorMessage('weightCapacity.required'))
    .int('weightCapacity.invalid')
    .positive('weightCapacity.invalid'),
  cargoSpaceCapacity: getDimensionsSchema(
    'cargoSpaceCapacity.required',
    'cargoSpaceCapacity.invalid',
  ),
  currentLocation: getCurrentLocationSchema(
    'currentLocation.required',
    'currentLocation.invalid',
  ),
  driverTime: DriverTimeSchema,
  dataSource: z.string(zodErrorMessage('dataSource.required')).optional(),
  minimalIncomePerKm: z
    .number(zodErrorMessage('minimalIncomePerKm.invalid'))
    .int('minimalIncomePerKm.invalid')
    .min(0, 'minimalIncomePerKm.invalid')
    .default(0),
  type: z.string(zodErrorMessage('type.required')).min(1, 'type.required'),
  id: z.string(zodErrorMessage('id.required')).min(1, 'id.required'),
  workingIntervals: z
    .array(
      getTimeWindowSchema(
        'workingIntervals.required',
        'workingIntervals.invalid',
      ),
      zodErrorMessage('workingIntervals.required'),
    )
    .min(1, 'workingIntervals.required')
    .refine(
      (workingIntervals) =>
        workingIntervals.every((data) => {
          const endDate = DateTime.fromJSDate(new Date(data.end));
          return endDate.diffNow().toMillis() >= 0;
        }),
      { message: 'workingIntervals.inactive' },
    ),
  baseCoordinates: getCoordinatesSchema(
    'baseCoordinates.required',
    'baseCoordinates.invalid',
  ),
  isActive: z.boolean(zodErrorMessage('isActive.invalid')).default(true),
  isTrailer: z.boolean(zodErrorMessage('isTrailer.invalid')).default(false),
  isExpress: z.boolean(zodErrorMessage('isExpress.invalid')).default(false),
  emptyDistanceThreshold: z
    .number(zodErrorMessage('emptyDistanceThreshold.invalid'))
    .int('emptyDistanceThreshold.invalid')
    .min(0, 'emptyDistanceThreshold.invalid')
    .optional(),
  emptyPercentageThreshold: z
    .number(zodErrorMessage('emptyPercentageThreshold.invalid'))
    .int('emptyPercentageThreshold.invalid')
    .min(0, 'emptyPercentageThreshold.invalid')
    .max(100, 'emptyPercentageThreshold.invalid')
    .optional(),
  maxWaitTime: z
    .number(zodErrorMessage('maxWaitTime.invalid'))
    .int('maxWaitTime.invalid')
    .min(0, 'maxWaitTime.invalid')
    .optional(),
  allowedCountries: AllowedCountriesSchema.optional(),
  asimuth: z
    .number(zodErrorMessage('asimuth.invalid'))
    .int('asimuth.invalid')
    .min(0, 'asimuth.invalid')
    .max(360, 'asimuth.invalid')
    .optional(),
  dispatcherUid: z.string(zodErrorMessage('dispatcherUid.invalid')).optional(),
  fuelLevel: z.number(zodErrorMessage('fuelLevel.invalid')).optional(),
  fuelLevelPercentage: z
    .number(zodErrorMessage('fuelLevelPercentage.invalid'))
    .min(0, 'fuelLevelPercentage.invalid')
    .max(100, 'fuelLevelPercentage.invalid')
    .optional(),
  velocity: z
    .number(zodErrorMessage('velocity.invalid'))
    .min(0, 'velocity.invalid')
    .optional(),
  analytics: z.any().optional(),
});

export type FirebaseVehicle = z.infer<typeof FirebaseVehicleSchema>;
