import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Duration } from 'luxon';
import { Toast } from 'src/app/core/interfaces/utilities/toast';

const DELAY_TIME = Duration.fromObject({ seconds: 5 }).toMillis();

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent {
  @Input({ required: true }) toast!: Toast;

  @Output() dispose = new EventEmitter<void>();

  get icon(): string {
    if (this.toast.icon) return this.toast.icon;

    switch (this.toast.type) {
      case 'error':
        return 'error';
      default:
        return 'check';
    }
  }

  ngOnInit(): void {
    setTimeout(() => this.hide(), DELAY_TIME);
  }

  hide(): void {
    this.dispose.emit();
  }
}
