import { z } from 'zod';
import { zodErrorMessage } from '../../helpers/zod-error-message';
import { getCoordinatesSchema } from './coordinates';

export const getAddressSchema = (
  requiredMessage: string,
  invalidMessage: string,
) =>
  getCoordinatesSchema(requiredMessage, invalidMessage).extend({
    street: z.string(zodErrorMessage(invalidMessage)).nullable().optional(),
    postcode: z.string(zodErrorMessage(invalidMessage)).nullable().optional(),
    city: z.string(zodErrorMessage(invalidMessage)).nullable().optional(),
    countryCode: z
      .string(zodErrorMessage(invalidMessage))
      .length(2, invalidMessage),
    name: z.string(zodErrorMessage(invalidMessage)).nullable().optional(),
  });

export type Address = z.infer<ReturnType<typeof getAddressSchema>>;

export type FireTmsAddress = {
  clientName: string;
  street: string;
  city: string;
  postcode: string;
  countryCode: string;
};
