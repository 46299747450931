import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

export class FormHelper {
  static showAllErrors(form: FormGroup | FormArray): void {
    form.markAllAsTouched();
    this.markAllAsDirty(form);
  }

  private static markAllAsDirty(form: FormGroup | FormArray): void {
    const controls: AbstractControl[] = Object.values(form.controls);
    for (const control of controls) {
      control.markAsDirty({ onlySelf: true });

      if (control instanceof FormGroup || control instanceof FormArray) {
        this.markAllAsDirty(control);
      }
    }
  }
}
