import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OrderStatus } from 'src/app/core/enums/orders/order-status';
import { DictionaryCode } from 'src/app/core/enums/utilities/dictionary-code';
import { Order } from 'src/app/core/interfaces/orders/order';
import { ToastService } from 'src/app/core/services/utilities/toast.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderDetailsComponent {
  readonly VEHICLE_TYPE = DictionaryCode.VEHICLE_TYPE;
  readonly OrderStatus = OrderStatus;

  @Input({ required: true }) order!: Order;
  @Input() heading = 'Szczegóły';

  constructor(
    private clipboard: Clipboard,
    private toastService: ToastService
  ) {}

  copy(data: string): void {
    this.clipboard.copy(data);
    this.toastService.show({
      type: 'message',
      icon: 'copy',
      iconWidth: 14,
      title: 'Skopiowano do schowka',
    });
  }
}
