import { Dialog } from '@angular/cdk/dialog';
import { Injectable } from '@angular/core';
import { uuidv4 } from '@firebase/util';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { DateTime } from 'luxon';
import { filter, first, map, of, switchMap, tap, zip } from 'rxjs';
import { OrderDetailsDialogComponent } from 'src/app/planner/common/components/order-details-dialog/order-details-dialog.component';
import { OrderAssignmentRejectionDialogComponent } from 'src/app/planner/orders-view/order-assignment-rejection-dialog/order-assignment-rejection-dialog.component';
import { OrderFormDialogComponent } from 'src/app/planner/orders-view/order-form-dialog/order-form-dialog.component';
import { OrderManualAssignmentDialogComponent } from 'src/app/planner/orders-view/order-manual-assignment-dialog/order-manual-assignment-dialog.component';
import { EUROPALLET_SIZE } from '../../constants/orders/europallet-size';
import { OrderStatusChange } from '../../enums/orders/order-status.change';
import { FirebaseOrder } from '../../interfaces/orders/firebase-order';
import { Order } from '../../interfaces/orders/order';
import { SingleOrderForm } from '../../interfaces/orders/single-order-form';
import { PartialNull } from '../../interfaces/utilities/partial-null';
import { FirebaseOrdersService } from '../../services/firebase/firebase-orders.service';
import { ToastService } from '../../services/utilities/toast.service';
import { OrdersActions } from './orders.actions';
import { selectOrder, selectOrderChildren } from './orders.selectors';

@Injectable()
export class OrdersEffects {
  constructor(
    private actions$: Actions,
    private firebaseOrdersService: FirebaseOrdersService,
    private store: Store,
    private dialog: Dialog,
    private toastService: ToastService,
  ) {}

  load$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrdersActions.load),
        switchMap(() =>
          this.firebaseOrdersService
            .getOrders$()
            .pipe(
              tap((orders) =>
                this.store.dispatch(OrdersActions.update({ orders })),
              ),
            ),
        ),
      ),
    { dispatch: false },
  );
  loadSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.load),
      switchMap(() =>
        this.actions$.pipe(
          ofType(OrdersActions.update),
          first(),
          map(OrdersActions.loadSuccess),
        ),
      ),
    ),
  );

  addOrder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrdersActions.addOrder),
        switchMap((order) => this.firebaseOrdersService.addOrder(order)),
      ),
    { dispatch: false },
  );
  editOrder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrdersActions.editOrder),
        switchMap(({ uuid, value }) =>
          this.firebaseOrdersService.editOrder(uuid, value),
        ),
      ),
    { dispatch: false },
  );
  deleteOrder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrdersActions.deleteOrder),
        switchMap(({ uuid }) => this.firebaseOrdersService.deleteOrder(uuid)),
      ),
    { dispatch: false },
  );
  changeStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.changeStatus),
      map(({ uuid, statusChange }) => {
        let value: PartialNull<FirebaseOrder>;
        switch (statusChange) {
          case OrderStatusChange.NEW:
            value = {
              assigned: false,
              loadingStarted: false,
              loadingStart: null,
              wasLoaded: false,
              unloadingStarted: false,
              unloadingStart: null,
              wasUnloaded: false,
              wasSold: false,
              assignedVehicleUuid: null,
              assignedVehicleId: null,
              assignedByOnyx: null,
            };
            break;
          case OrderStatusChange.ASSIGNED:
            value = {
              assigned: true,
              loadingStarted: false,
              loadingStart: null,
              wasLoaded: false,
              unloadingStarted: false,
              unloadingStart: null,
              wasUnloaded: false,
            };
            break;
          case OrderStatusChange.LOADING:
            value = {
              assigned: true,
              loadingStarted: true,
              loadingStart: DateTime.utc().toISO(),
              wasLoaded: false,
              unloadingStarted: false,
              unloadingStart: null,
              wasUnloaded: false,
            };
            break;
          case OrderStatusChange.LOADED:
            value = {
              assigned: true,
              loadingStarted: true,
              wasLoaded: true,
              unloadingStarted: false,
              unloadingStart: null,
              wasUnloaded: false,
            };
            break;
          case OrderStatusChange.UNLOADING:
            value = {
              assigned: true,
              loadingStarted: true,
              wasLoaded: true,
              unloadingStarted: true,
              unloadingStart: DateTime.utc().toISO(),
              wasUnloaded: false,
            };
            break;
          case OrderStatusChange.FINISHED:
            value = {
              assigned: true,
              loadingStarted: true,
              wasLoaded: true,
              unloadingStarted: true,
              wasUnloaded: true,
            };
            break;
          case OrderStatusChange.SOLD:
            value = {
              assigned: false,
              wasUnloaded: false,
              wasSold: true,
              assignedVehicleUuid: false as any, // workaround for engine to ignore sold order
              assignedVehicleId: null,
              assignedByOnyx: null,
            };
            break;
        }

        return OrdersActions.editOrder({ uuid, value });
      }),
    ),
  );

  showRejectAssignment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrdersActions.showRejectAssignment),
        tap((assignment) =>
          this.dialog.open(OrderAssignmentRejectionDialogComponent, {
            data: { assignment },
          }),
        ),
      ),
    { dispatch: false },
  );
  rejectAssignment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrdersActions.rejectAssignment),
        switchMap(({ assignment, reasons }) =>
          this.firebaseOrdersService.rejectAssignment(assignment, reasons),
        ),
        tap(() =>
          this.toastService.showSuccess(
            'Zlecenie odrzucone',
            'Akcja zakończona powodzeniem',
          ),
        ),
      ),
    { dispatch: false },
  );
  acceptAssignment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrdersActions.acceptAssignment),
        switchMap(({ orderParentUuid, vehicleUuid }) =>
          zip(
            this.store.pipe(select(selectOrderChildren(orderParentUuid))),
            of({ orderParentUuid, vehicleUuid }),
          ),
        ),
        switchMap(([orders, { orderParentUuid, vehicleUuid }]) =>
          this.firebaseOrdersService.assignVehicle(
            orderParentUuid,
            orders ?? [],
            { uuid: vehicleUuid },
            true,
          ),
        ),
        tap(() =>
          this.toastService.showSuccess(
            'Zlecenie zaakceptowane',
            'Akcja zakończona powodzeniem',
          ),
        ),
      ),
    { dispatch: false },
  );
  clearRejections$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrdersActions.clearRejections),
        switchMap(({ orderParentUuid }) =>
          this.firebaseOrdersService.clearRejections(orderParentUuid),
        ),
        tap(() =>
          this.toastService.showSuccess(
            'Odrzucenia pojazdu usunięte',
            'Akcja zakończona powodzeniem',
          ),
        ),
      ),
    { dispatch: false },
  );

  showAssignVehicle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrdersActions.showAssignVehicle),
        tap(({ orderParentUuid }) =>
          this.dialog.open(OrderManualAssignmentDialogComponent, {
            data: { orderParentUuid },
          }),
        ),
      ),
    { dispatch: false },
  );
  assignVehicle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrdersActions.assignVehicle),
        switchMap(({ orderParentUuid, vehicleUuid, vehicleId }) =>
          zip(
            this.store.pipe(select(selectOrderChildren(orderParentUuid))),
            of({ orderParentUuid, vehicleUuid, vehicleId }),
          ),
        ),
        switchMap(([orders, { orderParentUuid, vehicleUuid, vehicleId }]) =>
          this.firebaseOrdersService.assignVehicle(
            orderParentUuid,
            orders ?? [],
            { uuid: vehicleUuid, id: vehicleId },
            false,
          ),
        ),
      ),
    { dispatch: false },
  );

  showDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrdersActions.showDetails),
        tap(({ uuid }) =>
          this.dialog.open(OrderDetailsDialogComponent, {
            data: { uuid },
          }),
        ),
      ),
    { dispatch: false },
  );

  showAddOrderForm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrdersActions.showAddOrderForm),
        tap(() =>
          this.dialog.open(OrderFormDialogComponent, {
            data: {
              mode: 'add',
            },
          }),
        ),
      ),
    { dispatch: false },
  );
  submitAddOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.submitAddOrder),
      switchMap((form) =>
        zip(of(form), this.firebaseOrdersService.calculateDistance(form)),
      ),
      map(([form, distance]) =>
        OrdersActions.addOrder(this.transformOrderForm(form, distance)),
      ),
    ),
  );

  showEditOrderForm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrdersActions.showEditOrderForm),
        switchMap(({ uuid }) =>
          this.store.select(selectOrder(uuid)).pipe(
            first(),
            filter((order): order is Order => !!order),
          ),
        ),
        tap((order) =>
          this.dialog.open(OrderFormDialogComponent, {
            data: {
              mode: 'edit',
              data: order,
            },
          }),
        ),
      ),
    { dispatch: false },
  );
  submitEditOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersActions.submitEditOrder),
      switchMap(({ form, order }) =>
        zip(
          of(form),
          of(order),
          this.firebaseOrdersService.calculateDistance(form),
        ),
      ),
      map(([form, order, distance]) =>
        OrdersActions.editOrder({
          uuid: order.uuid,
          value: this.transformOrderForm(form, distance, order),
        }),
      ),
    ),
  );

  transformOrderForm(
    form: SingleOrderForm,
    distance: number,
    order?: Order,
  ): FirebaseOrder {
    const pickupStartTime = DateTime.fromFormat(
      form.pickupTimeStart.slice(0, 5),
      'HH:mm',
    );
    const pickupEndTime = DateTime.fromFormat(
      form.pickupTimeEnd.slice(0, 5),
      'HH:mm',
    );
    const pickupStart = DateTime.fromJSDate(form.pickupDateStart)
      .set({
        hour: pickupStartTime.hour,
        minute: pickupStartTime.minute,
      })
      .toISO()!;
    const pickupEnd = DateTime.fromJSDate(form.pickupDateEnd)
      .set({
        hour: pickupEndTime.hour,
        minute: pickupEndTime.minute,
      })
      .toISO()!;

    const deliveryStartTime = DateTime.fromFormat(
      form.deliveryTimeStart.slice(0, 5),
      'HH:mm',
    );
    const deliveryEndTime = DateTime.fromFormat(
      form.deliveryTimeEnd.slice(0, 5),
      'HH:mm',
    );
    const deliveryStart = DateTime.fromJSDate(form.deliveryDateStart)
      .set({
        hour: deliveryStartTime.hour,
        minute: deliveryStartTime.minute,
      })
      .toISO()!;
    const deliveryEnd = DateTime.fromJSDate(form.deliveryDateEnd)
      .set({
        hour: deliveryEndTime.hour,
        minute: deliveryEndTime.minute,
      })
      .toISO()!;

    return {
      uuid: order?.uuid ?? uuidv4(),
      parentUuid: order?.parentUuid ?? uuidv4(),
      companyId: order?.companyId ?? '',
      weight: form.weight,
      quantity: form.quantity,
      size: order?.size ?? EUROPALLET_SIZE,
      from: form.pickupAddress,
      to: form.deliveryAddress,
      pickupTimeWindow: {
        start: pickupStart,
        end: pickupEnd,
      },
      deliveryTimeWindow: {
        start: deliveryStart,
        end: deliveryEnd,
      },
      vehicleType: form.vehicleType,
      distance,
      id: form.id,
      transportType: form.transportType,
      loadingTime: order?.loadingTime ?? 0,
      unloadingTime: order?.unloadingTime ?? 0,
      rotatable: true,
      assigned: order?.assigned ?? false,
      loadingStarted: order?.loadingStarted ?? false,
      ...(order?.loadingStart && {
        loadingStart: order.loadingStart,
      }),
      wasLoaded: order?.wasLoaded ?? false,
      unloadingStarted: order?.unloadingStarted ?? false,
      ...(order?.unloadingStart && {
        unloadingStart: order.unloadingStart,
      }),
      wasUnloaded: order?.wasUnloaded ?? false,
      wasSold: order?.wasSold ?? false,
      ...(order?.assignedVehicleUuid && {
        assignedVehicleUuid: order.assignedVehicleUuid,
      }),
      ...(order?.assignedVehicleId && {
        assignedVehicleId: order.assignedVehicleId,
      }),
      assignedByOnyx: order?.assignedByOnyx ?? false,
      price: form.price,
      currency: form.currency,
      client: form.client,
      additionalInformation: order?.additionalInformation ?? '',
    };
  }
}
