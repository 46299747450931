import { Injectable } from '@angular/core';
import { chain } from 'lodash';
import { Observable, map, takeUntil, throttleTime } from 'rxjs';
import { THROTTLE_TIME } from '../../constants/utilities/throttle-time';
import { HistoricalCost } from '../../interfaces/planner/historical-cost';
import { DatabaseService } from '../utilities/database.service';
import { FirebaseAuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class HistoricalCostsService {
  constructor(
    private databaseService: DatabaseService,
    private firebaseAuthService: FirebaseAuthService,
  ) {}

  getHistoricalCosts$(): Observable<HistoricalCost[]> {
    return this.databaseService
      .list<HistoricalCost>('/historicalCosts')
      .valueChanges()
      .pipe(
        takeUntil(this.firebaseAuthService.logout$),
        throttleTime(THROTTLE_TIME, undefined, { trailing: true }),
        map((historicalCosts) =>
          chain(historicalCosts)
            .filter((historicalCost) => !!historicalCost.timestamp)
            .orderBy('timestamp', 'desc')
            .value(),
        ),
      );
  }
}
