import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  HostBinding,
  Injector,
  Input,
  signal,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable, Subscriber, debounceTime, skip } from 'rxjs';
import { ALPHABET } from 'src/app/core/constants/utilities/alphabet';
import { MapType } from 'src/app/core/enums/utilities/map-type';
import { Order } from 'src/app/core/interfaces/orders/order';
import { MapCoordinates } from 'src/app/core/interfaces/utilities/here-maps/map-coordinates';
import { MapRoute } from 'src/app/core/interfaces/utilities/here-maps/map-route';
import { MapRouteSection } from 'src/app/core/interfaces/utilities/here-maps/map-route-section';
import { MapWaypoint } from 'src/app/core/interfaces/utilities/here-maps/map-waypoint';
import { Vehicle } from 'src/app/core/interfaces/vehicles/vehicle';
import { HereMapsService } from 'src/app/core/services/utilities/here-maps.service';
import { OrdersActions } from 'src/app/core/state/orders/orders.actions';
import { PlannerActions } from 'src/app/core/state/planner/planner.actions';
import { LoadingPointsClusterBubbleComponent } from './loading-points-cluster-bubble/loading-points-cluster-bubble.component';

enum MarkerType {
  LOADING_POINT,
  WAYPOINT,
  VEHICLE,
  SELECTED_VEHICLE,
}

const CENTER_POINT = new H.geo.Point(47.45402532725695, 9.131194340990346);
const INITIAL_ZOOM = 5;
const ZOOM_STEP = 0.5;
const MIN_CONTENT_PADDING = 0.1;

interface VehicleMarkerData {
  type: MarkerType.VEHICLE | MarkerType.SELECTED_VEHICLE;
  uuid: string;
  status: Vehicle['status'];
  asimuth?: number;
}

const BEHAVIORS = [
  H.mapevents.Behavior.DBLTAPZOOM,
  H.mapevents.Behavior.DRAGGING,
  H.mapevents.Behavior.WHEELZOOM,
];

interface LoadingPointMarkerData {
  type: MarkerType.LOADING_POINT;
  order: Order;
}

interface LoadingPointClusterData {
  opened: boolean;
  bubble?: H.ui.InfoBubble;
}

@UntilDestroy()
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent implements AfterViewInit {
  @HostBinding('class.fullscreen') private fullscreen_ = false;

  @Input() disabled = false;
  @Input() fullscreen = false;
  @Input() zoom = false;
  @Input() controlsTransform = 0;

  protected map?: H.Map;

  private mapType = signal(MapType.MAP);
  private behaviors?: H.mapevents.Behavior;
  private ui?: H.ui.UI;

  private objects = new H.map.Group();
  private routeObjects = new H.map.Group();

  private vehicles = new Map<string, H.map.DomMarker>();
  private selectedVehicle?: H.map.DomMarker;

  private loadingPoints = new Array<H.clustering.DataPoint>();
  private loadingPointsCluster?: H.clustering.Provider;

  constructor(
    private elementRef: ElementRef,
    private hereMapsService: HereMapsService,
    private store: Store,
    private resolver: ComponentFactoryResolver,
    private injector: Injector,
    private applicationRef: ApplicationRef,
  ) {}

  ngAfterViewInit(): void {
    this.initializeMap();
  }

  addOrUpdateVehicle(vehicle: Vehicle): void {
    if (
      vehicle.currentLocation?.lat == null ||
      vehicle.currentLocation?.lon == null ||
      vehicle.uuid == null ||
      vehicle.status == null
    )
      return;

    const coordinates: H.geo.IPoint = {
      lat: vehicle.currentLocation.lat,
      lng: vehicle.currentLocation.lon,
    };

    if (!this.vehicles.has(vehicle.uuid)) {
      const data: VehicleMarkerData = {
        type: MarkerType.VEHICLE,
        uuid: vehicle.uuid,
        status: vehicle.status,
        asimuth: vehicle.asimuth,
      };

      const marker = new H.map.DomMarker(coordinates, {
        icon: this.getVehicleIcon(vehicle.status, vehicle.asimuth),
        zIndex: MarkerType.VEHICLE,
        data,
      });

      marker.addEventListener('tap', () => {
        this.store.dispatch(
          PlannerActions.selectVehicle({
            vehicleUuid: data.uuid,
          }),
        );
      });

      this.vehicles.set(vehicle.uuid, marker);
      this.objects.addObject(marker);
      return;
    }

    const marker = this.vehicles.get(vehicle.uuid)!;
    const data = marker.getData() as VehicleMarkerData;

    if (!marker.getGeometry().equals(coordinates)) {
      marker.setGeometry(coordinates);
    }
    if (
      data.status.code !== vehicle.status.code ||
      data.asimuth !== vehicle.asimuth
    ) {
      marker.setIcon(this.getVehicleIcon(vehicle.status, vehicle.asimuth));
      data.status = vehicle.status;
      data.asimuth = vehicle.asimuth;
    }
    if (marker.getVisibility() === false) {
      marker.setVisibility(true);
    }
  }

  hideVehicle(uuid: string): void {
    const marker = this.vehicles.get(uuid);
    marker?.setVisibility(false);
  }

  updateSelectedVehicle(vehicle: Vehicle | null): void {
    if (vehicle == null) {
      this.selectedVehicle?.setVisibility(false);
      // this.centerContent();
      // this.map?.setZoom(INITIAL_ZOOM);
      return;
    }

    if (
      vehicle.currentLocation?.lat == null ||
      vehicle.currentLocation?.lon == null
    )
      return;

    const coordinates: H.geo.IPoint = {
      lat: vehicle.currentLocation.lat,
      lng: vehicle.currentLocation.lon,
    };

    if (!this.selectedVehicle) {
      const data: VehicleMarkerData = {
        type: MarkerType.SELECTED_VEHICLE,
        uuid: vehicle.uuid,
        status: vehicle.status,
        asimuth: vehicle.asimuth,
      };

      const marker = new H.map.DomMarker(coordinates, {
        icon: this.getSelectedVehicleIcon(vehicle.asimuth),
        zIndex: MarkerType.SELECTED_VEHICLE,
        data,
      });

      this.selectedVehicle = marker;
      this.objects.addObject(marker);
      return;
    }

    const marker = this.selectedVehicle!;
    const data = marker.getData() as VehicleMarkerData;

    if (!marker.getGeometry().equals(coordinates)) {
      marker.setGeometry(coordinates);
    }
    if (data.asimuth !== vehicle.asimuth) {
      marker.setIcon(this.getSelectedVehicleIcon(vehicle.asimuth));
      data.asimuth = vehicle.asimuth;
    }
    if (marker.getVisibility() === false) {
      marker.setVisibility(true);
    }
  }

  drawRoute(route: MapRoute, options?: { drawFirst: boolean }): void {
    const waypoints = route.waypoints;
    if (!options?.drawFirst) waypoints.shift();

    for (const [index, waypoint] of route.waypoints.entries()) {
      this.drawWaypoint(index, waypoint);
    }

    for (const section of route.sections) {
      this.drawSection(section);

      if (section.charging) {
        const coordinates = section.arrival.location;
        this.drawChargingStation(coordinates);
      }
    }
  }

  clearRoutes(): void {
    this.routeObjects.removeAll();
  }

  updateLoadingOrders(orders: Order[]): void {
    const dataPoints = orders.map((order) => {
      const data: LoadingPointMarkerData = {
        type: MarkerType.LOADING_POINT,
        order,
      };

      return new H.clustering.DataPoint(
        order.from.lat,
        order.from.lon,
        undefined,
        data,
      );
    });

    this.loadingPoints = dataPoints;
    this.loadingPointsCluster?.setDataPoints(dataPoints);
  }

  centerContent(): void {
    const vehicles = this.objects.getBoundingBox();
    const route = this.routeObjects.getBoundingBox();

    let content = vehicles ?? route;
    if (!content) return;

    if (vehicles && route) content = content.mergeRect(route);

    this.centerAt(content);
  }

  centerSelectedVehicle(): void {
    if (!this.selectedVehicle) return;

    let content = this.selectedVehicle.getGeometry().getBoundingBox();

    const route = this.routeObjects.getBoundingBox();
    if (route) content = content.mergeRect(route);

    this.centerAt(content);
  }

  protected switchMapType(): void {
    if (!this.map) return;

    const platform = this.hereMapsService.getPlatform();
    const defaultLayers = platform.createDefaultLayers({ lg: 'pl' });

    if (this.mapType() === MapType.MAP) {
      this.mapType.set(MapType.SATELLITE);
      this.map.setBaseLayer(defaultLayers.raster.satellite.map);
    } else {
      this.mapType.set(MapType.MAP);
      this.map.setBaseLayer(defaultLayers.vector.normal.map);
    }
  }

  protected switchFullscreen(): void {
    this.fullscreen_ = !this.fullscreen_;
    this.enableMapBehaviors(this.fullscreen_);
    setTimeout(() => this.resizeMap());
  }

  protected zoomIn(): void {
    if (!this.map) return;
    this.map.setZoom(this.map.getZoom() + ZOOM_STEP);
  }

  protected zoomOut(): void {
    if (!this.map) return;
    this.map.setZoom(this.map.getZoom() - ZOOM_STEP);
  }

  private getVehicleIcon(
    status: Vehicle['status'],
    asimuth?: number,
  ): H.map.DomIcon {
    const element = document.createElement('div');
    element.className = `vehicle-marker`;
    element.style.backgroundColor = status.color;

    if (asimuth) {
      const arrow = document.createElement('div');
      arrow.className = 'arrow';
      arrow.style.transform = `rotate(${asimuth}deg)`;
      element.appendChild(arrow);
    }

    return new H.map.DomIcon(element);
  }

  private getSelectedVehicleIcon(asimuth = 0): H.map.DomIcon {
    const element = document.createElement('div');
    element.className = 'selected-vehicle-marker';

    const truck = document.createElement('div');
    truck.className = 'truck';
    truck.style.transform = `rotate(${asimuth}deg)`;
    element.appendChild(truck);

    return new H.map.DomIcon(element);
  }

  private drawWaypoint(index: number, waypoint: MapWaypoint): void {
    const element = document.createElement('div');
    element.className = 'waypoint-marker';
    element.classList.toggle('completed', waypoint.completed);

    const label = document.createElement('span');
    label.className = 'f-medium-1';
    label.textContent = ALPHABET[index];
    element.appendChild(label);

    const icon = new H.map.DomIcon(element);
    const marker = new H.map.DomMarker(
      {
        lat: waypoint.address.lat,
        lng: waypoint.address.lon,
      },
      {
        icon,
        data: { type: MarkerType.WAYPOINT },
        zIndex: MarkerType.WAYPOINT,
      },
    );

    this.routeObjects.addObject(marker);
  }

  private drawChargingStation(coordinates: MapCoordinates): void {
    const marker = new H.map.Marker(coordinates, {
      icon: new H.map.Icon('/assets/img/map/charging-station.svg'),
      zIndex: MarkerType.WAYPOINT,
    });

    this.routeObjects.addObject(marker);
  }

  private drawSection(section: MapRouteSection): void {
    const geometry = H.geo.LineString.fromFlexiblePolyline(section.polyline!);
    const polyline = new H.map.Polyline(geometry, {
      style: {
        lineWidth: 4,
        strokeColor: section.completed ? '#16a34a' : '#0078ff',
      },
    });

    this.routeObjects.addObject(polyline);
  }

  private initializeMap(): void {
    const platform = this.hereMapsService.getPlatform();
    const defaultLayers = platform.createDefaultLayers({ lg: 'pl' });

    this.map = new H.Map(
      this.elementRef.nativeElement,
      defaultLayers.vector.normal.map,
      {
        pixelRatio: window.devicePixelRatio || 1,
        center: CENTER_POINT,
        zoom: INITIAL_ZOOM,
      },
    );

    this.behaviors = new H.mapevents.Behavior(
      new H.mapevents.MapEvents(this.map),
    );
    this.enableMapBehaviors(!this.fullscreen && !this.disabled);

    this.ui = H.ui.UI.createDefault(this.map, defaultLayers);
    this.ui.removeControl('mapsettings');
    this.ui.removeControl('zoom');
    this.ui.removeControl('scalebar');

    this.map.addObject(this.objects);
    this.map.addObject(this.routeObjects);
    this.addLoadingPointsCluster();

    this.map.addEventListener('pointermove', (event) => {
      const element = this.map!.getViewPort().element as HTMLElement;
      if (event.target instanceof H.map.AbstractMarker) {
        element.style.cursor = 'pointer';
      } else {
        element.style.cursor = 'auto';
      }
    });

    this.map.addEventListener('tap', (event) => {
      if (!this.selectedVehicle?.getVisibility()) return;

      const isMarker = event.target instanceof H.map.AbstractMarker;
      if (!isMarker) {
        this.store.dispatch(
          PlannerActions.selectVehicle({ vehicleUuid: undefined }),
        );
      }
    });

    new Observable((subscriber: Subscriber<void>) => {
      const resizeObserver = new ResizeObserver(() => subscriber.next());
      resizeObserver.observe(this.elementRef.nativeElement);
      return () => resizeObserver.disconnect();
    })
      .pipe(untilDestroyed(this), skip(1), debounceTime(100))
      .subscribe(() => this.resizeMap());
  }

  private enableMapBehaviors(enable = true) {
    for (const behavior of BEHAVIORS) {
      enable
        ? this.behaviors?.enable(behavior)
        : this.behaviors?.disable(behavior);
    }

    const element = this.map?.getViewPort().element as HTMLElement;
    element.style.pointerEvents = enable ? 'all' : 'none';
  }

  private addLoadingPointsCluster(): void {
    const clusteredLoadingPointsProvider = new H.clustering.Provider(
      this.loadingPoints,
      {
        theme: {
          getClusterPresentation: (cluster) => {
            const clusterElement = document.createElement('div');
            clusterElement.classList.add('loading-points-cluster');
            clusterElement.innerText = cluster.getWeight().toString();

            const marker = new H.map.DomMarker(cluster.getPosition(), {
              icon: new H.map.DomIcon(clusterElement),
              zIndex: MarkerType.LOADING_POINT,
              min: cluster.getMinZoom(),
              max: cluster.getMaxZoom(),
            });

            marker.addEventListener('tap', () => {
              let data = marker.getData() as LoadingPointClusterData;
              if (data?.opened) {
                setTimeout(() => {
                  this.ui?.removeBubble(data.bubble!);
                  data = { opened: false };
                  marker.setData(data);
                });
                return;
              }

              const orders: Order[] = [];
              cluster.forEachDataPoint((dp) => {
                const data = dp.getData() as LoadingPointMarkerData;
                orders.push(data.order);
              });

              const bubble = new H.ui.InfoBubble(
                marker.getGeometry() as H.geo.Point,
              );
              bubble.addClass('loading-points-cluster-bubble');
              bubble.setContent(
                this.getLoadingPointsClusterBubble(marker, bubble, orders),
              );

              this.ui?.addBubble(bubble);

              data = { opened: true, bubble };
              marker.setData(data);
            });

            return marker;
          },
          getNoisePresentation: (noisePoint) => {
            const data = noisePoint.getData() as LoadingPointMarkerData;
            const marker = new H.map.Marker(noisePoint.getPosition(), {
              icon: new H.map.Icon('/assets/img/map/loading-point.svg'),
              zIndex: MarkerType.LOADING_POINT,
              min: noisePoint.getMinZoom(),
              data,
            });

            marker.addEventListener('tap', () => {
              this.store.dispatch(
                OrdersActions.showDetails({ uuid: data.order.uuid }),
              );
            });

            return marker;
          },
        },
      },
    );
    this.loadingPointsCluster = clusteredLoadingPointsProvider;

    const clusteringLayer = new H.map.layer.ObjectLayer(
      clusteredLoadingPointsProvider,
    );
    this.map?.addLayer(clusteringLayer);
  }

  private getLoadingPointsClusterBubble(
    marker: H.map.DomMarker,
    bubble: H.ui.InfoBubble,
    orders: Order[],
  ): HTMLElement {
    const factory = this.resolver.resolveComponentFactory(
      LoadingPointsClusterBubbleComponent,
    );

    const node = document.createElement('div');
    const ref = factory.create(this.injector, [], node);

    ref.setInput('orders', orders);
    ref.instance.close.subscribe(() => {
      this.ui?.removeBubble(bubble);
      const data: LoadingPointClusterData = { opened: false };
      marker.setData(data);
    });

    this.applicationRef.attachView(ref.hostView);

    return node;
  }

  private centerAt(content: H.geo.Rect) {
    const width = content.getWidth();
    const height = content.getHeight();
    const horizonalPadding = Math.max(0.3 * width, MIN_CONTENT_PADDING);
    const verticalPadding = Math.max(0.15 * height, MIN_CONTENT_PADDING);

    const bounds = new H.geo.Rect(
      content.getTop() + verticalPadding,
      content.getLeft() - horizonalPadding,
      content.getBottom() - verticalPadding,
      content.getRight() + horizonalPadding,
    );

    this.map?.getViewModel().setLookAtData({ bounds });

    const zoom = Math.max(INITIAL_ZOOM, this.map?.getZoom() ?? Infinity);
    this.map?.setZoom(zoom);
  }

  private resizeMap(): void {
    this.map?.getViewPort().resize();
  }
}
